import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Autocomplete, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { UserCreateService, UserUpdateService } from '../../../../utils/services/user.service';
import { FilterListService } from '../../../../utils/services/filter.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { StateListService } from '../../../../utils/services/state.service';
import { RoleListService } from '../../../../utils/services/role.service';
import { RootState } from '../../../../redux/store';
interface AddUserDialogProps {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  handleSuccess: () => void;
  currentUser: { id: number; user: string; email: string; name: string; role: string; district_id:number, company_id:number,region_id:number, mobile_number: string;state_id:number, company_name: string; state: string; region: string; district: string; temp_password:string, state_name:string, roles: { name: string }[]; maps: { region_id: number; region: string; district_id: number; district: string; id: number; }[]; } | null; }

const AddUserDialog: React.FC<AddUserDialogProps> = ({
  dialogOpen,
  handleCloseDialog,
  currentUser,
  handleSuccess

}) => {
  const [districtsMap, setDistrictsMap] = useState<{ [key: number]: any[] }>({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const isEditing = currentUser !== null;
  const [roles, setRole]= useState<any[]>([]);
  const userMapping = useSelector((state: RootState) => state.authUser.user_mapping);
  const userDetails = useSelector((state: RootState) => state.authUser.user);    
  const userRoles = useSelector((state: RootState) => state.authUser.roles);
  const isDistrictUser = userRoles?.some((role: { name: string }) => role.name === 'District User');
  const isAdminUser = userRoles?.some((role: { name: string }) => role.name === 'Super Admin');
  const initialRegion = isDistrictUser ? (userMapping?.[0]?.region || currentUser?.region || '') : '';
  const initialDistrict = isDistrictUser ? (userMapping?.[0]?.district || currentUser?.district || '') : '';
  const initialCompany = companies.find((company: { value: number }) => company.value === userDetails?.company_id)?.label || '';   
  const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';
  const regionsOptions = userMapping.map((mapping: { region: any; region_id: any; }) => ({
    label: mapping.region,
    value: mapping.region_id,
}));
const districtsOptions = userMapping.map((mapping: { district: any; district_id: any; }) => ({
    label: mapping.district,
    value: mapping.district_id,
})); 
type RowType = {
  region: {
    type: string;
    id: number;
    name: string;
  };
  district: {
    type: string;
    id: number;
    name: string;
  };
};
const [rows, setRows] = useState<RowType[]>([
  { region: { type: 'region', id: 0, name: '' }, district: { type: 'district', id: 0, name: '' } },
]);
console.log("currentUser", currentUser);

useEffect(() => {
  if (!loading && states.length > 0) {
    AddUserFormik.setValues({
      email: currentUser?.email || '',
      name: currentUser?.name || '',
      role: currentUser?.roles[0]?.name || '',
      mobile_number: currentUser?.mobile_number || '',
      password: currentUser?.temp_password || '',
      state: currentUser?.state_name || initialState || '',
      company: currentUser?.company_name || initialCompany || '',
      details: currentUser?.maps.map((map) => ({
        region: { type: 'region', id: map.region_id, name: map.region || initialRegion || '' },
        district: { type: 'district', id: map.district_id, name: map.district || initialDistrict || '' }
      })) || [{ region: { type: 'region', id: 0, name: '' }, district: { type: 'district', id: 0, name: '' } }],
    });
  }
}, [loading, states]);
useEffect(() => {
  if (currentUser) {
    AddUserFormik.setValues({
      email: currentUser.email || '',
      name: currentUser.name || '',
      role: currentUser.roles[0]?.name || '',
      mobile_number: currentUser.mobile_number || '',
      password: currentUser.temp_password || '',
      state: currentUser.state_name || initialState || '',
      company: currentUser.company_name || initialCompany || '',
      details: currentUser.maps.map((map) => ({
        region: { type: 'region', id: map.region_id, name: map.region || initialRegion },
        district: { type: 'district', id: map.district_id, name: map.district || initialDistrict }
      })) || []  
    });
    
    if (currentUser.maps.length > 0) {
      getDropdownList('region', currentUser.maps[0].region_id);
      getDropdownList('district', currentUser.maps[0].district_id);
    }
  }
},  [currentUser, initialState, initialCompany, initialRegion, initialDistrict]);
const AddUserFormik = useFormik({
  initialValues: {
    email: currentUser?.email || '',
    name: currentUser?.name || '',
    role: currentUser?.roles[0]?.name || '',
    mobile_number: currentUser?.mobile_number || '',
    password: currentUser?.temp_password || '',
    state: currentUser?.state_name || initialState || '',
    company: currentUser?.company_name || initialCompany || '',
    details: (currentUser?.maps || []).map((map) => ({
      region: {
        type: 'region',
        id: map.region_id,
        name: map.region || ''
      },
      district: {
        type: 'district',
        id: map.district_id,
        name: map.district || ''
      }
    })),  
  },
  validationSchema: Yup.object({
    email: Yup.string().trim().email('Invalid email format').required('Please enter email'),
    name: Yup.string().trim().required('Please enter name'),
    role: Yup.string().trim().required('Please enter role'),
    mobile_number: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid mobile number')
    .test('is-not-repetitive', 'Mobile number should not have all identical digits', value => {
      return value ? !/^(\d)\1{9}$/.test(value) : true;
    })
    .required('Please enter mobile number'),
    password: isEditing ? Yup.string().trim() : Yup.string().trim().required('Please enter password'),
   
  }),
  onSubmit: async (values, {resetForm}) => {
    setLoading(true);
    // const stateId = states.find((state: { name: string; }) => state.name === values.state)?.id;
    // const companyId = companies.find((company: { label: string; }) => company.label === values.company)?.value;
    const stateId = values.state ? states.find((state: { name: string }) => state.name === values.state)?.id : 0;
    const companyId = values.company ? companies.find((company: { label: string }) => company.label === values.company)?.value : 0;
    const selectedRole = roles.find(role => role.name === values.role);
    const details = stateId === 0 || companyId === 0
    ? []  
    : values.details.map((detail) => ({
        region_id: detail.region.id || 0,  
        district_id: detail.district.id || 0, 
      }));
    const payload = {
      id: currentUser?.id || null,
      email: values.email,
      name: values.name,
      role: selectedRole?.id || '',
      mobile_number: Number(values.mobile_number),
      password: values.password,
      state_id: stateId,
      company_id: companyId,
      details:details
      // details: values.details.map((detail) => ({
      //   region_id: stateId === 0 || companyId === 0 ? 0 : detail.region.id || 0,  
      //   district_id: stateId === 0 || companyId === 0 ? 0 : detail.district.id || 0, 
      // })),
    };
    let data;
    if (isEditing) {
      data = await UserUpdateService(payload); 
    } else {
      data = await UserCreateService(payload);
    }
    if (data?.code === 200) {
      resetForm();
      handleCloseDialog();
      handleSuccess();
      setLoading(false);
      dispatch(showSnackbar({ message: isEditing ? 'User details updated' : 'User created successfully', type: 'success', open: true }));
    } else {
      dispatch(showSnackbar({ message: data.data || 'Unknown error occurred. Please try again.', type: 'error', open: true }));
    }
  },
});
 
  const handleChange = (
    index: number,
    field: keyof RowType,
    value: { type: string; id: number; name?: string }
  ) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: { ...value, name: value.name || "" },
    };
    setRows(updatedRows);
    AddUserFormik.setFieldValue("details", updatedRows);
  
    if (field === "region") {
      getDropdownList("region", Number(value.id)).then((districts) => {
        if (districts) {
          setDistrictsMap((prev) => ({
            ...prev,
            [index]: districts || [],
          }));
        }
      });
    }
  };

  const getDropdownList = async (type: string, id?: number) => {
    setLoading(true);
    const payload = {
      type: type,
      id: id,
    };
    const { data, code } = await FilterListService(payload);
    if (code === 200 && data) {
      if (type === 'state') {
        setCompanies(data.data);
      } else if (type === 'company') {
        setRegions(data.data);
      } else if (type === 'region') {
        setDistricts(data.data);
        return data.data; 
      }
    }    
  };
  useEffect(() => {
    if (isEditing) {
      AddUserFormik.values.details.forEach((row, index) => {
        if (row.region.id) {
          getDropdownList('region', row.region.id).then((districts) => {
            setDistrictsMap((prev) => ({
              ...prev,
              [index]: districts || [],
            }));
          });
        }
      });
    }
  }, [isEditing]);
  const addRow = () => {
    if (AddUserFormik.values.details.length < 10) {
      const newRow: RowType = {
        region: { type: 'region', id: 0, name: '' },
        district: { type: 'district', id: 0, name: '' },
      };
      const updatedRows = [...AddUserFormik.values.details, newRow];
      setRows(updatedRows);
      AddUserFormik.setFieldValue('details', updatedRows);
    }
  };
  const deleteRow = (index: number) => {
    
    const updatedRows = [...AddUserFormik.values.details];
      updatedRows.splice(index, 1);
      setRows(updatedRows);
      AddUserFormik.setFieldValue('details', updatedRows);
  };

  useEffect(() => {
    AddUserFormik.setFieldValue('details', rows);
  }, [rows]);

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setLoading(true);
    const payload = {
      limit: 100,
      offset: 0,
      sort_by: 'name',
      order_by: 'asc',
      search: '',
    };
      const { data, code } = await StateListService(payload);
      if (code === 200 && data) {
        setStates(data.data);
      }
      setLoading(false);
  };
  
  
  useEffect(() => {
    const stateId = userDetails?.state_id || currentUser?.state_id;
    if (stateId) {
      getDropdownList('state', stateId);
    }
  }, [userDetails?.state_id, currentUser?.state_id]);

  useEffect(() => {
    const companyId = userDetails?.company_id || currentUser?.company_id;
    if (companyId) {
      getDropdownList('company', companyId);
    }
  }, [userDetails?.company_id, currentUser?.company_id]);
  useEffect(() => {       
    fetchRoles();
}, []);
const fetchRoles = async () => {
    setLoading(true);
    const payload = {
        limit: 100,
        offset: 0,
        sort_by: 'name',
        order_by: 'asc',
        search: ''
    };
    const { data, code } = await RoleListService(payload);
    if (code === 200 && data) {
        setRole(data.data);
    }
    setLoading(false);
};
const handleClose = () => {
  AddUserFormik.resetForm();
  handleCloseDialog();
};
const validateMobile = (event:any) => {
  let value = event.target.value;
  value = value.replace(/\D/g, '');
  if (value.length > 10) {
    value = value.slice(0, 10);
  }
  AddUserFormik.setFieldValue('mobile_number', value);
};

  return (
    <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={handleClose} scroll="paper"  PaperProps={{ component: 'form', onSubmit: AddUserFormik.handleSubmit }}>
     <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position:'sticky', top:'0', left:'0', right:'0', zIndex:'99', backgroundColor:'#fff', borderBottom:'1px solid #ccc', p:'10px 25px'  }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">{isEditing ? 'Edit User' : 'Add User'}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{mt:2}}>
        <Grid container spacing={2} pt={3}>
          <Grid item xs={12} lg={6}>
            <TextField fullWidth label="Name" variant="outlined" size="small"
              {...AddUserFormik.getFieldProps('name')}
              error={AddUserFormik.touched.name && Boolean(AddUserFormik.errors.name)}
              helperText={AddUserFormik.touched.name && AddUserFormik.errors.name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField fullWidth label="Email" variant="outlined" size="small"
              {...AddUserFormik.getFieldProps('email')}
              error={AddUserFormik.touched.email && Boolean(AddUserFormik.errors.email)}
              helperText={AddUserFormik.touched.email && AddUserFormik.errors.email}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete size='small'
              value={AddUserFormik.values.role}
              onChange={(event: any, newValue: string | null) => {
                AddUserFormik.setFieldValue('role', newValue || '');
                const roleId = roles.find((role: { name: string }) => role.name === newValue)?.id;
                if (roleId) {
                  getDropdownList('role', roleId);
                }
              }}
              inputValue={AddUserFormik.values.role}
              onInputChange={(event, newInputValue) => {
                if (roles.some((role: { name: string; }) => role.name === newInputValue)) {
                  AddUserFormik.setFieldValue('role', newInputValue);
                }
            }}
              options={roles.map((role: any) => role.name)}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Roles"
                  error={AddUserFormik.touched.role && Boolean(AddUserFormik.errors.role)}
                  helperText={AddUserFormik.touched.role && AddUserFormik.errors.role}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
             autoComplete="off"
              fullWidth
              label="Mobile*"
              variant="outlined"
              size="small"
              type="text"
              inputProps={{
                maxLength: 10
              }}
              {...AddUserFormik.getFieldProps('mobile_number')}
              onChange={validateMobile} 
              error={AddUserFormik.touched.mobile_number && Boolean(AddUserFormik.errors.mobile_number)}
              helperText={AddUserFormik.touched.mobile_number && AddUserFormik.errors.mobile_number}
            />
          </Grid>
          <Grid item xs={12} lg={6} mr={1}>
            <TextField autoComplete="off" fullWidth label="Password" variant="outlined" size="small"
              {...AddUserFormik.getFieldProps('password')}
              error={AddUserFormik.touched.password && Boolean(AddUserFormik.errors.password)}
              helperText={AddUserFormik.touched.password && AddUserFormik.errors.password}
            />
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <Autocomplete
                  size="small"
                  value={states.find((state: { name: string; }) => state.name === AddUserFormik.values.state) || null}
                  onChange={(event, newValue) => {
                      AddUserFormik.setFieldValue('state', newValue ? newValue.name : '');
                      AddUserFormik.setFieldValue('company', '');
                      AddUserFormik.setFieldValue('region', '');
                      AddUserFormik.setFieldValue('district', '');
                      setCompanies([]);
                      setRegions([]);
                      setDistrictsMap([]);
                      const stateId = states.find((state: { name: any; }) => state.name === newValue?.name)?.id;
                      if (stateId) {
                        getDropdownList('state', stateId);
                      }
                  }}
                  inputValue={AddUserFormik.values.state}
                  onInputChange={(event, newInputValue) => {
                      AddUserFormik.setFieldValue('state', newInputValue);
                  }}
                  onBlur={() => {
                    const validState = states.find((state: { name: any; }) => state.name === AddUserFormik.values.state);
                    if (!validState) {
                      AddUserFormik.setFieldValue(
                        'state',
                        currentUser?.state_name || ''
                      );
                    }
                  }}
                  options={states}
                  getOptionLabel={(option) => option.name}
                  
                  // freeSolo={false}
                  disabled={initialState}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      label="States"
                      error={AddUserFormik.touched.state && Boolean(AddUserFormik.errors.state)}
                      helperText={AddUserFormik.touched.state && typeof AddUserFormik.errors.state === 'string' ? AddUserFormik.errors.state : ''}
                      />
                  )}
                  />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
                size="small"
                value={AddUserFormik.values.company}
                onChange={(event: any, newValue: string | null) => {
                    if (newValue && companies.some((company: { label: string; }) => company.label === newValue)) {
                        AddUserFormik.setFieldValue('company', newValue);
                        AddUserFormik.setFieldValue('region', '');
                        AddUserFormik.setFieldValue('district', '');
                        setRegions([]);
                        setDistrictsMap([]);
                        const companyId = companies.find((company: { label: string; }) => company.label === newValue)?.value;
                        if (companyId) {
                            getDropdownList('company', companyId);
                        }
                    }
                }}
                inputValue={AddUserFormik.values.company}
                onInputChange={(event, newInputValue) => {
                    AddUserFormik.setFieldValue('company', newInputValue);
                }}
                onBlur={() => {
                  const validCompany = companies.some((company: { label: any; }) => company.label === AddUserFormik.values.company );
                  if (!validCompany && isEditing) {
                    AddUserFormik.setFieldValue(
                      'company',
                      currentUser?.company_name || ''
                    );
                  }
                }}
                disabled={initialCompany}
                options={companies.map((company: { label: any; }) => company.label)}
                
                freeSolo={false}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Company"
                        error={AddUserFormik.touched.company && Boolean(AddUserFormik.errors.company)}
                        helperText={AddUserFormik.touched.company && typeof AddUserFormik.errors.company === 'string' ? AddUserFormik.errors.company : ''}
                    />
                )}
            /> 
          </Grid> */}
          <Grid item xs={12} lg={6}>
              <Autocomplete
                size="small"
                value={states.find((state: { name: string }) => state.name === AddUserFormik.values.state) || null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    AddUserFormik.setFieldValue('state', newValue.name);
                    AddUserFormik.setFieldValue('company', '');
                    AddUserFormik.setFieldValue('region', '');
                    AddUserFormik.setFieldValue('district', '');
                    setCompanies([]);
                    setRegions([]);
                    setDistrictsMap([]);
                    
                    const stateId = states.find((state: { name: string }) => state.name === newValue.name)?.id;
                    if (stateId) {
                      getDropdownList('state', stateId);
                    }
                  } else {
                    AddUserFormik.setFieldValue('state', '');
                    AddUserFormik.setFieldValue('company', '');
                    AddUserFormik.setFieldValue('region', '');
                    AddUserFormik.setFieldValue('district', '');
                    setCompanies([]);
                    setRegions([]);
                    setDistrictsMap([]);
                  }
                }}
                inputValue={AddUserFormik.values.state}
                onInputChange={(event, newInputValue) => {
                  AddUserFormik.setFieldValue('state', newInputValue);
                }}
                // onBlur={() => {
                //   const validState = states.find((state: { name: string }) => state.name === AddUserFormik.values.state);
                //   if (!validState) {
                //     AddUserFormik.setFieldValue('state', currentUser?.state_name || '');
                //   }
                // }}
                options={states}
                getOptionLabel={(option) => option.name}
                freeSolo={false}
                disabled={initialState}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="States"
                    error={AddUserFormik.touched.state && Boolean(AddUserFormik.errors.state)}
                    helperText={AddUserFormik.touched.state && typeof AddUserFormik.errors.state === 'string' ? AddUserFormik.errors.state : ''}
                  />
                )}
              />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              size="small"
              value={companies.find((company: { label: string }) => company.label === AddUserFormik.values.company) || null}
              onChange={(event, newValue) => {
                if (newValue && companies.some((company: { label: string }) => company.label === newValue)) {
                  AddUserFormik.setFieldValue('company', newValue);
                  AddUserFormik.setFieldValue('region', '');
                  AddUserFormik.setFieldValue('district', '');
                  setRegions([]);
                  setDistrictsMap([]);
                  
                  const companyId = companies.find((company: { label: string }) => company.label === newValue)?.value;
                  if (companyId) {
                    getDropdownList('company', companyId);
                  }
                } else {
                  AddUserFormik.setFieldValue('company', '');
                  AddUserFormik.setFieldValue('region', '');
                  AddUserFormik.setFieldValue('district', '');
                  setRegions([]);
                  setDistrictsMap([]);
                }
              }}
              inputValue={AddUserFormik.values.company}
              onInputChange={(event, newInputValue) => {
                AddUserFormik.setFieldValue('company', newInputValue);
              }}
              // onBlur={() => {
              //   const validCompany = companies.some((company: { label: string }) => company.label === AddUserFormik.values.company);
              //   if (!validCompany && isEditing) {
              //     AddUserFormik.setFieldValue('company', currentUser?.company_name || '');
              //   }
              // }}
              options={companies.map((company: { label: string }) => company.label)}
              freeSolo={false}
              disabled={initialCompany}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  error={AddUserFormik.touched.company && Boolean(AddUserFormik.errors.company)}
                  helperText={AddUserFormik.touched.company && typeof AddUserFormik.errors.company === 'string' ? AddUserFormik.errors.company : ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ border: '1px solid #ddd' }}>
                <TableHead sx={{ backgroundColor: '#F8FAFF', fontWeight: 'bold' }}>
                  <TableRow>
                    <TableCell width={'50px'}>
                      <Button
                        color="primary"
                        onClick={addRow}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: '1.5rem !important' } }}
                        startIcon={<AddCircleOutlineIcon />}
                      />
                    </TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>District</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AddUserFormik.values.details.map((row: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Button color="error" onClick={() => deleteRow(index)}>
                          <RemoveCircleOutlineIcon />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth required>
                          {isAdminUser && (
                            <Autocomplete
                              size="small"
                              value={regions.find((region: { value: any; }) => region.value === row.region.id) || null}
                              onChange={(event, newValue) => {
                                AddUserFormik.setFieldValue('district', '');
                                // setDistrictsMap([]);
                                handleChange(index, 'region', newValue ? { type: 'region', id: newValue.value } : { type: 'region', id: '' });
                                getDropdownList('region', newValue ? newValue.value : undefined);
                              }}
                              options={regions}
                              disableClearable
                              freeSolo={false}
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => <TextField {...params} label="Region" />}
                            />

                          )}
                          {!isAdminUser && (
                            <Autocomplete
                              size="small"
                              value={regionsOptions.find((region: { value: any; }) => region.value === row.region.id) || null}
                              onChange={(event, newValue) => {
                                handleChange(index, 'region', newValue ? { type: 'region', id: newValue.value } : { type: 'region', id: '' });
                                getDropdownList('district', newValue ? newValue.value : undefined);
                              }}
                              options={regionsOptions}
                              disableClearable
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => <TextField {...params} label="Region" />}
                            />
                          )}
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth required>
                          {isAdminUser && (
                            <Autocomplete
                                size="small"
                                value={ districtsMap[index]?.find( (district: { value: any }) => district.value === row.district.id ) || null }
                                onChange={(event, newValue) => {
                                  handleChange(index, "district", { type: "district", id: newValue ? newValue.value : 0, });
                                }}
                                options={districtsMap[index] || []} 
                                disableClearable
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="District" />}
                              />
                          )}
                          {!isAdminUser && (
                            <Autocomplete
                              size="small"
                              value={districtsOptions.find((district: { value: any; }) => district.value === row.district.id) || null}
                              onChange={(event, newValue) => {
                                handleChange(index, 'district', newValue ? { type: 'district', id: newValue.value } : { type: 'district', id: '' });
                              }}
                              options={districtsOptions}
                              disableClearable
                              getOptionLabel={(option) => option.label}
                              renderInput={(params) => <TextField {...params} label="District" />}
                            />
                        )}
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
        <Button variant='contained' type="submit"  color='info'>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
