import { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography, TableContainer, Table, TableRow, TableCell, TableBody, TablePagination, Paper, CircularProgress, Grid, Box, IconButton, FormControlLabel, Checkbox, Badge, Chip } from '@mui/material';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import { ActivityLogService, NotificationLogService, NotificationStatusService } from '../../../utils/services/manage.service';
import React from 'react';
import TableHeadList from "../../../components/table/TableHeadList";
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DownloadIcon from '@mui/icons-material/Download';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { GetPermissions } from '../../../utils/utils';
interface ActivityDialogProps {
    dialogOpen: boolean;
    manageToActivity: any;
    handleClose: () => void;
    handleSuccess: () => void;
}
const headCells: any = [
    {
        id: 'srNo',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 20
    },
    {
        id: 'update',
        numeric: false,
        disablePadding: false,
        label: 'Update',
        width: 300
    },
    {
        id: 'updatedBy',
        numeric: false,
        disablePadding: false,
        label: 'Updated By',
        width: 200
    },
    {
        id: 'createdat',
        numeric: false,
        disablePadding: false,
        label: 'Created At',
        width: 200
    },
];
const ActivityLogDialog: React.FC<ActivityDialogProps> = ({ dialogOpen, manageToActivity, handleClose, handleSuccess }) => {
    const [activityLog, setActivityLog]= useState<any[]>([]);
    const [notificationLog, setNotificationLog]= useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [value, setValue] = React.useState('1');
    const [notificationId, setNotificationId] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [checkedState, setCheckedState] = useState<{ [key: number]: { checked: boolean; disabled: boolean } }>({});
    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (dialogOpen && manageToActivity && manageToActivity.id) {
    //         fetchActivity();
    //         fetchNotification();
    //     }
    // }, [dialogOpen, manageToActivity, rowsPerPage, page]);

    // const fetchActivity = async () => {
    //     if (!manageToActivity || !manageToActivity.id) {
    //         return;
    //     }
    //     const payload = {
    //         letter_id: manageToActivity.id,
    //         limit: rowsPerPage,
    //         offset: page * rowsPerPage,
    //         sort_by: 'id',
    //         order_by: 'asc',
    //         search: ''
    //     };

    //     setLoading(true);
    //     const { data, code } = await ActivityLogService(payload);
    //     if (code === 200 && data) {
    //         setActivityLog(data.data);
    //         setTotalRows(data.recordsTotal);
    //         handleSuccess();
    //     }
    //     setLoading(false);
    // };
    
    // const fetchNotification = async () => {
    //     if (!manageToActivity || !manageToActivity.id) {
    //         return;
    //     }
    //     const payload = {
    //         letter_id: manageToActivity.id,
    //         limit: rowsPerPage,
    //         offset: page * rowsPerPage,
    //         sort_by: 'created_at',
    //         order_by: 'asc',
    //         search: ''
    //     };
    //     setLoading(true);
    //     const { data, code } = await NotificationLogService(payload);
    //     if (code === 200 && data && data.data && data.data.length > 0) {
    //         setNotificationLog(data.data);
    //         setNotificationId(data.data.id);
    //         handleSuccess();
            
    //     } else {
    //         setNotificationLog([]);
    //         setNotificationId(null);
    //         setIsClosed(false);
    //     }
    //     setLoading(false);
    // };
     // const handleCheckboxChange = async (event: { target: { checked: boolean } }, notificationId: number) => {
    //     const isChecked = event.target.checked;
    //     await fetchNotificationStatus(notificationId, isChecked);
    //     handleSuccess();
    //     fetchNotification();
    // };
    useEffect(() => {
        if (dialogOpen && manageToActivity) {
            const letterId = getLetterId(manageToActivity);
            if (letterId) {
                fetchActivity(letterId);
                fetchNotification(letterId);
            }
        }
    }, [dialogOpen, manageToActivity, rowsPerPage, page]);
    
    const getLetterId = (manageToActivity: any) => {
        if (manageToActivity.id) {
            return manageToActivity.id;  
        } else if ( manageToActivity.letters.id) {
            return manageToActivity.letters.id; 
        }
        return null; 
    };
    
    const fetchActivity = async (letterId: number) => {
        const payload = {
            letter_id: letterId,  
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: 'id',
            order_by: 'asc',
            search: ''
        };
    
        setLoading(true);
        const { data, code } = await ActivityLogService(payload);
        if (code === 200 && data) {
            setActivityLog(data.data);
            setTotalRows(data.recordsTotal);
            handleSuccess();
        }
        setLoading(false);
    };
    
    const fetchNotification = async (letterId: number) => {
        const payload = {
            letter_id: letterId, 
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: 'created_at',
            order_by: 'asc',
            search: ''
        };
    
        setLoading(true);
        const { data, code } = await NotificationLogService(payload);
        if (code === 200 && data && data.data && data.data.length > 0) {
            setNotificationLog(data.data);
            setNotificationId(data.data.id);
            handleSuccess();
        } else {
            setNotificationLog([]);
            setNotificationId(null);
            setIsClosed(false);
        }
        setLoading(false);
    };
    const fetchNotificationStatus = async (notificationId: number, isChecked: boolean) => {
        if (!notificationId) {
            console.error("No notification ID available");
            return;
        }    
        const payload = {
            notification_id: notificationId,
            status: isChecked ? 'close' : 'open',
        };    
        setLoading(true);
        const { data, code } = await NotificationStatusService(payload);
        if (code === 200) {
            dispatch(showSnackbar({ message: 'Notification status updated successfully', type: 'success', open: true }));
            handleSuccess();
            setCheckedState((prevState) => ({
                ...prevState,
                [notificationId]: { checked: isChecked, disabled: isChecked },
            }));
        } else {
            dispatch(showSnackbar({ message: data || 'Unknown error occurred. Please try again.', type: 'error', open: true }));
        }
        setLoading(false);
    };
    const handleCheckboxChange = async (event: { target: { checked: boolean } }, notificationId: number, letterId: number) => {
        const isChecked = event.target.checked;
        await fetchNotificationStatus(notificationId, isChecked);    
        handleSuccess();
        await fetchNotification(letterId);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);  
    };
    const permissions: any = GetPermissions('letter');
    return (
        <>
            <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={handleClose} >
                <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0',
                    zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px', mb:2 }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={12} md={10}>
                                    <Typography variant="h4">Activity Logs</Typography>
                                </Grid>
                                <Grid item xs={12} md={2} textAlign={"end"}>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Activity Logs" value="1" sx={{fontWeight:600, '&.Mui-selected': { backgroundColor: '#0288d1', color: '#fff', }, }} />
                                    <Tab value="2" sx={{ fontWeight: 600, '&.Mui-selected': { backgroundColor: '#0288d1', color: '#fff' }, }} label="Notification" />
                                </TabList>
                            </Box>
                            
                            <TabPanel value="1" sx={{padding:0}}>
                                <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.3, }, }}>
                                    {activityLog && activityLog?.length > 0 ? (
                                        activityLog?.map((row, index) => (
                                            <TimelineItem>
                                                <TimelineOppositeContent sx={{ m: 'auto 0' }} align="left" variant="body2" color={'error'} fontSize={'12px'}>
                                                    {dayjs(row.created_at).format('DD-MM-YYYY | HH:mm:ss')}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator> 
                                                    <TimelineConnector sx={{backgroundColor:'#0288d1'}} />
                                                        <TimelineDot  sx={{ margin: '0 !important', width:'35px', height:'35px', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'#fff', color:'#0288d1', borderColor:'#0288d1', fontWeight:600 }}> {index + 1} </TimelineDot>
                                                    <TimelineConnector sx={{backgroundColor:'#0288d1'}}/>
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ py: '12px', px: 2, boxShadow:7, m:1, borderRadius:1 }}>
                                                    <Typography variant="h6" sx={{color:'#0288d1', fontWeight:600, mb:'5px'}}> {row.user_name} </Typography>
                                                    <Typography> {row.description}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>   
                                            
                                        ))
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            No activity found.
                                        </Typography>
                                    )}
                                </Timeline>
                                  
                            </TabPanel>
                            <TabPanel value="2" sx={{padding:0}}>
                                <Timeline>
                                {notificationLog && notificationLog?.length > 0 ? (
                                        notificationLog?.map((row, index) => (
                                        <TimelineItem>
                                            <TimelineOppositeContent sx={{ m: 'auto 0', flex:'none' }} align="right" variant="body2" color={'error'} fontSize={'12px'}>
                                                {dayjs(row.created_at).format('DD-MM-YYYY | HH:mm:ss')}
                                            </TimelineOppositeContent>
                                            <TimelineSeparator> 
                                                <TimelineConnector sx={{backgroundColor:'#0288d1'}} />
                                                    <TimelineDot  sx={{ margin: '0 !important', width:'35px', height:'35px', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'#fff', color:'#0288d1', borderColor:'#0288d1', fontWeight:600 }}> {index + 1} </TimelineDot>
                                                <TimelineConnector sx={{backgroundColor:'#0288d1'}}/>
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, boxShadow:7, m:1, borderRadius:1, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                                                <Box>
                                                    <Typography variant="h6" sx={{color:'#0288d1', fontWeight:600, mb:'5px'}}> {row.created_by_name} </Typography>
                                                    <Typography> {row.remark}</Typography>
                                                </Box>
                                                <Box>
                                                    {row.file_path.length >= 1 && ( 
                                                        <Button sx={{backgroundColor:'#ededed', boxShadow:'none', mr:'10px'}} variant="contained" color="inherit" onClick={() => window.open(row.file_path, '_blank', 'noopener,noreferrer')} > <SaveAltIcon />
                                                        </Button>
                                                    )}
                                                    <Chip sx={{height:'25px'}} label={row.status=='1' ? 'Close' : 'Open'} color={row.status=='1' ? 'error' : 'success'} variant="outlined" />
                                                </Box>
                                            </TimelineContent>
                                            
                                            {permissions.includes('notification_mark_as_resolved') && (
                                                <>
                                                    <Box sx={{ m: 'auto 0' }}>
                                                        {row.status === '0' && (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={checkedState[row.id]?.checked || false}
                                                                        onChange={(event) => handleCheckboxChange(event, row.id, manageToActivity.id || manageToActivity.letters.id)}
                                                                        disabled={checkedState[row.id]?.disabled || loading}
                                                                    />
                                                                }
                                                                label="Mark as resolved"
                                                            />
                                                        )}                                                        
                                                    </Box>
                                                </>
                                            )}
                                        </TimelineItem>   
                                     ))
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            No notification found.
                                        </Typography>
                                    )} 
                                </Timeline>
                            </TabPanel>
                        </TabContext>
                    </Box>
                    
            </DialogContent>
        </Dialog>
        </>
    )
}
 export default ActivityLogDialog