import { useSelector } from 'react-redux';
import { isAuthorizedPage, isLoggedIn } from '../utils/utils';

import AdminTopBar from '../components/admin/topbar';
import AdminNavBar from '../components/admin/navbar';

import { Box } from '@mui/material';
import { RootState } from '../utils/dto/response';
import { Navigate } from 'react-router-dom';
import Error403 from '../pages/error/Error403';

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
    // const authUser = useSelector((state: RootState) => state.authUser);
    // if (!isLoggedIn(authUser)) {
    //     return <Navigate to='/auth/login' />
    // }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh', height: 'auto', position: 'relative', minWidth: '290px', overflow: 'hidden' }}>
            <AdminTopBar />
            <Box sx={{ width: '100%', height: '100%', mt: '59px', flex: '1 1', display: 'flex' }}>
                <AdminNavBar />
                <Box sx={{ flexGrow: 1, width: 'calc(100% - 240px)', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.400' }}>
                    <Box sx={{ position: 'relative', minHeight: '100%' }}>
                        {  children }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AdminLayout;