import { Box, Button, FormControl, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Autocomplete, CardContent, Card, FormHelperText, FormControlLabel, Checkbox } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Upload } from '../../../utils/dto/request/master';
import { StoreUploadLetterService } from '../../../utils/services/manage.service';
import { CategoryListService } from '../../../utils/services/category.service';
import PageHead from '../../../components/admin/PageHead';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TypeListService } from '../../../utils/services/type.service';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import FilterData from './filter';
import { FilterListService } from '../../../utils/services/filter.service';
import { StateListService } from '../../../utils/services/state.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DownloadIcon from '@mui/icons-material/Download';
import { GetPermissions } from '../../../utils/utils';
import ActivityLogDialog from './activityLogDialog';
const centeredLabelStyle = {
    '& .MuiInputLabel-root': {
      display: 'flex',
      alignItem: 'center',
      width: '100%',
      position:'absolute',
      top:'-7px'
    },
  };
  const UploadLetter = () =>{
    const [stateId, setStateId] = useState<number | undefined>();
    const [selectedVillages, setSelectedVillages] = useState<string[]>([]);
    const [manageToActivity, setManageToActivity] = useState<any>(null);
    const [activityDialogOpen, setActivityDialogOpen] = useState<boolean>(false);
    const [isDateFieldVisible, setIsDateFieldVisible] = useState<boolean>(false)
    const [isReferenceFieldsVisible, setIsReferenceFieldsVisible] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [filterFormSubmitted, setFilterFormSubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitFormLoading, setSubmitFormLoading] = useState<boolean>(false);
    const [types, setType] = useState<any[]>([]);
    const [category, setCategory]= useState<any[]>([]);
    const [status, setStatus]= useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const location = useLocation();
    const { prefillData = {}, responseData: initialResponseData = [] } = location.state || {};
    const [responseData, setResponseData] = useState<any[]>(initialResponseData);
    const { action, parent_id } = location.state || {};
    const isEditing = prefillData !== null;
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [states, setStates] = useState<any[]>([]);   
    const [companies, setCompanies] = useState<any[]>([]);
    const [regions, setRegions] = useState<any[]>([]);
    const [districts, setDistricts] = useState<any[]>([]);
    const [talukas, setTalukas] = useState<any[]>([]);
    const [villages, setVillages] = useState<any[]>([]);
    const [rows, setRows] = useState<Upload[]>([
        { type: '', file: null, remark: '', date: '', type_id: '' },
    ]);    
    const dispatch = useDispatch();
    const navigate = useNavigate();    
    const [editData, setEditData] = useState<any>({
        company: prefillData.company_name || '',
        state: prefillData.state_name || '',
        region: prefillData.region_name || '',
        district: prefillData.district_name || '',
        taluka: prefillData.taluka_name || '',
        village: prefillData.village_name || ''
    });
    useEffect(() => {
        if (Object.keys(prefillData).length > 0) {
            setEditData({
                company: prefillData.company_name,
                state: prefillData.state_name,
                region: prefillData.region_name,
                district: prefillData.district_name,
                taluka: prefillData.taluka_name,
                village: prefillData.village_name
            });
            setFilterFormSubmitted(true); 
            setMode('edit');
        }
    }, [prefillData]);
    const UploadLetterFormik = useFormik({
        initialValues: {           
            address: prefillData.address || '',
            cc: prefillData.cc || '',
            category: prefillData.category_name || '',
            subject: prefillData.subject || '',
            reference_number: prefillData.reference_number || '',
            reference_number_type: prefillData.reference_number_type || 'Auto',
            reference_number_value:'',
            status: prefillData.status_name || '',
            details:rows,    
            state_id: prefillData.state_id || '',
            company_id: prefillData.company_id || '',
            region_id: prefillData.region_id || '',
            district_id: prefillData.district_id || '',
            taluka_id: prefillData.taluka_id || '',
            category_id: prefillData.category_id || '',
            status_id: '',
            company_name:prefillData.cc || '',
            parent_id: parent_id || '',
            manual_date:'',
            village_id: (prefillData.village_id && prefillData.village_id.length > 0)
            ? prefillData.village_id.split(',').map((v: string) => v.trim())
            : [],  
        },        
        validationSchema: Yup.object({            
            address: Yup.string().trim().required('Addressee is required'),
            cc: Yup.string().trim().required('CC is required'),
            subject: Yup.string().trim().required('Subject is required'), 
            category: Yup.string().trim().required('Category is required'),
            details: Yup.array().of(
                Yup.object().shape({
                    type_id: Yup.string().nullable(),
                    file: Yup.mixed().when(['type_id'], {
                        is: (type_id: string) => !!type_id && isEditing,
                        then: (schema) => schema.required('File is required when a type is selected'),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                })
            ),
        }),        
        onSubmit: async (values) => {
            console.log("village_id in values:", values.village_id);
            setLoading(true);
            const categoryId = category.find((category: { name: string; }) => category.name === values.category)?.id;
            const statusId = status.find((status: { name: string; }) => status.name === values.status)?.id;
            const formData = new FormData();          
            if (prefillData.id) {
                formData.append('id', prefillData.id);
            }
            formData.append('state_id', values.state_id);
            formData.append('company_id', values.company_id);
            formData.append('region_id', values.region_id);
            formData.append('district_id', values.district_id);
            formData.append('taluka_id', values.taluka_id);   
            const villageIds = values.village_id.length > 0
            ? values.village_id
            : (prefillData.village_id && prefillData.village_id.length > 0)
                ? prefillData.village_id.split(',').map((v: string) => v.trim())
                : [];
            villageIds.forEach((villageId: string, index: number) => {
                formData.append(`village_id[${index}]`, villageId);
            });
            formData.append('address', values.address);
            formData.append('cc', values.cc);
            formData.append('subject', values.subject);
            formData.append('manual_date', values.manual_date);
            formData.append('parent_id', values.parent_id || '');
            if (permissions.includes('generate_reference_no')) {
                formData.append('reference_number_type', values.reference_number_type);
                if (hasGeneratedReference ) { //|| mode === 'edit'
                    formData.append('reference_number', referenceNumber);
                }
            }            
            formData.append('category_id', categoryId || '');            
            values.details.forEach((details: Upload, index: number) => {
                formData.append(`details[${index}][type_id]`, details.type_id || '');          
                 if (details.file) {
                    formData.append(`details[${index}][file]`, details.file);
                }            
                formData.append(`details[${index}][date]`, details.date);
                formData.append(`details[${index}][remark]`, details.remark);
            }); 
            const { data, code } = await StoreUploadLetterService(formData);
            if (code === 200) {
                dispatch(showSnackbar({ message: prefillData.id ? 'Letter updated' : 'Letter uploaded successfully', type: 'success', open: true }));
                navigate('/manage');
            } else {
                dispatch(showSnackbar({ message: data || 'Something went wrong. Please try again later.', type: 'error', open: true }));                
            }
            setLoading(false);            
        },
    });    
    useEffect(() => {
        UploadLetterFormik.setFieldValue('village_id', selectedVillages);
    }, [selectedVillages]);
    const handleChange = (index: number, field: keyof Upload, value: any) => {
        const updatedRows = [...rows];
        updatedRows[index] = {
            ...updatedRows[index],
            [field]: value
        };
        setRows(updatedRows);
        UploadLetterFormik.setFieldValue(`details[${index}].${field}`, value);
    };    
    const addRow = () => {
        const updatedRows = [...rows, { type: '', file: null, remark: '', date: '', type_id: '' }];
        setRows(updatedRows);
        UploadLetterFormik.setFieldValue('details', updatedRows);
    };    
    const deleteRow = (index: number) => {
        const updatedRows = [...rows];
            updatedRows.splice(index, 1);
            setRows(updatedRows);
            UploadLetterFormik.setFieldValue('details', updatedRows);
    };    
    const handleFileChange = (index: number, file: File) => {
        const newRows = [...rows];
        newRows[index].file = file;
        setRows(newRows);
        UploadLetterFormik.setFieldValue(`details[${index}].file`, file);
    };    
    const handleValueChange = (index: number, newValue: number | null) => {
        const newRows = [...rows];
        newRows[index].type = newValue !== null ? String(newValue) : '';
        setRows(newRows);
        UploadLetterFormik.setFieldValue(`details[${index}].type_id`, newValue !== null ? String(newValue) : '');
    };         
    useEffect(() => {        
        fetchCategory();
    }, []);
    const fetchCategory = async () => {
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        setLoading(true);
        const { data, code } = await CategoryListService(payload);
        if (code === 200 && data) {
            setCategory(data.data);
        }
        setLoading(false);
    };
    useEffect(() => {        
        fetchType();
    }, []);
    const fetchType = async () => {
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        setLoading(true);
        const { data, code } = await TypeListService(payload);
        if (code === 200 && data) {
            setType(data.data);
        }
        setLoading(false);
    };
    const previousIds = useRef<{ [key: string]: number | undefined }>({});
    const getDropdownList = async (type: string, id?: number) => {
        setLoading(true);
        if (id === previousIds.current[type]) {
            setLoading(false);
            return;
        }    
        const payload = {
            type: type,
            id: id,
        };
        UploadLetterFormik.setFieldValue(type +'_id', id);
        const { data, code } = await FilterListService(payload);
        if (data && code === 200) {
            if (type === 'state') {
                setCompanies(data.data);
            } else if (type === 'company') {
                setRegions(data.data);
            } else if (type === 'region') {
                setDistricts(data.data);
            } else if (type === 'district') {
                setTalukas(data.data);
            }else if (type === 'taluka') {
                setVillages(data.data);
            }else if (type === 'village') {}
        }
        previousIds.current[type] = id;
        setLoading(false);
    };
    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };
    useEffect(() => {
        if (stateId) {
            getDropdownList('state', stateId);
        }
    }, [stateId]);
    
    const handleFilterSubmit = () => {
        setFilterFormSubmitted(true);
    };
    useEffect(() => {
        if (action === 'edit') {
            setMode('edit');
            setIsReferenceFieldsVisible(!!prefillData.reference_number);
            setIsButtonDisabled(!!prefillData.reference_number);
        } else {
            setMode('create');
            setIsReferenceFieldsVisible(false); 
            setIsButtonDisabled(false);
        }
    }, [action]);    
    const [isManualReference, setIsManualReference] = useState(prefillData.reference_number_type === 'manual');
    const [referenceNumber, setReferenceNumber] = useState<string>(prefillData.reference_number || '');
    const [hasGeneratedReference, setHasGeneratedReference] = useState(false);
    useEffect(() => { 
        if (responseData && responseData.length > 0) {
            const data = responseData[0];
            const refNumber = data.generate_reference_no === 1 ? data.reference_no_auto : data.reference_no_manual;

            if (!prefillData.reference_number) { 
                setReferenceNumber(refNumber);
                setIsManualReference(data.generate_reference_no === 0);
                UploadLetterFormik.setFieldValue('reference_number', refNumber);
            }
        }
    }, [responseData, prefillData.reference_number]);
    useEffect(() => {
        if (isEditing) {  
        if (!prefillData.reference_number) {
            if (responseData && responseData.length > 0) {
            const data = responseData.find((item) => item.district_id === prefillData.district_id);
            if (data) {
                const refNumber = data.generate_reference_no === 1 ? data.reference_no_auto : data.reference_no_manual;
                setReferenceNumber(refNumber);
                setIsManualReference(data.generate_manual_reference_no === 1);
                UploadLetterFormik.setFieldValue('reference_number', refNumber);
            }
            }
        } else {
            setReferenceNumber(prefillData.reference_number);
            setIsManualReference(false); 
        }
        }
    }, [isEditing, prefillData, responseData]);
    const handleReferenceNumberChange = (event:any) => {
        const newReferenceNumber = event.target.value;
        setReferenceNumber(newReferenceNumber);
        UploadLetterFormik.setFieldValue('reference_number', newReferenceNumber);
    };
    const handleManualReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDateFieldVisible(true);
        const isChecked = event.target.checked;
        setIsManualReference(isChecked);
        const newValue = isChecked ? 'Manual' : 'Auto';
        UploadLetterFormik.setFieldValue('reference_number_type', newValue);
            if (!isChecked && responseData.length > 0) {
                const autoReferenceNumber = responseData[0].reference_no_auto;
                setReferenceNumber(autoReferenceNumber);
                UploadLetterFormik.setFieldValue('reference_number', autoReferenceNumber);
            }
    };
    const shouldDisableCheckbox = mode === 'edit' && !!prefillData.reference_number;
    const backTolist =() => {
        navigate('/manage')
    }
    const permissions: any = GetPermissions('letter');
    const handleGenerateNumberClick = async (values: any) => {
        setIsReferenceFieldsVisible(true) 
        setIsManualReference(false)    
        setIsButtonDisabled(true)
        setHasGeneratedReference(true);
    }; 
    const handleOpenActivityDialog = (manage: any) => {
        setManageToActivity(manage);
        setActivityDialogOpen(true);
    };
    const handleCloseActivityDialog = () => {
        setActivityDialogOpen(false);
    };
    const handleSuccess = () => {};

    return(
        <>
            <Box sx={{ px: 2, py: 1.5, display:'flex', justifyContent:'space-between' }}>
                <PageHead primary='Upload Letter' />
                <Box>   
                    {mode==='edit' && (
                        <Button  variant="contained" color="success" title="Activity Logs" onClick={() => handleOpenActivityDialog(prefillData)} style={{ cursor: 'pointer', marginRight:10 }}>
                                Activity Log
                        </Button >    
                    )}
                    <Button sx={{ml:3}} variant='contained' color='info' onClick={backTolist}>
                        Back
                    </Button>
                </Box>                
            </Box>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <FilterData
                        states={states}
                        companies={companies}
                        regions={regions}
                        districts={districts}
                        talukas={talukas}
                        villages={villages}
                        onDropdownChange={getDropdownList}
                        setResponseData={setResponseData}
                        onFilterSubmit={handleFilterSubmit}
                        editData={editData}
                        mode={mode}
                        setSelectedVillages={setSelectedVillages} 
                    />
                </CardContent>
            </Card>
            {filterFormSubmitted && (               
                <Box component='form' onSubmit={UploadLetterFormik.handleSubmit}> 
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Grid container columnSpacing={3}>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <TextField
                                         autoComplete="off"
                                            fullWidth
                                            label="Addressee*"
                                            variant="outlined"
                                            size="small"
                                            {...UploadLetterFormik.getFieldProps('address')}
                                            name="address"
                                            error={UploadLetterFormik.touched.address && Boolean(UploadLetterFormik.errors.address)}
                                            helperText={UploadLetterFormik.touched.address && UploadLetterFormik.errors.address ? (UploadLetterFormik.errors.address as string) : ''}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <TextField
                                            autoComplete="off"
                                            fullWidth
                                            label="CC*"
                                            variant="outlined"
                                            size="small"
                                            {...UploadLetterFormik.getFieldProps('cc')}
                                            name="cc"
                                            error={UploadLetterFormik.touched.cc && Boolean(UploadLetterFormik.errors.cc)}
                                            helperText={UploadLetterFormik.touched.cc && UploadLetterFormik.errors.cc ? (UploadLetterFormik.errors.cc as string) : ''}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                        size='small'
                                        value={UploadLetterFormik.values.category}
                                        onChange={(event: any, newValue: string | null) => {
                                            UploadLetterFormik.setFieldValue('category', newValue);
                                        }}
                                        inputValue={UploadLetterFormik.values.category}
                                        onInputChange={(event, newInputValue) => {
                                            if (category.some((category: { name: string; }) => category.name === newInputValue)) {
                                                UploadLetterFormik.setFieldValue('category', newInputValue);
                                            }
                                        }}
                                        disableClearable
                                        freeSolo={false}
                                        options={category.map((option) => option.name)}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label="Category*"
                                            error={UploadLetterFormik.touched.category && Boolean(UploadLetterFormik.errors.category)}
                                            helperText={UploadLetterFormik.touched.category && UploadLetterFormik.errors.category ? (UploadLetterFormik.errors.category as string) : ''}
                                            />
                                        )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <TextField
                                         autoComplete="off"
                                            fullWidth
                                            label="Subject*"
                                            variant="outlined"
                                            size="small"
                                            {...UploadLetterFormik.getFieldProps('subject')}
                                            name="subject"
                                            error={UploadLetterFormik.touched.subject && Boolean(UploadLetterFormik.errors.subject)}
                                            helperText={UploadLetterFormik.touched.subject && UploadLetterFormik.errors.subject ? (UploadLetterFormik.errors.subject as string) : ''}
                                        />
                                    </FormControl>
                                </Grid>
                                {permissions.includes('generate_reference_no') && (
                                    <Grid item xs={12} lg={2} sx={{ mt: '20px' }}>
                                        <Button type="button" variant='contained' color='info' disabled={isButtonDisabled || loading} onClick={handleGenerateNumberClick}>Generate Reference Number</Button>
                                    </Grid>
                                )}
                                {(isReferenceFieldsVisible || hasGeneratedReference) && (
                                    <>
                                        {permissions.includes('generate_reference_no') && (
                                            <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                                <FormControl required fullWidth>
                                                    <TextField
                                                    autoComplete="off"
                                                    label="Reference Number*"
                                                    variant="outlined"
                                                    size="small"
                                                    name="reference_number"
                                                    value={referenceNumber}
                                                    onChange={isManualReference ? handleReferenceNumberChange : undefined}
                                                    InputProps={{
                                                        readOnly: !isManualReference,
                                                    }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {permissions.includes('generate_manual_reference_no') && (
                                            <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                                                <FormControl fullWidth required>
                                                    <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isManualReference}
                                                            onChange={handleManualReferenceChange}
                                                            name="reference_number_type"
                                                            color="primary"
                                                            disabled={mode === 'edit' ? shouldDisableCheckbox : false}
                                                        />
                                                    }
                                                    label="Manual Reference Number"
                                                    />
                                                    {UploadLetterFormik.touched.reference_number_type && UploadLetterFormik.errors.reference_number_type && (
                                                    <FormHelperText>{UploadLetterFormik.errors.reference_number_type as string}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {isDateFieldVisible && (
                                    <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                            label="Date"
                                            format="DD/MM/YYYY"
                                            name="manual_date"
                                            value={UploadLetterFormik.values.manual_date ? dayjs(UploadLetterFormik.values.manual_date) : null}
                                            onChange={(value) => {
                                                UploadLetterFormik.setFieldValue('manual_date', value ? dayjs(value).format('YYYY-MM-DD') : null);
                                            }}
                                            views={['year', 'month', 'day']}
                                            maxDate={dayjs().startOf('day')}
                                            slotProps={{ textField: { fullWidth: true, sx: centeredLabelStyle, InputProps: { readOnly: true, onFocus: (e) => e.target.blur() }} }}
                                            />
                                        </LocalizationProvider> 
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>                        
                    <Card sx={{ mb: 2 }}>
                        <CardContent sx={{ p: '0 !important' }}>
                            <Box m={0}>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                        {prefillData?.letter_files?.map((file:any) => (
                                            <TableRow key={file.id}>
                                                 <TableCell >
                                                    <Button sx={{opacity:'.3', userSelect:'none', pointerEvents:'none'}} color="error"><RemoveCircleOutlineIcon /></Button>
                                                </TableCell>
                                                <TableCell >{file.type.name}</TableCell>
                                                <TableCell>
                                                    <Button variant="outlined" color="primary" onClick={() => window.open(file.file_path, '_blank', 'noopener,noreferrer')} >
                                                        {file.file_name} <DownloadIcon sx={{ml:1}}/>
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{file.remarks}</TableCell>
                                                <TableCell>{file.date ? new Date(file.date).toLocaleDateString('en-GB') : '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                        <TableHead sx={{ backgroundColor: '#F8FAFF', fontWeight: 'bold' }}>
                                            <TableRow>
                                                <TableCell width={'20px'}>
                                                    <Button color="primary" onClick={addRow} sx={{ '& .MuiSvgIcon-root': { fontSize: '1.5rem !important' } }} startIcon={<AddCircleOutlineIcon />} />
                                                </TableCell>
                                                <TableCell width={'200px'}>Letter Stage</TableCell>
                                                <TableCell width={'200px'}>Upload File</TableCell>
                                                <TableCell width={'300px'}>Remark</TableCell>
                                                <TableCell width={'200px'}>Date</TableCell>                                       
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {UploadLetterFormik.values.details.map((row:any, index:number) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <Button color="error" onClick={() => deleteRow(index)}><RemoveCircleOutlineIcon /></Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControl fullWidth required>
                                                            <Autocomplete
                                                                size="small"
                                                                value={types.find(option => option.id === Number(row.type_id)) || null}
                                                                onChange={(event, newValue) => handleValueChange(index, newValue ? newValue.id : null)}
                                                                options={types}
                                                                disableClearable
                                                                freeSolo={false}
                                                                getOptionLabel={(option) => option.name}
                                                                renderInput={(params) => <TextField {...params} label="Type *" />}                                                
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell sx={{position:'relative'}}>
                                                        <Typography variant="body2" fontSize={'11px'} position={'absolute'} top={'3px'}  color='error'>Allowed only  PDF doc, docx, xls, pdf, zip, rar</Typography>
                                                        <Box display="block" alignItems="center">
                                                            <Box position="relative">
                                                                <input
                                                                    type="file"
                                                                    name={`details[${index}].file`}
                                                                    style={{ display: 'none' }}
                                                                    id={`details[${index}].file`}
                                                                    onChange={(event) => {
                                                                        if (event.currentTarget.files) {
                                                                            handleFileChange(index, event.currentTarget.files[0]);
                                                                        }
                                                                    }}
                                                                />
                                                                <label htmlFor={`details[${index}].file`}>
                                                                    <Button variant="outlined" color="inherit" component="span" fullWidth>
                                                                        <InsertDriveFileIcon sx={{ mr: 1 }} /> Upload File
                                                                    </Button>
                                                                </label>
                                                            </Box>
                                                        </Box>
                                                        {row.file && (
                                                            <Typography variant="body2" fontSize={'11px'} position={'absolute'}>
                                                                {`${row.file.name}`}
                                                            </Typography>
                                                        )}       
                                                        {UploadLetterFormik.touched.details && UploadLetterFormik.errors.details && (
                                                            <FormHelperText sx={{color:'red'}}>{(UploadLetterFormik.errors.details[index] as FormikErrors<Upload>).file}</FormHelperText>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControl fullWidth required>
                                                            <TextField
                                                             autoComplete="off"
                                                                label="Remark"
                                                                size="small"
                                                                fullWidth
                                                                variant="outlined"
                                                                value={row.remark || ''}
                                                                onChange={(e) => handleChange(index, 'remark', e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell>
                                                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker
                                                                    label="Select Date"
                                                                    format="DD/MM/YYYY"
                                                                    views={['year', 'month', 'day']}
                                                                    value={dayjs(row.date)} 
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            handleChange(index, 'date', dayjs(date).format('YYYY-MM-DD'));
                                                                        } else {
                                                                            handleChange(index, 'date', '');
                                                                        }
                                                                    }}
                                                                    sx={{ 
                                                                        color:'#000 !important',
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#ddd !important', 
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </CardContent>
                    </Card>
                    <Button sx={{float:'right', m:1}} variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>   
                </Box>
            )}
            <ActivityLogDialog dialogOpen={activityDialogOpen} manageToActivity={manageToActivity} handleClose={handleCloseActivityDialog} handleSuccess={handleSuccess} />
        </>
    )
}
export default UploadLetter