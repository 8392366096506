import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography, CircularProgress, Card, CardContent, Grid, FormControlLabel, Checkbox, Box, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import { RoleAssignService, RoleViewService } from '../../../../utils/services/role.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
interface ViewDialogProps {
    dialogOpen: boolean;
    roleToView: any;
    handleClose: () => void;
    onViewSuccess: () => void;
}

const ViewRoleDialog: React.FC<ViewDialogProps> = ({ dialogOpen, roleToView, handleClose, onViewSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [roleViewData, setRoleViewData] = useState<any>(null);
    const [selectedPermissions, setSelectedPermissions] = useState<any>([]);

    useEffect(() => {
        if (dialogOpen && roleToView) {
            fetchRoleView(roleToView.id);
        }
    }, [dialogOpen, roleToView]);

    useEffect(() => {
        localStorage.setItem('selectedPermissions', JSON.stringify(selectedPermissions));
    }, [selectedPermissions]);
    useEffect(() => {
        console.log(roleViewData);
        if (roleViewData) {
            const tempPermissions: any = [];
            roleViewData.menus.forEach((menu: any) => {
                tempPermissions.push({id: menu.id, permissions: menu.permissions && menu.permissions.length ? (menu.permissions.map((permission: any) => permission.is_selected === 1 ? permission.id : null).filter((e: any) => e)) : []});

                menu.sub_menus && menu.sub_menus.length && menu.sub_menus.forEach((subMenu: any) => {
                    tempPermissions.push({id: subMenu.id, permissions: subMenu.permissions && subMenu.permissions.length ? (subMenu.permissions.map((permission: any) => permission.is_selected === 1 ? permission.id : null).filter((e: any) => e)) : [] });
                });
            });
            setSelectedPermissions(tempPermissions);
        }
    }, [roleViewData]);
    
    const fetchRoleView = async (id: number) => {
        setLoading(true);
        const { data, code } = await RoleViewService(id);
        if (code === 200 && data) {
            setRoleViewData(data.data);
            onViewSuccess();
        }
        setLoading(false);
    };

    const checkIfPermissionAssigned = (menuId: number, permissionId: number) => {
        if (!(selectedPermissions && selectedPermissions.length)) return false;
        const menu = selectedPermissions.find((menu: any) => menu.id === menuId);
        if (menu && menu.id === 4) console.log(menu, menuId, permissionId);

        if (!(menu && menu.permissions && menu.permissions.length)) return false;
        return menu.permissions.includes(permissionId);
    }

    const handleCheckboxChange = (event: any, menuId: number, permissionId: number) => {
        if (!(selectedPermissions && selectedPermissions.length)) return false;
        const tempPermissions = [...selectedPermissions];

        let menu = tempPermissions.find((menu: any) => menu.id === menuId);
        if (!menu) {
            menu = {id: menuId, permissions: []};
            tempPermissions.push(menu);
        }
        const checked = event.target.checked;
        if (checked) {
            if (!menu.permissions) menu.permissions = []; 
            menu.permissions.push(permissionId);
        } else {
            if (menu.permissions) { 
                const index = menu.permissions.findIndex((permission: any) => permission === permissionId);
                if (index !== -1) { 
                    menu.permissions.splice(index, 1);
                }
            }
        }
        setSelectedPermissions(tempPermissions);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const payload = {
            id: roleToView.id,
            menus: selectedPermissions.filter((permission: any) => permission.permissions.length)
        };
        const { data, code } = await RoleAssignService(payload);
        setLoading(false);
        if (code === 200 && data) {
            dispatch(showSnackbar({ message: 'Role updated successfully', type: 'success', open: true }));
            handleClose();
        } else {
            dispatch(showSnackbar({ message: 'Failed to update role', type: 'error', open: true }));
        }
    };
    
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={dialogOpen}
            onClose={handleClose}
        >
            <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10} >
                            <Typography variant="h4">Assign Permission</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ mt: 2 }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    roleViewData ? (
                        <>
                            {roleViewData.menus.map((menu: any) => (
                                <Card key={menu.id} sx={{ marginBottom: 2 }}>
                                    <CardContent sx={{ p: 0, border: '1px solid #eee' }}>
                                        <Typography variant="h6" sx={{ mb: 1, borderBottom: '1px solid #f1f1f1', p: '15px', backgroundColor: '#eee' }}>
                                            {menu.name}
                                        </Typography>
                                        {menu.sub_menus ? (
                                            menu.sub_menus.map((subMenu: any) => (
                                                <Card key={subMenu.id} sx={{ margin: 2 }}>
                                                    <CardContent sx={{ p: 0, border: '1px solid #eee' }}>
                                                        <Typography variant="h6" sx={{ mb: 1, borderBottom: '1px solid #f1f1f1', p: '15px', backgroundColor: '#eee' }}>
                                                            {subMenu.name}
                                                        </Typography>
                                                        <Grid container spacing={2} p={2}>
                                                            {subMenu.permissions && subMenu.permissions.map((permission: any) => (
                                                                <Grid item key={permission.id} xs={12} lg={3}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={checkIfPermissionAssigned(subMenu.id, permission.id)}
                                                                                onChange={(event: any) => handleCheckboxChange(event, subMenu.id, permission.id)}
                                                                            />
                                                                        }
                                                                        label={permission.permission_name}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            ))
                                        ) : (
                                            <Grid container spacing={2} p={2}>
                                                {menu.permissions && menu.permissions.map((permission: any) => (
                                                    <Grid item key={permission.id} xs={12} lg={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={checkIfPermissionAssigned(menu.id, permission.id)}
                                                                    onChange={(event: any) => handleCheckboxChange(event, menu.id, permission.id)}
                                                                />
                                                            }
                                                            label={permission.permission_name}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </CardContent>
                                </Card>
                            ))}
                        </>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            No role details available.
                        </Typography>
                    )
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Close
                </Button>
                <Button onClick={handleSubmit} variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewRoleDialog;
