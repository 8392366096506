import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { ProjectExportService, DistrictExportService, LetterExportService, SummaryExportService, PendingocExportService } from '../../../utils/services/manage.service';
import { Button, Menu } from '@mui/material';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
import { GetPermissions } from '../../../utils/utils';
interface ExportProps {
    headCells: any[];
    visibleColumns: string[];
    rowsPerPage: number;
    page: number;
    searchTerm: string;
    period:string;
    selectedIds: any;
    typeId:any;
}

const Export: React.FC<ExportProps> = ({ headCells, visibleColumns, rowsPerPage, page, searchTerm, selectedIds, period, typeId }) => {
    const dispatch = useDispatch();
    const handleExport = async () => {
        const headers = headCells
            .filter((cell: any) => cell.id !== 'action') 
            .map((cell: any) => cell.id === 'is_blocked' || cell.id === 'is_cancelled' || visibleColumns.includes(cell.id) ? cell.label : null)
            .filter((label: any) => label);    
        const payload = {
            limit: rowsPerPage, 
            offset: page * rowsPerPage, 
            sort_by: 'id', 
            order_by: 'asc', 
            search: searchTerm, 
            headers, 
            start_date: selectedIds.start_date, 
            end_date: selectedIds.end_date, 
            period: period,  
            type_id: typeId,
            ...selectedIds
        };
        const data = await LetterExportService(payload);
        if (data && data.data) {
            const fileUrl = data.data.filePath;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            const message =  'file exported successfully';
            dispatch(showSnackbar({ message, type: 'success', open: true }));
        } else {
            console.error('Export failed: no file path received');
        }       
    }; 
    const handleDistrictExport = async () => {
        const { status_id, taluka_id, project_id, current_type_id, ...filteredSelectedIds } = selectedIds;
        const payload = {
            limit: rowsPerPage, 
            offset: page * rowsPerPage, 
            sort_by: 'id', 
            order_by: 'asc', 
            search: searchTerm, 
            start_date: selectedIds.start_date, 
            end_date: selectedIds.end_date,
            period: period, 
            type_id: typeId,
             ...filteredSelectedIds
        };
        const data = await DistrictExportService(payload);
        if (data && data.data) {
            const fileUrl = data.data.data;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            const message =  'file exported successfully';
            dispatch(showSnackbar({ message, type: 'success', open: true }));
        } else {
            console.error('Export failed: no file path received');
        }       
    };
    const handleProjectExport = async () => {
        const { status_id, current_type_id, ...filteredSelectedIds } = selectedIds;
        const payload = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: 'id',
            order_by: 'asc',
            search: searchTerm,
            start_date: selectedIds.start_date,
            end_date: selectedIds.end_date,
            period: period,
            type_id: typeId,
            ...filteredSelectedIds
        };
        const data = await ProjectExportService(payload);
        if (data && data.data) {
            const fileUrl = data?.data?.filePath;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            const message =  'file exported successfully';
            dispatch(showSnackbar({ message, type: 'success', open: true }));
        } else {
            const errorMessage = data.data || 'An error occurred';
            dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
        }       
    };
    const handleSummaryExport = async () => {
        const { status_id, taluka_id, project_id, current_type_id, ...filteredSelectedIds } = selectedIds;
        const payload = {
            limit: rowsPerPage, 
            offset: page * rowsPerPage, 
            sort_by: 'id', 
            order_by: 'asc', 
            search: searchTerm, 
            start_date: selectedIds.start_date, 
            end_date: selectedIds.end_date,
            period: period, 
            type_id: typeId,
             ...filteredSelectedIds
        };
        const data = await SummaryExportService(payload);
        if (data && data.data && data.code == 200) {
            const fileUrl = data.data.filePath;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            const message =  'file exported successfully';
            dispatch(showSnackbar({ message, type: 'success', open: true }));
        } else {
            console.error('Export failed: no file path received');
        }       
    }; 
    const handlePendingOcExport = async () => {
        const headers = headCells
        .filter((cell: any) => cell.id !== 'action') 
        .map((cell: any) => cell.id === 'is_blocked' || cell.id === 'is_cancelled' || visibleColumns.includes(cell.id) ? cell.label : null)
        .filter((label: any) => label);    
        const payload = {
            limit: rowsPerPage, 
            offset: page * rowsPerPage, 
            sort_by: 'id', 
            order_by: 'asc', 
            search: searchTerm, 
            headers, 
            start_date: selectedIds.start_date, 
            end_date: selectedIds.end_date, 
            period: period,  
            type_id: typeId,
            ...selectedIds
        };
        
        const data = await PendingocExportService(payload);
        if (data && data.data) {
            const fileUrl = data.data.filePath;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            const message =  'file exported successfully';
            dispatch(showSnackbar({ message, type: 'success', open: true }));
        } else {
            console.error('Export failed: no file path received');
        }   
    }; 
    const permissions: any = GetPermissions('letter');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
        {(permissions.includes('export') || permissions.includes('district_export') || permissions.includes('project_export') || permissions.includes('summary_export')) && (
            <Box sx={{ width: 'auto', maxWidth: '100%', display: 'flex', alignItems: 'center', gap: 1 }} p={0}>
                <FormControl fullWidth size="small">
                    <Button variant="contained" color="success" onClick={handleClick}>
                        Export Data
                    </Button>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        {permissions.includes('export') && (
                            <MenuItem onClick={() => { handleExport(); handleClose(); }}>Export All</MenuItem>
                        )}
                        {permissions.includes('district_export') && (
                            <MenuItem onClick={() => { handleDistrictExport(); handleClose(); }}>District Category</MenuItem>
                        )}
                        {permissions.includes('project_export') && (
                            <MenuItem onClick={() => { handleProjectExport(); handleClose(); }}>Project Category</MenuItem>
                        )}
                        {permissions.includes('summary_export') && (
                            <MenuItem onClick={() => { handleSummaryExport(); handleClose(); }}>Summary Export</MenuItem>
                        )}
                    </Menu>
                </FormControl>
            </Box>
        )}
        {permissions.includes('pending_oc') && (
            <Button variant="contained" color="error" onClick={handlePendingOcExport}>
                Pending OC's
            </Button>
        )}
        </>
    );
};

export default Export;
