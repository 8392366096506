import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
    showFilter: boolean;
    selectedConditions: string[];
}

const initialState: FilterState = {
    showFilter: false,
    selectedConditions: [],
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        toggleFilter: (state) => {
            state.showFilter = !state.showFilter;
        },
        addCondition: (state, action: PayloadAction<string>) => {
            if (!state.selectedConditions.includes(action.payload)) {
                state.selectedConditions.push(action.payload);
            }
        },
        removeCondition: (state, action: PayloadAction<string>) => {
            state.selectedConditions = state.selectedConditions.filter(condition => condition !== action.payload);
        },
        clearConditions: (state) => {
            state.selectedConditions = [];
        },
    },
});

export const { toggleFilter, addCondition, removeCondition, clearConditions } = filterSlice.actions;
export default filterSlice.reducer;
