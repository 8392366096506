import { Container, Box, Avatar, Typography, TextField, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { setAuthUser } from '../../../redux/reducer/authUserSlice'
import { LoginResponse } from '../../../utils/dto/response';
import { loginService } from '../../../utils/services/auth.service';
import { encryptText } from '../../../utils/utils';
import { useState } from 'react';
import { UserDetailService } from '../../../utils/services/user.service';
const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<any>(null);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setError(false);
        const formData = new FormData(event.currentTarget);
        const payload = {
            email: formData.get('email') as string,
            password: formData.get('password') as string,
        }
        setLoading(true);
        const { code, data }: { code: number, data: LoginResponse | null } = await loginService(payload);
        setLoading(false)
        if (code === 200 && data) {
            const encrypted = JSON.stringify(data);
            localStorage.setItem('user', encryptText(encrypted));
            dispatch(setAuthUser(data));
            await fetchUserDetails(data);
            navigate('/manage');
        } else {
            setError(true);
        }
    };    
    const fetchUserDetails = async (payload: any) => {
        setLoading(true);
        const { data, code } = await UserDetailService(payload);
        setLoading(false);      
        if (code === 200 && data) {
            const dt = {
                auth_token: payload.auth_token,
                expires_at: payload.expires_at,
                user: data.user_details,
                menus: data.menus,
                user_mapping: data.user_mapping,
                roles: data.roles,                    
            };
            const encrypted = JSON.stringify(dt);
            localStorage.setItem('user', encryptText(encrypted));
            dispatch(setAuthUser(dt));
        }
    };
  
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 6
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.light' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h5">
                    Sign in
                </Typography>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        size='small'
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        size='small'
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {error && <Typography variant='body1' color='text.error'>Invalid Email or Password</Typography>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }} disabled={loading}
                        size='large'
                    >Sign In</Button>
                </Box>
            </Box>

        </Container>
    )
}

export default LoginPage;