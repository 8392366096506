import React from 'react';
import { TableHead, TableRow, TableCell, Box, IconButton } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
interface HeadCell {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    width?: number;
    sortable: boolean;
}
interface TableHeadListProps {
    headCells: HeadCell[];
    onSort: (id: string, order: 'asc' | 'desc') => void;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
}

const TableHeadList: React.FC<TableHeadListProps> = ({ headCells, onSort, sortBy, sortOrder }) => {
    const handleSortClick = (id: string) => {
        const isAsc = sortBy === id && sortOrder === 'asc';
        onSort(id, isAsc ? 'desc' : 'asc');
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sx={{ p: 1, borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300', bgcolor: 'grey.200', position: 'sticky', top: 0, zIndex: 1 }}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        width={headCell.width}
                        align={headCell.numeric ? 'right' : 'left'}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>{headCell.label}</Box>
                            {headCell.sortable && (
                                <Box sx={{ display: 'flex' }}>
                                    <IconButton size="small" onClick={() => handleSortClick(headCell.id)}>
                                        <UnfoldMoreIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default TableHeadList;
