import { Add } from "@mui/icons-material"
import { Badge, styled, Box, Button, Paper, TableContainer, Table, TableRow, TableCell, Checkbox, TableBody, TablePagination, Avatar, Tooltip, Menu, MenuItem, Chip, IconButton, ListItemIcon, useMediaQuery, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Grid, TextField, AccordionDetails, Accordion, Autocomplete, FormControl, AccordionSummary, AccordionSlots, Fade, Popover  } from '@mui/material'
import PageHead from "../../../../components/admin/PageHead"
import TableHeadList from "../../../../components/table/TableHeadList";
import { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { RegionListService } from '../../../../utils/services/region.service';
import AddRegionDialog from "./addRegionDialog";
import DeleteRegionDialog from "./deleteRegionDialog";
import { StyledTableCell } from "../../../../components/StyledComponents";
import { StateListService } from "../../../../utils/services/state.service";
import { FilterListService } from "../../../../utils/services/filter.service";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { GetPermissions } from "../../../../utils/utils";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockIcon from '@mui/icons-material/Block';
const headCells: any = [
    {
        id: 'srNo',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 50,
        sortable: false
    },
    {
        id: 'states',
        numeric: false,
        disablePadding: false,
        label: 'States',
        width: 200,
        sortable: false
    },
    {
        id: 'company',
        numeric: false,
        disablePadding: false,
        label: 'Company',
        width: 600,
        sortable: false
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Region',
        width: 600,
        sortable: true
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
        width: 50,
        sortable: false
    },
];

const RegionMaster = () => {
    const [popoverRowId, setPopoverRowId] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentRegion, setCurrentRegion] = useState<any>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [regionToDelete, setRegionToDelete] = useState<any>(null);
    const [region, setRegion] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>(''); 
    const [states, setStates] = useState<any[]>([]);   
    const [companies, setCompanies] = useState<any[]>([]);
    const [expanded, setExpanded] = React.useState(false);
    const userDetails = useSelector((state: RootState) => state.authUser.user);
    const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';
    const initialCompany = companies.find((company: { value: number }) => company.value === userDetails?.company_id)?.label || '';   
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
     const [selectedIds, setSelectedIds] = useState({
        state_id: undefined as number | undefined,
        company_id: undefined as number | undefined,
    });
    const [formValues, setFormValues] = useState({
        state: '',
        company: '',
    });
    useEffect(() => {       
        fetchRegions();
    }, [page, rowsPerPage, searchTerm, sortOrder, sortBy]);
    const fetchRegions = async () => {
        setLoading(true);
        const payload = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: sortBy,
            order_by: sortOrder,
            search: searchTerm,
            ...selectedIds,
        };
        const { data, code } = await RegionListService(payload);
        if (code === 200 && data) {
            setRegion(data.data);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };
    const handleOpenDialog = () => {
        setCurrentRegion(null);
        setDialogOpen(true);
    };

    const handleOpenEditDialog = (region: any) => {
        setCurrentRegion(region);
        setDialogOpen(true);
        console.log("region", region);
    };
    const handleOpenDeleteDialog = (region: any) => {
        setRegionToDelete(region);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const handleSuccess = () => {
        fetchRegions(); 
    };  
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };

    const getDropdownList = async (type: string, id?: number) => {
        setLoading(true);
        const payload = {
            type: type,
            id: id,
        };
        const { data, code } = await FilterListService(payload);
        if (data && code === 200) {
            if (type === 'state') {
                setCompanies(data.data);
            }{}
        }
        setLoading(false);
    };
    
    const handleInputChange = (field: string, value: string) => {
        setFormValues({ ...formValues, [field]: value });
    };

    const handleChange = (type: string, newValue: string | null) => {
        const idMap: { [key: string]: number | undefined } = {
            state: states.find((state: any) => state.name === newValue)?.id,
            company: companies.find((company: any) => company.label === newValue)?.value,
        };

        setFormValues({ ...formValues, [type]: newValue || '' });
        setSelectedIds({ ...selectedIds, [`${type}_id`]: idMap[type] });

        if (idMap[type] !== undefined) {
            getDropdownList(type, idMap[type]);
        }
    };
    // const handleSubmit = () => {
    //     fetchRegions();
    // };
    const handleSubmit = () => {
        const { state, company } = formValues;
        if (!state && !company) {
          setError('Please select fields');
        } else {
          setError(''); 
          fetchRegions();
        }
    };
    const handleReset = () => {
        setFormValues({
            state: '',
            company: '',
        });
        setSelectedIds({
            state_id: undefined,
            company_id: undefined,
        });
        fetchRegions();
        window.location.reload()
    };
    const handleSearchReset = () => {
        setSearchTerm('');
    }     
    const permissions = GetPermissions('region_master');         
               
    useEffect(() => {
        if (initialState) {
          setFormValues((prevValues) => ({
            ...prevValues,
            state: initialState,
          }));
          const initialStateId = states.find((state: { name: string }) => state.name === initialState)?.id;
          setSelectedIds((prevIds) => ({
            ...prevIds,
            state_id: initialStateId,
          }));
        }
        if (initialCompany) {
          setFormValues((prevValues) => ({
            ...prevValues,
            company: initialCompany,
          }));
          const initialCompanyId = companies.find((company: { label: string }) => company.label === initialCompany)?.value;
          setSelectedIds((prevIds) => ({
            ...prevIds,
            company_id: initialCompanyId,
          }));
        }
      }, [initialState, initialCompany, states, companies]);
      useEffect(() => {
        if (userDetails?.state_id) {
            getDropdownList('state', userDetails.state_id);
        }
    }, [userDetails?.state_id]);     
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };   
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, rowId: any) => {
        setAnchorEl(event.currentTarget);
        setPopoverRowId(rowId);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverRowId(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined; 
    return (
        <>
            <Box>
                <Box sx={{ px: 2, py: 1.5 }}>
                    <PageHead primary='Region Master' />
                </Box>
                <Accordion expanded={expanded}
                    onChange={handleExpansion}
                    slots={{ transition: Fade as AccordionSlots['transition'] }}
                    slotProps={{ transition: { timeout: 400 } }}
                    sx={{
                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                    }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        <Typography>Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container columnSpacing={3}>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.state}
                                            onChange={(event: any, newValue: string | null) => handleChange('state', newValue)}
                                            inputValue={formValues.state}
                                            onInputChange={(event, newInputValue) => handleInputChange('state', newInputValue)}
                                            options={states.map((state: any) => state.name)}
                                            disableClearable
                                            freeSolo={false}
                                            disabled={initialState}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="States"
                                                
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.company}
                                            onChange={(event: any, newValue: string | null) => handleChange('company', newValue)}
                                            inputValue={formValues.company}
                                            onInputChange={(event, newInputValue) => handleInputChange('company', newInputValue)}
                                            options={companies.map((company: any) => company.label)}
                                            disableClearable
                                            freeSolo={false}
                                            disabled={initialCompany}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Company"
                                                
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <Button onClick={handleSubmit} variant="contained" color="info" sx={{marginTop:'3px'}}>
                                        Filter Data
                                    </Button>
                                    <Button onClick={handleReset} variant="outlined" color="primary" sx={{marginTop:'3px', ml: 2}}>
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                            {error && (
                                <Grid item xs={12} textAlign={'left'} mt={2}>
                                    <Typography color="error" variant="body2" sx={{fontWeight:'400 !important'}}>{error}</Typography>
                                </Grid>
                            )}
                        </AccordionDetails>
                </Accordion>
                                
                <Paper>
                    <Box sx={{ borderTopWidth: 1, borderRightWidth: 0, borderBottomWidth: 1, borderLeftWidth: 0, borderStyle: 'solid', borderColor: 'grey.400', px: 2, py: 1 }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} lg={9} >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                {permissions.includes('Add') && (
                                    <Button variant='contained' startIcon={<Add />} onClick={handleOpenDialog}>Add Region</Button>
                                )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={3} display={'flex'} textAlign={'right'} alignItems={'center'}>
                                <TextField fullWidth
                                    label="Search"
                                    placeholder="Search here ..."
                                    variant="outlined"
                                    size="small"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <Button onClick={handleSearchReset} variant="contained" color="info" sx={{ ml: 2}}>
                                    Reset
                                </Button>
                            </Grid>                           
                        </Grid>  
                    </Box>
                    <TableContainer sx={{ maxHeight: 600 }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small' stickyHeader aria-label="sticky table">
                            <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                            {permissions.includes('List') ? (
                            <TableBody>
                                    {region.length > 0 ? (
                                        region.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {index + 1 + page * rowsPerPage}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.state_name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.company_name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>                                           
                                        <Button aria-describedby={id} onClick={(e) => handleClickPopover(e, row.id)}>
                                            <MoreVertIcon />
                                        </Button>
                                        {popoverRowId === row.id && (
                                            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                                                <Box sx={{display:'flex', flexDirection:'column', gap:2}}>
                                                    {permissions.includes('Edit') && (
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Edit</Typography> 
                                                            <Button size="small" variant="contained"  title="Edit" onClick={() => handleOpenEditDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                    <ModeEditOutlinedIcon sx={{fontSize:'16px'}} />
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    {permissions.includes('Delete') && (
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Delete</Typography>
                                                            <Button size="small" color="error" variant="contained"  title="Delete" onClick={() => handleOpenDeleteDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                <DeleteOutlineOutlinedIcon sx={{fontSize:'16px'}} />
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Popover>
                                        )}
                                        </StyledTableCell>
                                    </TableRow>
                                ))
                                ) : (
                                    <TableRow>
                                        <StyledTableCell colSpan={5} align="center">
                                            {loading ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">
                                                    No regions found.
                                                </Typography>
                                            )}
                                        </StyledTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                             ) : (
                                <TableRow>
                                    <StyledTableCell colSpan={6} align="center" sx={{p:1}}>
                                        <BlockIcon sx={{fontSize:'40px', color:"#0288d1"}}/>
                                        <Typography variant="h5" color="#0288d1" fontWeight={300}>
                                           Sorry, you cannot view the page.
                                        </Typography>
                                        <Typography variant="body1" color="error" fontWeight={300} pt={1}>
                                           No permission to access this page
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            )} 
                        </Table>
                    </TableContainer>     
                    <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />                   
                </Paper>
             </Box>
             <AddRegionDialog dialogOpen={dialogOpen}  handleCloseDialog={handleCloseDialog} currentRegion={currentRegion} handleSuccess={handleSuccess} />
             <DeleteRegionDialog dialogOpen={deleteDialogOpen} regionToDelete={regionToDelete} handleClose={handleCloseDeleteDialog} handleSuccess={handleSuccess} /> 
        </>
    )
}
export default RegionMaster