import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { isLoggedIn } from '../utils/utils';

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
    // const authUser = useSelector((state: RootState) => state.authUser);
    // if (isLoggedIn(authUser)) {
    //     if (authUser.user) {
    //         if (authUser.user.user_type === "company") {
    //             return <Navigate to='/user/exhibitor' />
    //         } else if (authUser.user.user_type === "visitor") {
    //             return <Navigate to='/user/visitor' />
    //         }
    //     } else {
    //         return <Navigate to='/admin/dashboard' />
    //     }
    // }

    return (
        <>{children}</>
    )
}

export default AuthLayout;