import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from '../layout/AuthLayout';
import LoginPage from './Auth/login';
import Error404 from './error/Error404';


const AuthRoute = () => {
    return (
        <>
            <Routes>
                <Route path='/login' element={<AuthLayout><LoginPage /></AuthLayout>}></Route>
                <Route path='*' element={<Error404 />}></Route>
            </Routes>
        </>
    )
}

export default AuthRoute;