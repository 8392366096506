import { Navigate, Route, Routes } from 'react-router-dom';
import AdminLayout from '../layout/AdminLayout';
import Error404 from './error/Error404';
import MasterRoute from './MasterRoute';
import ManageMaster from './admin/manage';
import UploadLetter from './admin/manage/upload';
import ViewLetter from './admin/manage/view';


const AdminRoute = () => {
    return (
        <>
            <Routes>
                {/* <Route path='/' element={<Navigate to='/admin/dashboard' />}></Route> */}
                <Route path='/' element={<Navigate to='/manage' />}></Route>
                <Route path='*' element={<AdminLayout><Error404 /></AdminLayout>}></Route>
                <Route path='/master/*' element={<MasterRoute />}></Route>
                <Route path='/manage' element={<AdminLayout><ManageMaster /></AdminLayout>}></Route>
                <Route path='/upload-letter' element={<AdminLayout><UploadLetter /></AdminLayout>}></Route>
                <Route path='/view-letter' element={<AdminLayout><ViewLetter /></AdminLayout>}></Route>
            </Routes>
        </>
    )
}

export default AdminRoute;