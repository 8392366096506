import { Theme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import MiniDrawerStyled from "./MiniDrawerStyled";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useSettings } from "../../../providers/SettingsProvider";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import RoofingSharpIcon from '@mui/icons-material/RoofingSharp';
import RoomSharpIcon from '@mui/icons-material/RoomSharp';
import GpsFixedSharpIcon from '@mui/icons-material/GpsFixedSharp';
import ExploreSharpIcon from '@mui/icons-material/ExploreSharp';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import ContentPasteSearchSharpIcon from '@mui/icons-material/ContentPasteSearchSharp';
import FormatAlignRightSharpIcon from '@mui/icons-material/FormatAlignRightSharp';
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import ListIcon from '@mui/icons-material/List';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Person3SharpIcon from '@mui/icons-material/Person3Sharp';
import { GetPermissions } from "../../../utils/utils";
const RenderNavs = () => {
  const [openMaster, setOpenMaster] = useState(false);

  const handleMasterClick = () => {
    setOpenMaster(!openMaster);
  };
  const permissions: any = GetPermissions();
  console.log("permissions", permissions);
  
  return (
    <List component="nav" sx={{ mt: "59px" }}>
        <>
         
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={handleMasterClick}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <StorefrontIcon sx={{ fontSize: "25px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Master" />
                  {openMaster ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openMaster} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {GetPermissions('user_master').length > 0 && (
                    <ListItem disablePadding> 
                      <NavLink to="/master/user" className={({ isActive }) => isActive ? "menu-bar-item-active" : "menu-bar-item-inactive" } style={{ width: "100%" }} > 
                        <ListItemButton sx={{ pl: 4 }}> <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <Person3SharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="User" /> 
                      </ListItemButton> 
                      </NavLink> 
                    </ListItem>
                  )}
                  {GetPermissions('role_master').length > 0 && (
                    <ListItem disablePadding> 
                      <NavLink to="/master/role" className={({ isActive }) => isActive ? "menu-bar-item-active" : "menu-bar-item-inactive" } style={{ width: "100%" }} > 
                        <ListItemButton sx={{ pl: 4 }}> 
                          <ListItemIcon sx={{ minWidth: "40px" }}> 
                          <SettingsSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Role" /> 
                        </ListItemButton> 
                      </NavLink> 
                    </ListItem>
                   )}
                 {GetPermissions('state_master').length > 0 && (
                    <ListItem disablePadding> 
                      <NavLink to="/master/state" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                        <ListItemButton sx={{ pl: 4 }}> 
                          <ListItemIcon sx={{ minWidth: "40px" }}> 
                          <RoofingSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="State" /> 
                        </ListItemButton> 
                      </NavLink> 
                    </ListItem>
                  )}
                {GetPermissions('company_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/company" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <AccountBalanceSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Company" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                {GetPermissions('region_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/region" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <RoomSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Region" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                   {GetPermissions('district_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/district" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <GpsFixedSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="District" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                {GetPermissions('taluka_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/taluka" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <ExploreSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Taluka" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                {GetPermissions('project_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/project" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <AssignmentSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Project" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
               
                {/* {GetPermissions('menu_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/menu" className={({ isActive }) => isActive ? "menu-bar-item-active" : "menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <ListIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Menu" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                 */}
                {GetPermissions('category_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/category" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <WidgetsSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Category" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                {GetPermissions('type_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/type" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <FormatAlignRightSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Type" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                {GetPermissions('status_master').length > 0 && (
                  <ListItem disablePadding> 
                    <NavLink to="/master/status" className={({ isActive }) => isActive ? "menu-bar-item-active" : 	"menu-bar-item-inactive" } style={{ width: "100%" }} > 
                      <ListItemButton sx={{ pl: 4 }}> 
                        <ListItemIcon sx={{ minWidth: "40px" }}> 
                        <QueryStatsSharpIcon sx={{ fontSize: "22px" }} /> </ListItemIcon> <ListItemText primary="Status" /> 
                      </ListItemButton> 
                    </NavLink> 
                  </ListItem>
                   )}
                </List>
                
              </Collapse>
            </>
          {GetPermissions('letter').length > 0 && (
          <ListItem disablePadding>
            <NavLink
              to="/manage"
              className={({ isActive }) =>
                isActive ? "menu-bar-item-active" : "menu-bar-item-inactive"
              }
              style={{ width: "100%" }}
            >
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <MailOutlineIcon sx={{ fontSize: "25px" }} />
                </ListItemIcon>
                <ListItemText primary="Manage" />
              </ListItemButton>
            </NavLink>
          </ListItem>
            )}
        </>
    </List>
  );
};

const AdminNavBar = ({ window }: any) => {
  const { isDashboardDrawerOpened, setIsDashboardDrawerOpened } = useSettings();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ flexShrink: { md: 0 }, zIndex: 100 }}
        aria-label="mailbox folders"
      >
        {!matchDownMD ? (
          <MiniDrawerStyled variant="permanent" open={isDashboardDrawerOpened}>
            <RenderNavs />
          </MiniDrawerStyled>
        ) : (
          <Drawer
            container={container}
            variant="temporary"
            open={isDashboardDrawerOpened}
            onClose={setIsDashboardDrawerOpened}
            ModalProps={{ keepMounted: true }}
            sx={{
              zIndex: 100,
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 260,
                borderRight: "1px solid",
                borderRightColor: "divider",
                backgroundColor: "#fff",
                boxShadow: "inherit",
              },
            }}
          >
            <RenderNavs />
          </Drawer>
        )}
      </Box>
    </>
  );
};

export default AdminNavBar;
