
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SubMenu {
    id: number;
    name: string;
    slug: string;
    url: string;
    permissions: string[];
}

export interface Menu {
    id: number;
    name: string;
    slug: string;
    url: string;
    icon: string;
    permissions: string[];
    sub_menus: SubMenu[] | null;
}

export interface UserDetails {
    id: number;
    name: string;
    email: string;
    mobile_number: number;
}

export interface UserMapping {
    region_id: number;
    region: string;
    district_id: number;
    district: string;
    id: number;
}

export interface Role {
    id: number;
    name: string;
    slug: string;
}
interface PermissionsState {
    permissions: Menu[];
    user_details: UserDetails | null;
    user_mapping: UserMapping[];
    roles: Role[];
}

const initialState: PermissionsState = {
    permissions: [],
    user_details: null,
    user_mapping: [],
    roles: [],
};

const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setPermissions(state, action: PayloadAction<Menu[]>) {
            state.permissions = action.payload;
        },
        setUserDetail(state, action: PayloadAction<UserDetails>) {
            state.user_details = action.payload;
        },
        setUserMapping(state, action: PayloadAction<UserMapping[]>) {
            state.user_mapping = action.payload;
        },
        setRoles(state, action: PayloadAction<Role[]>) {
            state.roles = action.payload;
        },
    },
});

export const { setPermissions, setUserDetail, setUserMapping, setRoles } = permissionsSlice.actions;
export default permissionsSlice.reducer;

