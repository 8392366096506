import { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography, TableContainer, Table, TableRow, TableCell, TableBody, TablePagination, Paper, CircularProgress, Grid, Box, IconButton, Tooltip } from '@mui/material';
import {  LetterParentDetailsService } from '../../../utils/services/manage.service';
import React from 'react';
import TableHeadList from "../../../components/table/TableHeadList";
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryIcon from '@mui/icons-material/History';
import ParentHistoryDialog from './parentHistory';
interface ParentDialogProps {
    dialogOpen: boolean;
    manageToParent: any;
    handleClose: () => void;
    handleSuccess: () => void;
}
const headCells: any = [
    {
        id: 'srNo',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 20
    },   
    {
        id: 'refrence',
        numeric: false,
        disablePadding: false,
        label: 'Refrence No.',
        width: 200
    },
    {
        id: 'subject',
        numeric: false,
        disablePadding: false,
        label: 'Subject',
        width: 200
    },
    {
        id: 'project',
        numeric: false,
        disablePadding: false,
        label: 'Project',
        width: 200
    },
    {
        id: 'user',
        numeric: false,
        disablePadding: false,
        label: 'User',
        width: 200
    },
    {
        id: 'createdat',
        numeric: false,
        disablePadding: false,
        label: 'Created At',
        width: 200
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
        width: 50
    },
];
const ParentDetailDialog: React.FC<ParentDialogProps> = ({ dialogOpen, manageToParent, handleClose, handleSuccess }) => {
    const [parentDetails, setparentDetails]= useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [parentHistoryDialogOpen, setParentHistoryDialogOpen] = useState<boolean>(false);
    const [manageToParentHistory, setManageToParentHistory] = useState<any>(null);
    useEffect(() => {
        if (dialogOpen && manageToParent && manageToParent.id) {
            fetchParent();
        }
    }, [dialogOpen, manageToParent, rowsPerPage, page]);

    const fetchParent = async () => {
        if (!manageToParent || !manageToParent.id) {
            return;
        }
        const payload = {
            letter_id: manageToParent.id,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: 'id',
            order_by: 'asc',
            search: ''
        };
        setLoading(true);
        const { data, code } = await LetterParentDetailsService(payload);
        if (code === 200 && data) {
            setparentDetails(data.data);
        }
        setLoading(false);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    const handleClickView = (row: any) => {
        window.open(`/view-letter?district_id=${Number(row.district_id)}&letter_id=${Number(row.id)}`, '_blank');
    };    
    const handleOpenParentHistoryDialog = (manage: any) => {
        setManageToParentHistory(manage);
        setParentHistoryDialogOpen(true);  
    };      
    const handleCloseParentHistoryDialog = () => {
        setParentHistoryDialogOpen(false);
    };
    return (
        <>
            <Dialog fullWidth maxWidth="lg" open={dialogOpen} onClose={handleClose}>
                <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px', mb:2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} md={10}>
                                <Typography variant="h4">Parent Details</Typography>
                            </Grid>
                            <Grid item xs={12} md={2} textAlign={"end"}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium'>
                            <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                                <TableBody>
                                    {parentDetails.length > 0 ? (
                                        parentDetails.map((row, index) => (
                                        <TableRow key={row.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                {row.reference_number}
                                            </TableCell>
                                            <TableCell sx={{maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300'  }}>
                                                <Tooltip title={row.subject}>
                                                    <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.subject}</Typography>
                                                </Tooltip>
                                            </TableCell> 
                                            <TableCell sx={{ maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                <Tooltip title={row.village_name || row.taluka_name || row.district_name
                                                ? `${row.village_name || '-'}, Taluka- ${row.taluka_name || '-'}, District- ${row.district_name || '-'}`
                                                : '-'} >
                                                    <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                        {row.village_name  ? `${row.village_name || '-'}` : '-'}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                {row.created_name}
                                            </TableCell>                                        
                                            <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                {dayjs(row.created_at).format('DD-MM-YYYY  | HH:mm:ss')}
                                            </TableCell>
                                            <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                <Button size="small" variant="contained" color="info" title="View Letter" onClick={() => handleClickView(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                    <VisibilityIcon sx={{fontSize:'16px'}} />
                                                </Button>
                                                {row.parent_id !== 0 && (
                                                    <Button color='success' size="small" variant="contained" title="Parent History" onClick={() => handleOpenParentHistoryDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                        <HistoryIcon sx={{fontSize:'16px'}} />
                                                    </Button>  
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            {loading ? (
                                                <CircularProgress size={4} />
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">
                                                    No records found.
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>   
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <ParentHistoryDialog dialogOpen={parentHistoryDialogOpen} manageToParentHistory={manageToParentHistory} handleClose={handleCloseParentHistoryDialog} handleSuccess={handleSuccess}/>
        </>
    )
}
 export default ParentDetailDialog