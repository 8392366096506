import { createContext, useState, ReactNode, useContext } from 'react'

export type SettingsContextValue = {
	isDashboardDrawerOpened: boolean,
	setIsDashboardDrawerOpened: () => void,
}

export const SettingsContext = createContext<SettingsContextValue>({
	isDashboardDrawerOpened: true,
	setIsDashboardDrawerOpened: () => null,
})

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
	const [isDashboardDrawerOpened, saveIsDashboardDrawerOpened] = useState<boolean>(window.innerWidth >= 1200)

	const setIsDashboardDrawerOpened = () => saveIsDashboardDrawerOpened(!isDashboardDrawerOpened);

	return <SettingsContext.Provider value={{ isDashboardDrawerOpened, setIsDashboardDrawerOpened }}>{children}</SettingsContext.Provider>
}

export const SettingsConsumer = SettingsContext.Consumer

export const useSettings = (): SettingsContextValue => useContext(SettingsContext)