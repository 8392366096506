import { createSlice } from '@reduxjs/toolkit'
import { decryptText } from '../../utils/utils';

const auth = JSON.parse(decryptText(localStorage.getItem('user')) || '{}');

const initialState = {
	user: auth.user || null,
	auth_token: auth.auth_token || null,
	expires_at: auth.expires_at || null,
	menus: auth.menus,
	user_mapping: auth.user_mapping,
	roles: auth.roles,
}

const authUserSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthUser: (state, action) => {
			return {...state, ...action.payload};
		},
		deleteAuthUser: () => {
			return { user: {}, auth_token: null, expires_at: null, menus: null, user_mapping: null, roles: null, user_details:null };
		}
	},
});

export const { setAuthUser, deleteAuthUser } = authUserSlice.actions;

export default authUserSlice.reducer