import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Autocomplete, createFilterOptions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TalukaCreateService } from '../../../../utils/services/taluka.service';
import { FilterListService } from '../../../../utils/services/filter.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch, useSelector} from 'react-redux';
import { StateListService } from '../../../../utils/services/state.service';
import { RootState } from '../../../../redux/store';
interface AddTalukaDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
    currentTaluka:{ id: string; state: string, company:string, region:string, district:string, name:string, company_name:string, taluka_name:string, state_name:string, region_name:string, district_name:string, state_id:string, company_id:string,region_id:string, district_id:string  } | null;
}
interface StateOption {
    id: number;
    name: string;
}
const filterOptions = createFilterOptions<StateOption>({
    matchFrom: 'start',
    stringify: (option) => option.name,
});
const AddTalukaDialog: React.FC<AddTalukaDialogProps> = ({ dialogOpen, handleCloseDialog, currentTaluka, handleSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [states, setStates] = useState<any>([]);
    const [value, setValue] = React.useState<string | null>(null);
    const [companies, setCompanies] = useState<any>([]);
    const [regions, setRegions] = useState<any>([]);
    const [districts, setDistricts] = useState<any>([]);
    const isEditing = currentTaluka !== null;
    const userMapping = useSelector((state: RootState) => state.authUser.user_mapping);
    const userDetails = useSelector((state: RootState) => state.authUser.user);    
    const userRoles = useSelector((state: RootState) => state.authUser.roles);
    const isDistrictUser = userRoles?.some((role: { name: string }) => role.name === 'District User');
    const initialRegion = userMapping?.[0]?.region || currentTaluka?.region || '';
    const initialDistrict = userMapping?.[0]?.district || currentTaluka?.district || '';
    const initialCompany = companies.find((company: { value: number }) => company.value === userDetails?.company_id)?.label || '';   
    const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';
    const AddTalukaFormik = useFormik({
        initialValues: {
            id:currentTaluka?.id || '',
            state:currentTaluka?.state_name || initialState || '',
            company: currentTaluka?.company_name || initialCompany,            
            region:currentTaluka?.region_name || initialRegion,
            district:currentTaluka?.district_name || initialDistrict,
            name:currentTaluka?.name || ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            company: Yup.string().required('Please select a company'),
            state: Yup.string().required('Please select a state'),
            region: Yup.string().required('Please select a region'),
            district: Yup.string().required('Please select a district'),
            name: Yup.string().trim().required("Please enter taluka"),
        }),
        onSubmit: async (values, {resetForm}) => {
            setLoading(true);
            const stateId = states.find((state: { name: string; }) => state.name === values.state)?.id;
            const companyId = companies.find((company: { label: string; }) => company.label === values.company)?.value;
            const regionId = regions.find((region: { label: string; }) => region.label === values.region)?.value;
            const districtId = districts.find((district: { label: string; }) => district.label === values.district)?.value;
            let serviceResponse;
            if (isEditing) {
                const { data, code } = await TalukaCreateService({
                    id: currentTaluka.id,
                    state_id: stateId || currentTaluka.state_id,
                    company_id: companyId || currentTaluka.company_id,
                    region_id: regionId || currentTaluka.region_id,
                    district_id: districtId || currentTaluka.district_id,
                    name: values.name
                });
                serviceResponse = { data, code };
            } else {
                const { data, code } = await TalukaCreateService({
                    state_id: stateId,
                    company_id: companyId,
                    region_id: regionId,
                    district_id: districtId,
                    name: values.name
                });
                serviceResponse = { data, code };
            }
            setLoading(false);
            if (serviceResponse.data && serviceResponse.code === 200) {
                resetForm();
                handleCloseDialog();
                handleSuccess();
                const message = isEditing ? 'Taluka details updated' : 'Taluka created successfully';
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } else {
                const errorMessage = serviceResponse.data || 'An error occurred';
                dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
            }           
        },
    });

    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };
  
    const getDropdownList = async (type: string, id?: number) => {
        setLoading(true);
        const payload = {
            type: type,
            id: id,
        };
        const { data, code } = await FilterListService(payload);
        if (data && code === 200) {
            if (type === 'state') {
                setCompanies(data.data);
            } else if (type === 'company') {
                setRegions(data.data);
            } else if (type === 'region') {
                setDistricts(data.data);
            } 
        }
        setLoading(false);
    };
    useEffect(() => {
        if (userDetails?.state_id) {
            getDropdownList('state', userDetails.state_id);
        }
    }, [userDetails?.state_id]);
    useEffect(() => {
        if (userDetails?.company_id) {
            getDropdownList('company', userDetails.company_id);
        }
    }, [userDetails?.company_id]);
    useEffect(() => {
        if (userMapping && userMapping.length > 0) {
            const regionId = userMapping[0].region_id;
            if (regionId) {
                getDropdownList('region', regionId);
                const region = regions.find((r: { value: number }) => r.value === regionId);
                if (region) {
                    AddTalukaFormik.setFieldValue('region', region.label);
                }
            }
        } else {
            const regionId = regions.find((region: { label: string }) => region.label === AddTalukaFormik.values.region)?.value;
            if (regionId) {
                getDropdownList('region', regionId);
            }
        }
    }, [AddTalukaFormik.values.region, userMapping]);
    useEffect(() => {
        if (userMapping && userMapping.length > 0) {
            const regionId = userMapping[0].region_id;
            if (regionId) {
                getDropdownList('region', regionId);
                const region = regions.find((r: { value: number }) => r.value === regionId);
                if (region) {
                    AddTalukaFormik.setFieldValue('region', region.label);
                }
            }
        } else {
            const regionId = regions.find((region: { label: string }) => region.label === AddTalukaFormik.values.region)?.value;
            if (regionId) {
                getDropdownList('region', regionId);
            }
        }
    }, [AddTalukaFormik.values.region, userMapping]);

    useEffect(() => {
        if (userMapping && userMapping.length > 0) {
            const districtId = userMapping[0].district_id;
            if (districtId) {
                getDropdownList('district', districtId);
                const district = districts.find((r: { value: number }) => r.value === districtId);
                if (district) {
                    AddTalukaFormik.setFieldValue('district', district.label);
                }
            }
        } else {
            const districtId = districts.find((district: { label: string }) => district.label === AddTalukaFormik.values.district)?.value;
            if (districtId) {
                getDropdownList('district', districtId);
            }
        }
    }, [AddTalukaFormik.values.district, userMapping]);

    const handleClose = () => {
        AddTalukaFormik.resetForm();
        handleCloseDialog();
    };
    return (
        <>
            <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: AddTalukaFormik.handleSubmit }} >
                <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} md={10} >
                                <Typography variant="h4">{isEditing ? 'Edit Taluka' : 'Add Taluka'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={2} textAlign={"end"}>
                                <IconButton onClick={() => { handleClose() }}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size="small"
                                    value={states.find((state: { name: string; }) => state.name === AddTalukaFormik.values.state) || null}
                                    onChange={(event, newValue: StateOption | null) => {
                                        AddTalukaFormik.setFieldValue('state', newValue ? newValue.name : '');
                                        AddTalukaFormik.setFieldValue('company', ''); 
                                        AddTalukaFormik.setFieldValue('region', '');
                                        AddTalukaFormik.setFieldValue('district', '');
                                        setCompanies([]);
                                        setRegions([]);
                                        setDistricts([]);
                                        const stateId = states.find((state: { name: any; }) => state.name === newValue?.name)?.id;
                                        if (stateId) {
                                            getDropdownList('state', stateId);
                                        }
                                    }}
                                    inputValue={AddTalukaFormik.values.state}
                                    onInputChange={(event, newInputValue) => {
                                        AddTalukaFormik.setFieldValue('state', newInputValue);
                                    }}
                                    options={states}
                                    getOptionLabel={(option: StateOption) => option.name}
                                    filterOptions={filterOptions}
                                    key={states.map((state: { id: any; }) => state.id).join(',')}
                                    onBlur={() => {
                                        const validState = states.find((state: { name: any; }) => state.name === AddTalukaFormik.values.state);
                                        if (!validState) {
                                          AddTalukaFormik.setFieldValue(
                                            'state',
                                            currentTaluka?.state_name || ''
                                          );
                                        }
                                      }}
                                    disableClearable
                                    freeSolo={false} 
                                    disabled={initialState}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="States*"
                                        error={AddTalukaFormik.touched.state && Boolean(AddTalukaFormik.errors.state)}
                                        helperText={AddTalukaFormik.touched.state && typeof AddTalukaFormik.errors.state === 'string' ? AddTalukaFormik.errors.state : ''}
                                    />
                                    )}
                                />                                  
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size="small"
                                    value={AddTalukaFormik.values.company}
                                    onChange={(event: any, newValue: string | null) => {
                                        if (newValue && companies.some((company: { label: string; }) => company.label === newValue)) {
                                            AddTalukaFormik.setFieldValue('company', newValue);
                                            AddTalukaFormik.setFieldValue('region', '');
                                            AddTalukaFormik.setFieldValue('district', '');
                                            setRegions([]);
                                            setDistricts([])
                                            const companyId = companies.find((company: { label: string; }) => company.label === newValue)?.value;
                                            if (companyId) {
                                                getDropdownList('company', companyId);
                                            }
                                        } else {
                                            AddTalukaFormik.setFieldValue('company', '');
                                        }
                                    }}
                                    inputValue={AddTalukaFormik.values.company}
                                    onInputChange={(event, newInputValue) => {
                                        AddTalukaFormik.setFieldValue('company', newInputValue);
                                    }}
                                    onBlur={() => {
                                        const validCompany = companies.some((company: { label: any; }) => company.label === AddTalukaFormik.values.company );
                                        if (!validCompany && isEditing) {
                                          AddTalukaFormik.setFieldValue(
                                            'company',
                                            currentTaluka?.company_name || ''
                                          );
                                        }
                                      }}
                                    disabled={initialCompany}
                                    options={companies.map((company: { label: any; }) => company.label)}
                                    disableClearable
                                    freeSolo={false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Company*"
                                            error={AddTalukaFormik.touched.company && Boolean(AddTalukaFormik.errors.company)}
                                            helperText={AddTalukaFormik.touched.company && typeof AddTalukaFormik.errors.company === 'string' ? AddTalukaFormik.errors.company : ''}
                                        />
                                    )}
                                /> 
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={AddTalukaFormik.values.region}
                                    onChange={(event: any, newValue: string | null) => {
                                        AddTalukaFormik.setFieldValue('region', newValue);
                                        AddTalukaFormik.setFieldValue('district', '');
                                            setDistricts([])
                                        const regionId = regions.find((region: { label: string | null; }) => region.label === newValue)?.value;
                                        if (regionId) {
                                            getDropdownList('region', regionId);
                                        }
                                    }}
                                    inputValue={AddTalukaFormik.values.region}
                                    onInputChange={(event, newInputValue) => {
                                        AddTalukaFormik.setFieldValue('region', newInputValue);
                                    }}
                                    onBlur={() => {
                                        const validRegion = regions.some((region: { label: any; }) => region.label === AddTalukaFormik.values.region );
                                        if (!validRegion && isEditing) {
                                          AddTalukaFormik.setFieldValue(
                                            'region',
                                            currentTaluka?.region_name || ''
                                          );
                                        }
                                      }}
                                    disableClearable
                                    freeSolo={false}
                                    options={regions.map((region: { label: any; }) => region.label)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Region*"
                                            error={AddTalukaFormik.touched.region && Boolean(AddTalukaFormik.errors.region)}
                                            helperText={AddTalukaFormik.touched.region && typeof AddTalukaFormik.errors.region === 'string' ? AddTalukaFormik.errors.region : ''}
                                        />
                                    )}
                                />                               
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={AddTalukaFormik.values.district}
                                    onChange={(event: any, newValue: string | null) => {
                                        AddTalukaFormik.setFieldValue('district', newValue);
                                        const districtId = districts.find((district: { label: string | null; }) => district.label === newValue)?.value;
                                        if (districtId) {
                                            getDropdownList('district', districtId);
                                        }
                                    }}
                                    inputValue={AddTalukaFormik.values.district}
                                    onInputChange={(event, newInputValue) => {
                                        AddTalukaFormik.setFieldValue('district', newInputValue);
                                    }}
                                    disableClearable
                                    freeSolo={false}
                                    options={districts.map((district: { label: any; }) => district.label)}
                                    onBlur={() => {
                                        const validDistrict = districts.some((district: { label: any; }) => district.label === AddTalukaFormik.values.district );
                                        if (!validDistrict && isEditing) {
                                          AddTalukaFormik.setFieldValue(
                                            'district',
                                            currentTaluka?.district_name || ''
                                          );
                                        }
                                      }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="District*"
                                            error={AddTalukaFormik.touched.district && Boolean(AddTalukaFormik.errors.district)}
                                            helperText={AddTalukaFormik.touched.district && typeof AddTalukaFormik.errors.district === 'string' ? AddTalukaFormik.errors.district : ''}
                                        />
                                    )}
                                />                               
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={AddTalukaFormik.values.region}
                                    onChange={(event: any, newValue: string | null) => {
                                        AddTalukaFormik.setFieldValue('region', newValue);
                                        AddTalukaFormik.setFieldValue('district', '');
                                        setDistricts([])
                                        const regionId = isDistrictUser
                                            ? regionsOptions.find((region: { label: string | null; }) => region.label === newValue)?.value
                                            : regions.find((region: { label: string | null; }) => region.label === newValue)?.value;
                                        if (regionId) {
                                            getDropdownList('region', regionId);
                                        }
                                    }}
                                    inputValue={AddTalukaFormik.values.region}
                                    onInputChange={(event, newInputValue) => {
                                        AddTalukaFormik.setFieldValue('region', newInputValue);
                                    }}
                                    disableClearable
                                    freeSolo={false}
                                    onBlur={() => {
                                        if (!regions.some((region: { label: string; }) => region.label === AddTalukaFormik.values.region)) {
                                            AddTalukaFormik.setFieldValue('region', '');
                                        }
                                    }}
                                    options={(isDistrictUser ? regionsOptions : regions).map((region: { label: any; }) => region.label)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Region*"
                                            error={AddTalukaFormik.touched.region && Boolean(AddTalukaFormik.errors.region)}
                                            helperText={AddTalukaFormik.touched.region && typeof AddTalukaFormik.errors.region === 'string' ? AddTalukaFormik.errors.region : ''}
                                    />
                                )}
                            />
                                
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size="small"
                                    value={AddTalukaFormik.values.district}
                                    onChange={(event: any, newValue: string | null) => {
                                        AddTalukaFormik.setFieldValue('district', newValue);
                                            const districtId = isDistrictUser
                                                ? districtsOptions.find((district: { label: string | null; }) => district.label === newValue)?.value
                                                : districts.find((district: { label: string; }) => district.label === newValue)?.value;
                                            if (districtId) {
                                                getDropdownList('district', districtId);
                                            }   
                                    }}
                                    inputValue={AddTalukaFormik.values.district}
                                    onInputChange={(event, newInputValue) => {
                                        AddTalukaFormik.setFieldValue('district', newInputValue);
                                    }}
                                    onBlur={() => {
                                        if (!districts.some((district: { label: string; }) => district.label === AddTalukaFormik.values.district)) {
                                            AddTalukaFormik.setFieldValue('district', '');
                                        }
                                    }}
                                    options={(isDistrictUser ? districtsOptions : districts).map((district: { label: any; }) => district.label)}
                                    disableClearable
                                    freeSolo={false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="District*"
                                            error={AddTalukaFormik.touched.district && Boolean(AddTalukaFormik.errors.district)}
                                            helperText={AddTalukaFormik.touched.district && typeof AddTalukaFormik.errors.district === 'string' ? AddTalukaFormik.errors.district : ''}
                                        />
                                    )}
                                /> 
                               
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                    autoComplete="off"
                                    fullWidth
                                    label="Taluka*"
                                    variant="outlined"
                                    size="small"
                                    {...AddTalukaFormik.getFieldProps('name')}
                                    name="name"
                                    error={AddTalukaFormik.touched.name && Boolean(AddTalukaFormik.errors.name)}
                                    helperText={AddTalukaFormik.touched.name && AddTalukaFormik.errors.name}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddTalukaDialog;
