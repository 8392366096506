import { createSlice } from '@reduxjs/toolkit'
import { decryptText } from '../../utils/utils';

const initialState = {
	job: null,
	name: null
}

const drawerSlice = createSlice({
	name: 'drawer',
	initialState,
	reducers: {
		openDrawer: (state, action) => {
			if (action.payload.job) return { ...state, ...{ job: action.payload.job, name: action.payload.name } };
			return { ...state, ...action.payload }
		},
		closeDrawer: (state) => {
			return { ...state, ...{ name: null } };
		}
	},
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;

export default drawerSlice.reducer