import { Alert, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BulkUploadService } from '../../../../utils/services/project.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { FilterListService } from '../../../../utils/services/filter.service';
import { StateListService } from '../../../../utils/services/state.service';
interface BulkUploadDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
}
interface FormValues {
    region_id: string;
    file: File | null;
    state:string,
    company:string,
    region:string
}
const BulkUploadDialog: React.FC<BulkUploadDialogProps> = ({ dialogOpen, handleCloseDialog, handleSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any>([]);
    const [states, setStates] = useState<any>([]);
    const [regions, setRegions] = useState<any>([]);
    const BulkUploadFormik = useFormik<FormValues>({
        initialValues: {
            state:'',
            company:'',
            region:'',
            region_id: '',
            file: null,
        },
        validationSchema: Yup.object({
            file: Yup.mixed().required("Please upload a file"),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            const regionId = regions.find((region: { label: string; }) => region.label === values.region)?.value;
            const formData = new FormData();
            formData.append('region_id',regionId);
            if (values.file) {
                formData.append('file', values.file);
            }

            const { data, code } = await BulkUploadService(formData);
            if (code === 200) {
                dispatch(showSnackbar({ message: 'Documents uploaded successfully', type: 'success', open: true }));
                handleSuccess();
                handleClose()
            } else {
                dispatch(showSnackbar({ message: data || 'Unknown error occurred. Please try again.', type: 'error', open: true }));
            }
            
        },
    });
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            BulkUploadFormik.setFieldValue('file', event.currentTarget.files[0]);
        }
    };
    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };
    const getDropdownList = async (type: string, id?: number) => {
        setLoading(true);
        const payload = {
            type: type,
            id: id,
        };
        const { data, code } = await FilterListService(payload);
        if (data && code === 200) {
            if (type === 'state') {
                setCompanies(data.data);
            } else if (type === 'company') {
                setRegions(data.data);
            } 
        }
    };
    const handleClose = () => {
        BulkUploadFormik.resetForm();
        handleCloseDialog();
    };
    return (
        <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: BulkUploadFormik.handleSubmit }} >
            <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="h4">Bulk Upload</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={BulkUploadFormik.values.state}
                                    onChange={(event: any, newValue: string | null) => {
                                        BulkUploadFormik.setFieldValue('state', newValue);
                                        const stateId = states.find((state: { name: string; }) => state.name === newValue)?.id;
                                        if (stateId) {
                                            getDropdownList('state', stateId); 
                                        }
                                    }}
                                    inputValue={BulkUploadFormik.values.state}
                                    onInputChange={(event, newInputValue) => {
                                        if (states.some((state: { name: string; }) => state.name === newInputValue)) {
                                            BulkUploadFormik.setFieldValue('state', newInputValue);
                                        }
                                    }}
                                    options={states.map((state: any) => state.name)}
                                    disableClearable
                                    freeSolo={false}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            label="States*"
                                            error={BulkUploadFormik.touched.state && Boolean(BulkUploadFormik.errors.state)}
                                            helperText={BulkUploadFormik.touched.state && BulkUploadFormik.errors.state}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={BulkUploadFormik.values.company}
                                    onChange={(event: any, newValue: string | null) => {
                                        BulkUploadFormik.setFieldValue('company', newValue);
                                        const companyId = companies.find((company: { label: string | null; }) => company.label === newValue)?.value;
                                        if (companyId) {
                                            getDropdownList('company', companyId);
                                        }
                                    }}
                                    inputValue={BulkUploadFormik.values.company}
                                    onInputChange={(event, newInputValue) => {
                                        if (companies.some((company: { label: string | null; }) => company.label === newInputValue)) {
                                            BulkUploadFormik.setFieldValue('company', newInputValue);
                                        } 
                                    }}
                                    options={companies.map((company: any) => company.label)}
                                    disableClearable
                                    freeSolo={false}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            label="Company*"
                                            error={BulkUploadFormik.touched.company && Boolean(BulkUploadFormik.errors.company)}
                                            helperText={BulkUploadFormik.touched.company && BulkUploadFormik.errors.company}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={BulkUploadFormik.values.region}
                                    onChange={(event: any, newValue: string | null) => {
                                        BulkUploadFormik.setFieldValue('region', newValue);
                                        const regionId = regions.find((region: { label: string | null; }) => region.label === newValue)?.value;
                                        if (regionId) {
                                            getDropdownList('region', regionId); 
                                        }
                                    }}
                                    inputValue={BulkUploadFormik.values.region}
                                    onInputChange={(event, newInputValue) => {
                                        if (regions.some((region: { label: string | null; }) => region.label === newInputValue)) {
                                        BulkUploadFormik.setFieldValue('region', newInputValue);
                                        }
                                    }}
                                    options={regions.map((region: any) => region.label)}
                                    disableClearable
                                    freeSolo={false}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            label="Region*"
                                            error={BulkUploadFormik.touched.region && Boolean(BulkUploadFormik.errors.region)}
                                            helperText={BulkUploadFormik.touched.region && BulkUploadFormik.errors.region}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth required>
                            <Box display="block" alignItems="center">
                                <Box position="relative">
                                    <input
                                        type="file"
                                        id="file-upload"
                                        name="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="file-upload">
                                        <Button variant="outlined" color="inherit" component="span" fullWidth>
                                            <InsertDriveFileIcon sx={{ mr: 1 }} /> Upload Documents
                                        </Button>
                                    </label>
                                </Box>
                                {BulkUploadFormik.values.file && (
                                    <Typography variant="body2" fontSize={'11px'} mt={1}>
                                        {BulkUploadFormik.values.file.name}
                                    </Typography>
                                )}
                            </Box>
                        </FormControl>
                    </Grid>
                </Grid>
                <Alert sx={{p:'1px 10px', mt:2}} severity="warning">”<strong>Note:</strong> During bulk upload, any records already in the system will be skipped. Only new records will be added.”</Alert>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                <Button variant='contained' type="submit" color='info'>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BulkUploadDialog;
