import ClientsAxios from '../client-axios';
import { ResponseEntity } from '../dto/response';

const { API_SERVER } = ClientsAxios;


export const ProjectCreateService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('project/store', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}

export const ProjectUpdateService = async (id: string, payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('project/update', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}


export const ProjectListService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('project/list', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const ProjectDeleteService = async (id: number) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('project/delete',  { id })
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}
export const BulkUploadService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('project/bulk-upload', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}
export const ProjectExportService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('project/export', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}