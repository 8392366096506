import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton,  CircularProgress,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { StateCreateService, StateUpdateService } from '../../../../utils/services/state.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
interface AddStateDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
    currentState:{ id: string; name: string } | null;
}

const AddStateDialog: React.FC<AddStateDialogProps> = ({ dialogOpen, handleCloseDialog, currentState, handleSuccess}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const isEditing = currentState !== null;
    const AddStateFormik = useFormik({
        initialValues: {
            id:currentState?.id || '',
            name:currentState?.name || '',
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            name: Yup.string().trim().required("Please enter state"),
        }),
        onSubmit: async (values, { resetForm }) => {
            let serviceResponse;
            if (isEditing) {
                const { data, code,  } = await StateCreateService({
                    id: currentState.id,
                    name: values.name
                });
                serviceResponse = { data, code };
            } else {
                const { data, code } = await StateCreateService({
                    name: values.name
                });
                serviceResponse = { data, code };
            }
            setLoading(false);
            if (serviceResponse.code === 200 && serviceResponse.data) {
                resetForm();
                handleCloseDialog();
                handleSuccess();
                const message = isEditing ? 'State details updated' : 'State created successfully';
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } else {
                const errorMessage = serviceResponse.data || 'An error occurred';
                dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
            }   

        },
    });
    const handleClose = () => {
        AddStateFormik.resetForm();
        handleCloseDialog();
    };

    return (
        <>
            <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: AddStateFormik.handleSubmit }} >
               <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position:'sticky', top:'0', left:'0', right:'0', zIndex:'99', backgroundColor:'#fff', borderBottom:'1px solid #ccc', p:'10px 25px'  }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10} >
                            <Typography variant="h4">{isEditing ? 'Edit State' : 'Add State'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={() => { handleClose() }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                    autoComplete="off"
                                    fullWidth
                                    label="State*"
                                    variant="outlined"
                                    size="small"
                                    {...AddStateFormik.getFieldProps('name')}
                                    name="name"
                                    error={AddStateFormik.touched.name && Boolean(AddStateFormik.errors.name)}
                                    helperText={AddStateFormik.touched.name && AddStateFormik.errors.name}
                                />
                            </FormControl>
                        </Grid>
                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddStateDialog;


