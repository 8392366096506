import { Add } from "@mui/icons-material"
import { Badge, styled, Box, Button, Paper, TableContainer, Table, TableRow, TableCell, Checkbox, TableBody, TablePagination, Avatar, Tooltip, Menu, MenuItem, Chip, IconButton, ListItemIcon, useMediaQuery, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Grid, TextField, Popover } from '@mui/material'
import PageHead from "../../../../components/admin/PageHead"
import TableHeadList from "../../../../components/table/TableHeadList";
import { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import AddRoleDialog from "./addRoleDialog";
import DeleteRoleDialog from "./deleteRoleDialog";
import { RoleListService } from "../../../../utils/services/role.service";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ViewRoleDialog from "./viewRoleDialog";
import { StyledTableCell } from "../../../../components/StyledComponents";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { GetPermissions } from "../../../../utils/utils";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from "react";
import BlockIcon from '@mui/icons-material/Block';
const headCells: any = [
    {
        id: 'srNo',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 20,
        sortable: false
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Role',
        width: 800,
        sortable: true
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
        width: 50,
        sortable: false
    },
];

const Role = () => {
    const [popoverRowId, setPopoverRowId] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentRole, setCurrentRole] = useState<any>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [roleToDelete, setRoleToDelete] = useState<any>(null);
    const [roles, setRole]= useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [roleToView, setRoleToView] = useState<any>(null);
    const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>(''); 
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    useEffect(() => {       
        fetchRoles();
    }, [page, rowsPerPage, searchTerm, sortOrder, sortBy]);
    const fetchRoles = async () => {
        setLoading(true);
        const payload = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: sortBy,
            order_by: sortOrder,
            search: searchTerm
        };
        const { data, code } = await RoleListService(payload);
        if (code === 200 && data) {
            setRole(data.data);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };
    const handleOpenDialog = () => {
        setCurrentRole(null);
        setDialogOpen(true);
    };

    const handleOpenEditDialog = (name: any) => {
        setCurrentRole(name);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleOpenDeleteDialog = (role: any) => {
        setRoleToDelete(role);
        setDeleteDialogOpen(true);
        
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const handleSuccess = () => {
        fetchRoles(); 
    };
    const handleOpenViewDialog = (role: any) => {
        setRoleToView(role);
        setViewDialogOpen(true);
    };
    const handleCloseViewDialog = () => {
        setViewDialogOpen(false);
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const permissions = GetPermissions('role_master');
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };
    const handleReset = () => {
        setSearchTerm('');
    }; 
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, rowId: any) => {
        setAnchorEl(event.currentTarget);
        setPopoverRowId(rowId);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverRowId(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <Box>
                <Box sx={{ px: 2, py: 1.5 }}>
                    <PageHead primary='Role Master' />
                </Box>
                <Paper>
                    <Box sx={{ borderTopWidth: 1, borderRightWidth: 0, borderBottomWidth: 1, borderLeftWidth: 0, borderStyle: 'solid', borderColor: 'grey.400', px: 2, py: 1 }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} lg={9} >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                {permissions.includes('Add') && (
                                    <Button variant='contained' startIcon={<Add />} onClick={handleOpenDialog}>Add Role</Button>
                                )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={3} display={'flex'} textAlign={'right'} alignItems={'center'}>
                                <TextField fullWidth
                                    label="Search"
                                    placeholder="Search here ..."
                                    variant="outlined"
                                    size="small"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <Button onClick={handleReset} variant="contained" color="info" sx={{ ml: 2}}>
                                    Reset
                                </Button>
                            </Grid>                            
                        </Grid>  
                    </Box>
                    <TableContainer sx={{ maxHeight: 600 }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium' stickyHeader aria-label="sticky table">
                        <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                        {permissions.includes('List') ? (
                            <TableBody>
                                    {roles.length > 0 ? (
                                        roles.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {index + 1 + page * rowsPerPage}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>     
                                                    <Button aria-describedby={id} onClick={(e) => handleClickPopover(e, row.id)}>
                                                        <MoreVertIcon />
                                                    </Button>
                                                    {popoverRowId === row.id && (
                                                        <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                                                            <Box sx={{display:'flex', flexDirection:'column', gap:2}}>
                                                                {permissions.includes('Edit') && (     
                                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Edit</Typography>                                       
                                                                        <Button size="small" variant="contained"  title="Edit" onClick={() => handleOpenEditDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                            <ModeEditOutlinedIcon sx={{fontSize:'16px'}} />
                                                                        </Button>
                                                                    </Box>    
                                                                )}
                                                                {permissions.includes('Delete') && (      
                                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Delete</Typography>
                                                                        <Button size="small" color="error" variant="contained"  title="Delete" onClick={() => handleOpenDeleteDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                            <DeleteOutlineOutlinedIcon sx={{fontSize:'16px'}} />
                                                                        </Button>
                                                                    </Box>    
                                                                )}
                                                                <Box display={'flex'} justifyContent={'space-between'} >  
                                                                    <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>View</Typography>
                                                                    <Button size="small" color="info" variant="contained"  title="view" onClick={() => handleOpenViewDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                        <VisibilityOutlinedIcon sx={{fontSize:'16px'}} />
                                                                    </Button>
                                                                </Box>    
                                                            </Box>
                                                        </Popover>         
                                                    )}                                           
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                        ) : (
                                            <TableRow>
                                                <StyledTableCell colSpan={3} align="center">
                                                    {loading ? (
                                                        <CircularProgress size={24} />
                                                    ) : (
                                                        <Typography variant="body2" color="textSecondary">
                                                            No roles found.
                                                        </Typography>
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                            </TableBody>
                        ) : (
                            <TableRow>
                                <StyledTableCell colSpan={6} align="center" sx={{p:1}}>
                                    <BlockIcon sx={{fontSize:'40px', color:"#0288d1"}}/>
                                    <Typography variant="h5" color="#0288d1" fontWeight={300}>
                                       Sorry, you cannot view the page.
                                    </Typography>
                                    <Typography variant="body1" color="error" fontWeight={300} pt={1}>
                                       No permission to access this page
                                    </Typography>
                                </StyledTableCell>
                            </TableRow>
                        )}
                        </Table>
                    </TableContainer>    
                    <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />                                 
                </Paper>
             </Box>
             <AddRoleDialog dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} currentRole={currentRole} handleSuccess={handleSuccess}/>
             <DeleteRoleDialog dialogOpen={deleteDialogOpen} roleToDelete={roleToDelete} handleClose={handleCloseDeleteDialog} onDeleteSuccess={handleSuccess} />
             <ViewRoleDialog dialogOpen={viewDialogOpen} roleToView={roleToView} handleClose={handleCloseViewDialog} onViewSuccess={handleSuccess} />
             
            
        </>
    )
}
export default Role