import ClientsAxios from '../client-axios';
import { ResponseEntity } from '../dto/response';

const { API_SERVER } = ClientsAxios;


export const TypeCreateService = async ( payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('type/store', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}

export const TypeUpdateService = async (id: number, payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('type/store', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}

export const TypeListService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('type/list', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const TypeDeleteService = async (id: number) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('type/delete',  { id })
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}