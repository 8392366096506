import { Box, Typography } from '@mui/material';

const PageHead = ({ primary, secondary }: { primary: React.ReactNode | string, secondary?: React.ReactNode | string, custom?: React.ReactNode | string }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: { xs: 'flex-start', md: 'center' }, flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 1.2, md: 0 } }}>
            <Box>
                {typeof (primary) === 'string' && <Typography variant='h1' fontSize='1.5rem' fontWeight='bold'>{primary}</Typography>}
                {typeof (primary) != 'string' && primary}
            </Box>
            <Box>
                {typeof (secondary) === 'string' && <Typography variant='h5'>{secondary}</Typography>}
                {typeof (secondary) != 'string' && secondary}
            </Box>
        </Box>
    )
}

export default PageHead;