
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppRoute from './pages/AppRoute';
import { SettingsProvider } from './providers/SettingsProvider';
import SnackbarMessage from './components/SnackbarMessage';

const App = () => {
    return (
        <SettingsProvider>
            <Router>
                <Routes>
                    <Route path='*' element={<AppRoute />} />
                </Routes>
            </Router>
            <SnackbarMessage />
        </SettingsProvider>
    )
}

export default App;