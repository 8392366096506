import React, { useEffect, useState } from "react"
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography, TableContainer, Table, TableRow, TableCell, TableBody, TablePagination, Paper, CircularProgress, Grid, Box, IconButton, FormControl, Autocomplete, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StatusListService } from "../../../utils/services/status.service";
import { useFormik } from 'formik';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
import * as Yup from 'yup';
import { StatusChangeService } from "../../../utils/services/manage.service";
interface ChangeStatusProps {
    dialogOpen: boolean;
    manageToStatus: any;
    handleClose: () => void;
    handleSuccess: () => void;
}
const ChangeStatusDialog : React.FC<ChangeStatusProps> = ({ dialogOpen, manageToStatus, handleClose, handleSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus]= useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (dialogOpen && manageToStatus && manageToStatus.id) {
            // fetchDetails();
        }
    }, [dialogOpen, manageToStatus]);
    const ChangeStatusFormik = useFormik({
        initialValues: {
            letter_id:'',
            status_id:'',
            status:'',
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            status: Yup.string().required('Please select a status'),  
        }),
        onSubmit: async (values, {resetForm}) => {
            const statusId = status.find((status: { name: string; }) => status.name === values.status)?.id;
            const { data, code } = await StatusChangeService({
                letter_id: manageToStatus.id,
                status_id: statusId,
            });
            if (data && code === 200) {
                resetForm();
                handleClose();
                handleSuccess();
                setLoading(false);
                const message = 'Status has been changed' 
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } else {
                const errorMessage = data || 'An error occurred';
                dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
            }         
        },
    });    
    useEffect(() => {       
        fetchStatus();
    }, []);
    const fetchStatus = async () => {
        const payload = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        setLoading(true);
        const { data, code } = await StatusListService(payload);
        if (code === 200 && data) {
            setStatus(data.data);
        }
        setLoading(false);
    };
    return(
        
        <> 
            <Dialog fullWidth  open={dialogOpen} onClose={handleClose} PaperProps={{ component: 'form', onSubmit: ChangeStatusFormik.handleSubmit }}>
                <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky',top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px', mb:2}}>
               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="h4">Change Status</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>            
            <DialogContent>                
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth required>
                            <Autocomplete
                            size='small'
                            value={ChangeStatusFormik.values.status}
                            onChange={(event: any, newValue: string | null) => {
                                ChangeStatusFormik.setFieldValue('status', newValue);
                            }}
                            inputValue={ChangeStatusFormik.values.status}
                            onInputChange={(event, newInputValue) => {
                                if (status.some((status: { name: string; }) => status.name === newInputValue)) {
                                    ChangeStatusFormik.setFieldValue('status', newInputValue);
                                }
                            }}
                            disableClearable
                            freeSolo={false}
                            options={status.map((option) => option.name)}
                            renderInput={(params) => (
                                <TextField  autoComplete="off"
                                {...params}
                                label="Status*"
                                error={ChangeStatusFormik.touched.status && Boolean(ChangeStatusFormik.errors.status)}
                                helperText={ChangeStatusFormik.touched.status && ChangeStatusFormik.errors.status ? (ChangeStatusFormik.errors.status as string) : ''}
                                />
                            )}
                            />
                        </FormControl>
                    </Grid>
                </Grid> 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button sx={{float:'right', m:1}} variant='contained' type="submit" disabled={loading} color='info'>Submit</Button> 
            </DialogActions>
        </Dialog>
        </>
    )
}
export default ChangeStatusDialog