import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
    open: boolean;
    type: any;
    message: string;
};

const initialState: initialStateType = {
    open: false,
    type: '',
    message: '',
};

const snackbarSlice: any = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        showSnackbar: (state, action) => {
            return { ...state, ...action.payload };
        },
        hideSnackbar: (state) => {
            return { ...initialState };
        },
    },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
