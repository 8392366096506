import { Add } from "@mui/icons-material"
import { Badge, styled, Box, Button, Paper, TableContainer, Table, TableRow, TableCell, Checkbox, TableBody, TablePagination, Avatar, Tooltip, Menu, MenuItem, Chip, IconButton, ListItemIcon, useMediaQuery, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Stack, Grid, TableHead, FormControlLabel, TextField, Accordion, AccordionSlots, Fade, AccordionSummary, AccordionDetails, Autocomplete, FormControl, Popover, Alert, InputLabel, Select, AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material'
import PageHead from "../../../components/admin/PageHead"
import TableHeadList from "../../../components/table/TableHeadList";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { ManageListService, LetterDetailsService } from "../../../utils/services/manage.service";
import { useNavigate } from "react-router-dom";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import dayjs, { Dayjs } from "dayjs";
import BlockIcon from '@mui/icons-material/Block';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BlockUnblockManageDialog from "./blockUnblockManageDialog";
import ActivityLogDialog from "./activityLogDialog";
import LetterDetails from "./detailsDialog";
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import { StyledTableCell } from "../../../components/StyledComponents";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
import { FilterListService } from "../../../utils/services/filter.service";
import { StateListService } from "../../../utils/services/state.service";
import { CategoryListService } from "../../../utils/services/category.service";
import { StatusListService } from "../../../utils/services/status.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TypeListService } from "../../../utils/services/type.service";
import ParentDetailDialog from "./parentDetails";
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { GetPermissions } from "../../../utils/utils";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChangeStatusDialog from "./changeStatusDialog";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CancelRevertBackDialog from "./cancelRevertBackDialog";
import ChatIcon from '@mui/icons-material/Chat';
import NotificationDialog from "./notificationDialog";
import Export from "./export";
import BulkUplaodDialog from "./bulkUploadDialog";
import RefrencesDetailDialog from "./refrencesDialog";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
const centeredLabelStyle = {'& .MuiInputLabel-root': { display: 'flex', alignItem: 'center', width: '100%', position:'absolute', top:'-7px' }, };
const headCells: any = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Sr. No.', width: 50, sortable: false },
    { id: 'state_name', numeric: false, disablePadding: false, label: 'State Name', width: 300, sortable: false },
    { id: 'company_name', numeric: false, disablePadding: false, label: 'Company Name', width: 300, sortable: false },
    { id: 'region_name', numeric: false, disablePadding: false, label: 'Region Name', width: 300, sortable: false },
    { id: 'district_name', numeric: false, disablePadding: false, label: 'District Name', width: 300, sortable: false },
    { id: 'taluka_name', numeric: false, disablePadding: false, label: 'Taluka Name', width: 300, sortable: false },
    { id: 'village_name', numeric: false, disablePadding: false, label: 'Project Name', width: 300, sortable: false },
    { id: 'reference_number', numeric: false, disablePadding: false, label: 'Reference Number', width: 250, sortable: false },
    { id: 'category_name', numeric: false, disablePadding: false, label: 'Category Name', width: 300, sortable: false },
    { id: 'status_name', numeric: false, disablePadding: false, label: 'Status Name', width: 300, sortable: false },
    { id: 'stage', numeric: false, disablePadding: false, label: 'Stage', width: 300, sortable: false },
    {id: 'address', numeric: false, disablePadding: false, label: 'Address', width: 300, sortable: false },
    {id: 'subject', numeric: false, disablePadding: false, label: 'Subject', width: 300, sortable: false },
    {id: 'cc', numeric: false, disablePadding: false, label: 'CC', width: 300, sortable: false },
    { id: 'remarks', numeric: false, disablePadding: false, label: 'Remarks', width: 300, sortable: false },
    { id: 'creatd_by_name', numeric: false, disablePadding: false, label: 'Created By Name', width: 350, sortable: false },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Date', width: 200, sortable: true },
    { id: 'modify_date', numeric: false, disablePadding: false, label: 'Updated Date', width: 300, sortable: false, format:"DD/MM/YYY"},
    { id: 'is_cancelled', numeric: false, disablePadding: false, label: 'Is Cancelled', width: 50, sortable: false, hidden: true },
    { id: 'is_blocked', numeric: false, disablePadding: false, label: 'Is Blocked', width: 50, sortable: false,  hidden: true },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action', width: 100, sortable: false },   
];
const periodOptions = [
    { value: 'all', label: 'All' },
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'last_7_days', label: 'Last 7 Days' },
    { value: 'last_15_days', label: 'Last 15 Days' },
  ];
const ManageMaster = () => {
    const [period, setPeriod] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);
    const [popoverRowId, setPopoverRowId] = useState<any>(null);
    const [visibleColumns, setVisibleColumns] = useState<string[]>(['id', 'company_name', 'district_name', 'taluka_name', 'village_name', 'reference_number', 'stage', 'created_at', 'action']);
    const [error, setError] = useState('');
    const [blockUnblockDialogOpen, setblockUnblockDialogOpen] = useState<boolean>(false);
    const [cancelBackDialogOpen, setCancelBackDialogOpen] = useState<boolean>(false);
    const [manageToCancelBack, setManageToCancelBack] = useState<any>(null);
    const [cancelBackStatus, setCancelBackStatus] = useState<{ [key: string]: boolean }>({});
    const [manageToBlock, setManageToBlock] = useState<any>(null);
    const [manage, setManage]= useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [manageToActivity, setManageToActivity] = useState<any>(null);
    const [manageToNotification, setManageToNotification] = useState<any>(null);
    const [manageToParent, setManageToParent] = useState<any>(null);
    const [manageToRefrences, setManageToRefrences] = useState<any>(null);
    const [refrencesDialogOpen, setRefrencesDialogOpen] = useState<boolean>(false);
    const [notificationDialogOpen, setNotificationDialogOpen] = useState<boolean>(false);
    const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState<boolean>(false);
    const [activityDialogOpen, setActivityDialogOpen] = useState<boolean>(false);
    const [parentDialogOpen, setParentDialogOpen] = useState<boolean>(false);
    const [blockedStatus, setBlockedStatus] = useState<{ [key: string]: boolean }>({});
    const [manageToDetails, setManageToDetails] = useState<any>(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState<boolean>(false);
    const [states, setStates] = useState<any[]>([]);   
    const [companies, setCompanies] = useState<any[]>([]);
    const [regions, setRegions] = useState<any[]>([]);
    const [districts, setDistricts] = useState<any[]>([]);
    const [talukas, setTalukas] = useState<any[]>([]);
    const [villages, setVillages] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [expanded, setExpanded] = React.useState(false);
    const [category, setCategory]= useState<any[]>([]);
    const [status, setStatus]= useState<any[]>([]);
    const [types, setType] = useState<any[]>([]);  
    const [manageToStatus, setManageToStatus] = useState<any>(null);
    const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState('date');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [selectedTypeId, setSelectedTypeId] = useState<number | undefined>(undefined); 
    const userMapping = useSelector((state: RootState) => state.authUser.user_mapping);
    const userDetails = useSelector((state: RootState) => state.authUser.user);    
    const userRoles = useSelector((state: RootState) => state.authUser.roles);
    const isSuperAdmin = userRoles?.some((role: { slug: string }) => role.slug === 'super-admin');
    const initialCompany = companies.find((company: { value: number }) => company.value === userDetails?.company_id)?.label || '';   
    const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';  
    const regionsOptions = useMemo(() =>
        (userMapping || []).map((mapping: { region: any; region_id: any; }) => ({
            label: mapping.region,
            value: mapping.region_id,
        })),
        [userMapping]
    );    
    const districtsOptions = useMemo(() =>  (userMapping || []).map((mapping: { district: any; district_id: any; }) => ({
            label: mapping.district,
            value: mapping.district_id,
        })), 
        [userMapping]
    );
    const [typeId, setTypeId] = useState<number | undefined>(undefined);
    const [counts, setCounts] = useState<{
        draft_count: number;  final_letter_count: number;  acknowledgment_count: number; total_count:number, reply_letter_count: number; inward_letter_count: number; reminder_letter: number;  draft_id?: number; final_letter_id?: number; acknowledgment_id?: number; reply_letter_id?: number;  inward_letter_id?: number; reminder_id?: number;
      }>
    ({ draft_count: 0, final_letter_count: 0, acknowledgment_count: 0, total_count:0, reply_letter_count: 0, inward_letter_count: 0, reminder_letter: 0,});     
    const [selectedIds, setSelectedIds] = useState<{
        state_id?: number; company_id?: number; region_id?: number;  district_id?: number;  taluka_id?: number; village_id?: number;  category_id?: number;  status_id?: number; current_type_id?: number;  start_date?: string; end_date?: string; }>
        ({ state_id: undefined, company_id: undefined, region_id: undefined, district_id: undefined,  taluka_id: undefined, village_id: undefined,  category_id: undefined, status_id: undefined, current_type_id: undefined,
        start_date: undefined, end_date: undefined,
    });
    const [formValues, setFormValues] = useState({
        state: '',  company: '', region: regionsOptions,  district: districtsOptions,  taluka: '', village: '', category: '', status: '', current_type: '',  
        start_date: '', end_date: '', period:'', periodOptions:''
    });
    const navigate = useNavigate();
    useEffect(() => {
        fetchManage();
    }, [page, rowsPerPage, searchTerm, selectedIds, sortBy, sortOrder, typeId, period]); //totalRows     
    const fetchManage = async () => {
        setLoading(true);
        const payload = { 
            limit: rowsPerPage, 
            offset:page*rowsPerPage, 
            sort_by: sortBy, 
            order_by: sortOrder, 
            search: searchTerm, 
            start_date: selectedIds.start_date, 
            end_date: selectedIds.end_date, 
            type_id: typeId,
            period: period, 
            ...selectedIds
        };
        const { data, code } = await ManageListService(payload);
        if (code === 200 && data) {
            setManage(data.data);
            setCounts(data.counts);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };
    useEffect(() => {        
        fetchType();
    },  [page, rowsPerPage, searchTerm]);
    const fetchType = async () => {
        const payload = { limit: rowsPerPage, offset: page * rowsPerPage, sort_by: 'name', order_by: 'asc', search: searchTerm };
        setLoading(true);
        const { data, code } = await TypeListService(payload);
        if (code === 200 && data) {
            setType(data.data);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };
    useEffect(() => {       
        fetchStatus();
    }, [page, rowsPerPage, searchTerm]);
    const fetchStatus = async () => {
        const payload = {
            limit: rowsPerPage, 
            offset: page * rowsPerPage, 
            sort_by: 'name', 
            order_by: 'asc', 
        };
        setLoading(true);
        const { data, code } = await StatusListService(payload);
        if (code === 200 && data) {
            setStatus(data.data);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };   
    // Block Unblock
    const handleOpenBlockUnblockDialog = (manage: any, type: string) => {
        if (!manage || !manage.id) {
            return;
        }
        setManageToBlock({ ...manage, type });
        setblockUnblockDialogOpen(true);
        setPopoverAnchorEl(null);
    };
    const handleBlockUnblock = () => {
        if (manageToBlock) {
            setBlockedStatus((prevState) => ({
                ...prevState,
                [manageToBlock.id]: manageToBlock.type === 'block'
            }));
        }
    };
    const handleCloseBlockUnblockDialog = () => {
        setblockUnblockDialogOpen(false);
    };
    // cancel
    const handleOpenCancelBackDialog = (manage: any, type: string) => {
        if (!manage || !manage.id) {
            return;
        }
        setManageToCancelBack({ ...manage, type });
        setCancelBackDialogOpen(true);
        handleClosePopover();
    };
    const handleCancelBack = () => {
        if (manageToCancelBack) {
            setCancelBackStatus((prevState) => ({
                ...prevState,
                [manageToCancelBack.id]: manageToCancelBack.type === 'cancel'
            }));
        }
    };
    const handleCloseCancelBackDialog = () => {
        setCancelBackDialogOpen(false);
    };
    // Activity Logs
    const handleOpenActivityDialog = (manage: any) => {
        setManageToActivity(manage);
        setActivityDialogOpen(true);
        setPopoverAnchorEl(null);
    };
    const handleCloseActivityDialog = () => {
        setActivityDialogOpen(false);
    };

    // Notification
    const handleNotificationDialog = (manage: any) => {
        setManageToNotification(manage);
        setNotificationDialogOpen(true);
        setPopoverAnchorEl(null);
    };    
    const handleCloseNotificationDialog = () => {
        setNotificationDialogOpen(false);
    };

    const handleBulkUploadDialog = () => {
        setBulkUploadDialogOpen(true);
    };    
    const handleCloseBulkUploadDialog = () => {
        setBulkUploadDialogOpen(false);
    };

    const handleOpenParentDialog = (manage: any) => {
        setManageToParent(manage);
        setParentDialogOpen(true);      
        setPopoverAnchorEl(null);  
    };      
    const handleCloseParentDialog = () => {
        setParentDialogOpen(false);
    };
    const handleOpenRefrencesDialog = (manage: any) => {
        setManageToRefrences(manage);
        setRefrencesDialogOpen(true);        
        setPopoverAnchorEl(null);
    };      
    const handleCloseRefrencesDialog = () => {
        setRefrencesDialogOpen(false);
    };
    const handleChangeStatusDialog = (manage: any) => {
        setManageToStatus(manage);
        setStatusDialogOpen(true);    
        setPopoverAnchorEl(null);    
    };    
    const handleCloseStatusDialog = () => {
        setStatusDialogOpen(false);
    };
       // Detail Dialog   
    const handleCloseDetailsDialog = () => {
        setDetailsDialogOpen(false);
    };    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };   
    const handleChangeRowsPerPage = (event:any) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
      };
      
    const handleSuccess = () => {
        fetchManage(); 
    };    
    
    // const handleClickView = (row: any) => {
    //     navigate('/view-letter', { state: { prefillData: row, action: 'view' } });
    // };
    const handleClickView = (row: any) => {
        window.open(`/view-letter?district_id=${Number(row.district_id)}&letter_id=${Number(row.id)}`, '_blank');
    };
    const handleClickEdit = async (row: any) => {
        const { data, code } = await LetterDetailsService({ district_id: row.district_id });
        if (data && code === 200) {
          navigate('/upload-letter', { state: { prefillData: row, action: 'edit', responseData: data } });
        } else {
          console.error('Failed to fetch data:', data);
        }  
    };  

    const handleAddRefrence = (row: any) => {
        navigate('/upload-letter', { state: { action: 'refrence', parent_id: row.id } });
    };    
    const handleAddLetter = (row: any) => {
        navigate('/upload-letter', { state: { action: 'create' } });
    };    
    const handleToggleColumn = (id: string) => {
        setVisibleColumns(prevVisibleColumns => {
            if (id === 'id' || id === 'action' || id === 'company_name') {
                return prevVisibleColumns;
            }
            if (prevVisibleColumns.includes(id)) {
                return prevVisibleColumns.filter(columnId => columnId !== id);
            } 
            else {
                return [...prevVisibleColumns, id];
            }
        });
    };    
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };   
   
    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {limit: 100, offset: 0, sort_by: 'name', order_by: 'asc', search: '' };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };
    useEffect(() => {        
        fetchCategory();
    },[page, rowsPerPage, searchTerm]);
    const fetchCategory = async () => {
        const payload = {limit: rowsPerPage, offset: page * rowsPerPage, sort_by: 'name', order_by: 'asc',
        };
        setLoading(true);
        const { data, code } = await CategoryListService(payload);
        if (code === 200 && data) {
            setCategory(data.data);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };
    const getDropdownList = async (type: string, id?: number, formikInstance?: any) => {
        setLoading(true);
        const payload = { type: type, id: id, };
        if (formikInstance) {
            formikInstance.setFieldValue(type + '_id', id);
        }
        const { data, code } = await FilterListService(payload);
        if (data && code === 200) {
            if (type === 'state') {
                setCompanies(data.data);
            } else if (type === 'company') {
                setRegions(data.data);
            } else if (type === 'region') {
                setDistricts(data.data);
            } else if (type === 'district') {
                setTalukas(data.data);
            }else if (type === 'taluka') {
                setVillages(data.data);
            }else if (type === 'village') {}
        }
        setLoading(false);
    };    
    const handleInputChange = (field: string, value: string) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };
    const handleChange = (type: string, newValue: any, field:string) => {
        const idMap: { [key: string]: number | undefined } = {
          state: states.find((state: any) => state.name === newValue)?.id,
          company: companies.find((company: any) => company.label === newValue)?.value,
          region: regions.find((region: any) => region.label === newValue)?.value,
          district: districts.find((district: any) => district.label === newValue)?.value,
          taluka: talukas.find((taluka: any) => taluka.label === newValue)?.value,
          village: villages.find((village: any) => village.label === newValue)?.value,          
        };    
        if (type === 'start_date' || type === 'end_date') {
          const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
          setFormValues(prev => ({ ...prev, [type]: formattedDate }));
          setSelectedIds(prev => ({ ...prev, [type]: formattedDate }));
        } else {
          setFormValues(prev => ({ ...prev, [type]: newValue || '' }));
          setSelectedIds(prev => ({ ...prev, [`${type}_id`]: idMap[type] }));
        }
    
        if (type !== 'date' && idMap[type] !== undefined) {
          getDropdownList(type, idMap[type]);
        }

        if (type === 'state') {
            setFormValues((prevValues) => ({
                ...prevValues,
                company: '', 
                region: '',  
                district:'',
                taluka:'',
                village:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                company_id: undefined, 
                region_id: undefined, 
                district_id:undefined,
                taluka_id:undefined,
                village_id:undefined
            }));
        } else if (type === 'company') {
            setFormValues((prevValues) => ({
                ...prevValues,
                region: '', 
                district:'',
                taluka:'',
                village:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                region_id: undefined,
                district_id:undefined,
                taluka_id:undefined,
                village_id:undefined
            }));
        }else if (type === 'region') {
            setFormValues((prevValues) => ({
                ...prevValues,
                district:'',
                taluka:'',
                village:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                district_id:undefined,
                taluka_id:undefined,
                village_id:undefined
            }));
        }else if (type === 'district') {
            setFormValues((prevValues) => ({
                ...prevValues,
                taluka:'',
                village:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                taluka_id:undefined,
                village_id:undefined
            }));
        }
        if (newValue && typeof newValue === 'object') {
            if (field === 'region') {
                setSelectedIds((prevIds) => ({
                    ...prevIds,
                    region_id: (newValue as { value: number }).value,
                }));
            }
            if (field === 'district') {
                setSelectedIds((prevIds) => ({
                    ...prevIds,
                    district_id: (newValue as { value: number }).value,
                }));
            }
        }
    };
    const handleIndependentChange = (type: string, newValue: string | null) => {
    if (newValue === null) {
        setFormValues(prev => ({ ...prev, [type]: '' }));
        setSelectedIds(prev => ({ ...prev, [`${type}_id`]: undefined }));
        return;
    }
    const idMap: { [key: string]: number | undefined } = {
        status: status.find((stat: any) => stat.name === newValue)?.id,
        current_type: types.find((type: any) => type.name === newValue)?.id,
        category: category.find((category: any) => category.name === newValue)?.id,
    };
    setFormValues(prev => ({ ...prev, [type]: newValue }));
    setSelectedIds(prev => ({ ...prev, [`${type}_id`]: idMap[type] }));
    };    
    const handleCountClick = (type_id: number) => {
        setSelectedTypeId(type_id);
        setTypeId(type_id);
    };
    const handleSubmit = () => {
        const { state, company, region, district, taluka, village, category, status, current_type, start_date, end_date, period} = formValues;
        if (!state && !company && !region && !district && !taluka && !village && !status && !current_type && !category && !start_date && !end_date && !period ) {
          setError('Please select fields');
        } else {
          setError(''); 
          fetchManage();
        }
    };
    const handleReset = () => {
        if (isSuperAdmin) {
            setFormValues({ state: '', company: '', region: '', district: '', taluka: '', village: '', category: '', status: '', current_type: '', start_date: '', end_date: '', period:'', periodOptions:'' });
            setSelectedIds({
                state_id: undefined, company_id: undefined, region_id: undefined, district_id: undefined, taluka_id: undefined,  village_id: undefined,  category_id: undefined, status_id: undefined, current_type_id: undefined, start_date: undefined, end_date: undefined, 
            }); setPeriod('');
        } else {
            setFormValues(prevValues => ({
                ...prevValues,               
                 taluka: '', village: '', category: '', status: '', current_type: '', start_date: '', end_date: '' }));
            setSelectedIds(prevIds => ({
                ...prevIds,               
                 taluka_id: undefined, village_id: undefined, category_id: undefined, status_id: undefined, current_type_id: undefined, start_date: undefined, end_date: undefined, }));
                 setPeriod('');
        }
    };
    const handleSearchReset = () => {
        setSearchTerm('');
    } 
    const permissions: any = GetPermissions('letter');
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };
    useEffect(() => {
        const initialState = states.find((state) => state.id === userDetails?.state_id)?.name || '';
        const initialCompany = companies.find((company) => company.value === userDetails?.company_id)?.label || '';
        const initialRegion = userMapping?.[0]?.region || '';
        const initialDistrict = userMapping?.[0]?.district || '';    
        if (initialState) {
            setFormValues((prevValues) => ({
                ...prevValues,
                state: initialState,
            }));
            const initialStateId = states.find((state) => state.name === initialState)?.id;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                state_id: initialStateId,
            }));
        }    
        if (initialCompany) {
            setFormValues((prevValues) => ({
                ...prevValues,
                company: initialCompany,
            }));
            const initialCompanyId = companies.find((company) => company.label === initialCompany)?.value;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                company_id: initialCompanyId,
            }));
        }    
        if (initialRegion) {
            setFormValues((prevValues) => ({
                ...prevValues,
                region: regions.find((option) => option.label === initialRegion) || null,
            }));
            const initialRegionId = regionsOptions.find((option: { label: any; }) => option.label === initialRegion)?.value;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                region_id: initialRegionId,
            }));
        }    
        if (initialDistrict) {
            setFormValues((prevValues) => ({
                ...prevValues,
                district: districts.find((option) => option.label === initialDistrict) || null,
            }));
            const initialDistrictId = districtsOptions.find((option: { label: any; }) => option.label === initialDistrict)?.value;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                district_id: initialDistrictId,
            }));
        }
    }, [userDetails, userMapping, companies, states, regionsOptions, districtsOptions]);    
    useEffect(() => {
        if (userDetails?.state_id) {
            getDropdownList('state', userDetails.state_id);
        }
    }, [userDetails?.state_id]);
    useEffect(() => {
        if (userDetails?.company_id) {
            getDropdownList('company', userDetails.company_id);
        }
    }, [userDetails?.company_id]);
    useEffect(() => {
        if (selectedIds.region_id) {
            getDropdownList('region', selectedIds.region_id);
        }
    }, [selectedIds.region_id]);
    useEffect(() => {
        if (selectedIds.district_id) {
            getDropdownList('district', selectedIds.district_id);
        }
    }, [selectedIds.district_id]); 
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setPopoverAnchorEl(null); 
        setMenuAnchorEl(event.currentTarget);
    };    
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };    
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, rowId: any) => {
        setMenuAnchorEl(null); 
        setPopoverAnchorEl(event.currentTarget);
        setPopoverRowId(rowId);
    };    
    const handleClosePopover = () => {
        setPopoverAnchorEl(null);
        setPopoverRowId(null);
    };
    const menuOpen = Boolean(menuAnchorEl);
    const popoverOpen = Boolean(popoverAnchorEl && popoverRowId !== null);   
    const handleTotalClick = () => {
         window.location.reload()
    } 
    const periodHandleChange = (
        event: SyntheticEvent<Element, Event>, 
        newValue: { value: string; label: string } | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<{ value: string; label: string }>
      ) => {
        setPeriod(newValue?.value || '');
        setFormValues((prevValues) => ({
          ...prevValues,
          period: newValue?.value || '',
        }));
        if (error) {
          setError('');
        }
      };
    return (
        <>
            <Box>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                        <Box sx={{pl:2, py: 0 }}>
                            <PageHead primary='Manage Master' />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={10} sx={{ textAlign: 'right' }}>
                        <Stack spacing={2} m={1} direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'center', lg: 'flex-end' }} justifyContent={'end'}>
                            <Box component="section" sx={{ width:'160px', p: '7px', textAlign:'center',borderRadius:'5px', border:selectedTypeId === counts.draft_id ? '2px solid #000' : '1px dashed #000',  backgroundColor: selectedTypeId === counts.draft_id ? '#b3e0ff' : '#ddf0ff', fontWeight: 500, display:'flex', flexDirection:'column', alignItems:'center', cursor:'pointer' }}onClick={() => handleCountClick(counts.draft_id!)} >
                                <Typography variant="h6">{counts.draft_count}</Typography>
                                Draft Letter Received
                            </Box>
                            <Box component="section" sx={{ width:'160px', p: '7px', textAlign:'center',borderRadius:'5px', border:selectedTypeId === counts.final_letter_id ? '2px solid #000' : '1px dashed #000', backgroundColor: selectedTypeId === counts.final_letter_id ? '#b3e0ff' : '#e2ffe3', fontWeight: 500, display:'flex', flexDirection:'column', alignItems:'center', cursor:'pointer' }} onClick={() => handleCountClick(counts.final_letter_id!)}>
                                <Typography variant="h6">{counts.final_letter_count}</Typography>
                                Final Letter Submitted
                            </Box>
                            <Box component="section" sx={{ width:'160px', p: '7px', textAlign:'center',borderRadius:'5px', border:selectedTypeId === counts.acknowledgment_id ? '2px solid #000' : '1px dashed #000', backgroundColor: selectedTypeId === counts.acknowledgment_id ? '#b3e0ff' : '#ffecec', fontWeight: 500, display:'flex', flexDirection:'column', alignItems:'center', cursor:'pointer' }} onClick={() => handleCountClick(counts.acknowledgment_id!)}>
                                <Typography variant="h6">{counts.acknowledgment_count}</Typography>
                                Acknowledgement 
                            </Box>
                            <Box component="section" sx={{ width:'160px', p: '7px', textAlign:'center',borderRadius:'5px', border:selectedTypeId === counts.reply_letter_id ? '2px solid #000' : '1px dashed #000', backgroundColor: selectedTypeId === counts.reply_letter_id ? '#b3e0ff' : '#e6e6e6', fontWeight: 500, display:'flex', flexDirection:'column', alignItems:'center', cursor:'pointer' }} onClick={() => handleCountClick(counts.reply_letter_id!)}>
                                <Typography variant="h6">{counts.reply_letter_count}</Typography>
                                Reply Letter Received 
                            </Box>
                            <Box component="section" sx={{ width:'160px', p: '7px', textAlign:'center',borderRadius:'5px', border:selectedTypeId === counts.inward_letter_id ? '2px solid #000' : '1px dashed #000', backgroundColor: selectedTypeId === counts.inward_letter_id ? '#b3e0ff' : '#f9d8ff', fontWeight: 500, display:'flex', flexDirection:'column', alignItems:'center', cursor:'pointer' }} onClick={() => handleCountClick(counts.inward_letter_id!)}>
                                <Typography variant="h6">{counts.inward_letter_count}</Typography>
                                Inward Letter
                            </Box>
                            <Box component="section" sx={{ width:'160px', p: '7px', textAlign:'center',borderRadius:'5px', border:selectedTypeId === counts.total_count ? '1px dashed #000' : '1px dashed #000', backgroundColor: selectedTypeId === counts.total_count ? '#fffce0' : '#fffce0', fontWeight: 500, display:'flex', flexDirection:'column', alignItems:'center', cursor:'pointer' }} onClick={() => handleTotalClick()}>
                                <Typography variant="h6">{counts.total_count}</Typography>
                                Total Count 
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>   
                <Accordion expanded={expanded} onChange={handleExpansion} slots={{ transition: Fade as AccordionSlots['transition'] }}
                    slotProps={{ transition: { timeout: 400 } }}  sx={{  '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' }, }} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        <Typography>Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container columnSpacing={3}>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.state}
                                            onChange={(event: any, newValue: string | null) => {
                                                handleChange('state', newValue, 'state'); 
                                                if (error) {
                                                  setError(''); 
                                                }
                                              }}
                                            inputValue={formValues.state}
                                            onInputChange={(event, newInputValue) => handleInputChange('state', newInputValue)}
                                            options={states.map((state: any) => state.name)}
                                            disableClearable
                                            freeSolo={false}
                                            disabled={initialState}
                                            renderInput={(params) => ( <TextField  {...params} label="States" /> )}
                                            
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.company}
                                            onChange={(event: any, newValue: string | null) => {
                                                handleChange('company', newValue, 'company'); 
                                                if (error) {
                                                    setError(''); 
                                                }
                                              }}
                                            inputValue={formValues.company}
                                            onInputChange={(event, newInputValue) => handleInputChange('company', newInputValue)}
                                            options={companies.map((company: any) => company.label)}
                                            disableClearable
                                            freeSolo={false}
                                            disabled={initialCompany}
                                            renderInput={(params) => ( <TextField  {...params} label="Company"/> )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            // value={formValues.region}
                                            value={ regionsOptions.length === 1 ? regionsOptions[0] : formValues.region}
                                            onChange={(event: any, newValue: any | null) => {
                                                handleChange('region', newValue, 'region');
                                                if (error) {
                                                    setError(''); 
                                                }
                                              }}
                                            inputValue={formValues.region ? formValues.region.label : ''}
                                            onInputChange={(event, newInputValue) => handleInputChange('region', newInputValue)}
                                            options={regionsOptions && regionsOptions.length > 0 ? regions : regions.map((region: any) => ({ label: region.label, value: region.value }))}
                                            disableClearable
                                            freeSolo={false}
                                            getOptionLabel={(option) => option.label || ''}
                                            renderInput={(params) => (<TextField {...params} label="Region" disabled={regionsOptions.length === 1} />)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <Autocomplete fullWidth
                                        size="small"
                                        value={formValues.district}
                                        // value={ districtsOptions.length === 1 ? districtsOptions[0] : formValues.district}
                                        onChange={(event, newValue) => {
                                            handleChange('district', newValue, 'district');
                                            if (error) {
                                                setError(''); 
                                            }
                                        }}
                                        inputValue={formValues.district ? formValues.district.label : ''}
                                        onInputChange={(event, newInputValue) => handleInputChange('district', newInputValue)}
                                        options={
                                            districtsOptions && districtsOptions.length > 0 && districtsOptions.some((mapping: { district: any; }) => mapping.district)
                                            ? districts 
                                            : districts.map(district => ({ label: district.label, value: district.value })) 
                                        }
                                        disableClearable
                                        freeSolo={false}
                                        getOptionLabel={(option) => option.label || ''}
                                        renderInput={(params) => (<TextField {...params} label="District" disabled={districtsOptions.length === 1}/> )} />
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.taluka}
                                            onChange={(event: any, newValue: string | null) =>{
                                                handleChange('taluka', newValue, 'taluka');
                                                if (error) {
                                                    setError(''); 
                                                }
                                            }}
                                            inputValue={formValues.taluka}
                                            onInputChange={(event, newInputValue) => handleInputChange('taluka', newInputValue)}
                                            options={talukas.map((taluka: any) => taluka.label)}
                                            disableClearable
                                            freeSolo={false}
                                            renderInput={(params) => ( <TextField  {...params}  label="Taluka" /> )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                                size='small'
                                                value={formValues.village}
                                                onChange={(event: any, newValue: string | null) =>{
                                                    handleChange('village', newValue, 'village');
                                                    if (error) {
                                                        setError(''); 
                                                    }
                                                }}
                                                inputValue={formValues.village}
                                                onInputChange={(event, newInputValue) => handleInputChange('village', newInputValue)}
                                                options={villages.map((village: any) => village.label)}
                                                disableClearable
                                                freeSolo={false}
                                                renderInput={(params) => ( <TextField {...params} label="Project" /> )}
                                            />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                                size='small'
                                                value={formValues.status}
                                                onChange={(event: any, newValue: string | null) =>{
                                                     handleIndependentChange('status', newValue);
                                                     if (error) {
                                                        setError(''); 
                                                    }
                                                }}
                                                inputValue={formValues.status}
                                                onInputChange={(event, newInputValue) => handleInputChange('status', newInputValue)}
                                                options={status.map((status: any) => status.name)}
                                                disableClearable
                                                freeSolo={false}
                                                renderInput={(params) => ( <TextField  {...params}  label="Status"/> )}
                                            />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                                size='small'
                                                value={formValues.current_type}
                                                onChange={(event: any, newValue: string | null) => handleIndependentChange('current_type', newValue)}
                                                inputValue={formValues.current_type}
                                                onInputChange={(event, newInputValue) => {
                                                    handleInputChange('types', newInputValue);
                                                    if (error) {
                                                        setError(''); 
                                                    }
                                                }}
                                                options={types.map((types: any) => types.name)}
                                                disableClearable
                                                freeSolo={false}
                                                renderInput={(params) => ( <TextField {...params} label="Letter Stage"/> )}
                                            />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                                size='small'
                                                value={formValues.category}
                                                onChange={(event: any, newValue: string | null) => handleIndependentChange('category', newValue)}
                                                inputValue={formValues.category}
                                                onInputChange={(event, newInputValue) =>{
                                                     handleInputChange('category', newInputValue);
                                                        if (error) {
                                                            setError(''); 
                                                        }
                                                    }}
                                                options={category.map((category: any) => category.name)}
                                                disableClearable
                                                freeSolo={false}
                                                renderInput={(params) => ( <TextField  {...params}  label="Category" /> )}
                                            />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={2} sx={{ mt: 2}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Start Date"
                                             format="DD/MM/YYYY"
                                            views={['year', 'month', 'day']}
                                            value={formValues.start_date ? dayjs(formValues.start_date) : null}
                                            onChange={(date) =>{
                                                 handleChange('start_date', date, 'start_date');
                                                 if (error) {
                                                    setError(''); 
                                                }
                                            }}
                                            slotProps={{ textField: { fullWidth: true, sx: centeredLabelStyle } }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker 
                                            label="End Date"
                                            format="DD/MM/YYYY"
                                            views={['year', 'month', 'day']}
                                            value={formValues.end_date ? dayjs(formValues.end_date) : null}
                                            onChange={(date) =>{
                                                 handleChange('end_date', date, 'end_date');
                                                 if (error) {
                                                    setError(''); 
                                                }
                                            }}
                                            slotProps={{ textField: { fullWidth: true, sx: centeredLabelStyle } }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                                    <FormControl fullWidth className="period-filter">
                                        <Autocomplete
                                            size='small'
                                            options={periodOptions}
                                            getOptionLabel={(option) => option.label}
                                            value={periodOptions.find(option => option.value === period) || null}  
                                            onChange={periodHandleChange}                                            
                                            renderInput={(params) => (
                                                <TextField 
                                                {...params} 
                                                label="Period Filter" 
                                                placeholder="Select period"
                                                error={!!error}                                               
                                                />
                                            )}
                                        />                                        
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                                    <Button onClick={handleSubmit} variant="contained" color="info" sx={{marginTop:'3px'}}>
                                        Filter Data
                                    </Button>
                                    <Button onClick={handleReset} variant="outlined" color="primary" sx={{marginTop:'3px', ml: 2}}>
                                        Reset
                                    </Button>
                                </Grid>

                            </Grid>
                            {error && (
                                <Grid item xs={12} textAlign={'left'} mt={2}>
                                    <Typography color="error" variant="body2" sx={{fontWeight:'400 !important'}}>{error}</Typography>
                                </Grid>
                            )}
                        </AccordionDetails>
                </Accordion>
                <Paper>
                    <Box sx={{ borderTopWidth: 1, borderRightWidth: 0, borderBottomWidth: 1, borderLeftWidth: 0, borderStyle: 'solid', borderColor: 'grey.400', px: 2, pb: 1 }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} lg={9} sx={{ mt: 1.3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap:1, alignItems:'center' }}>
                                    {permissions.includes('store_letter') && (
                                        <Button variant='contained' startIcon={<NoteAddIcon />} onClick={handleAddLetter}>Upload Letter</Button>
                                    )} 
                                    <Button variant="outlined" onClick={handleClickMenu}>
                                        Toggle Columns
                                    </Button>
                                    <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu} sx={{padding:'0 !important'}}MenuListProps={{style: { height: 300, overflow: 'auto',}}}>
                                        {headCells
                                        .filter((cell: any) => !cell.hidden)
                                        .map((cell: any) => (
                                            <MenuItem key={cell.id}>
                                                <FormControlLabel
                                                    control={ <Checkbox  checked={visibleColumns.includes(cell.id)} onChange={() => handleToggleColumn(cell.id)} /> }
                                                    label={cell.label}/>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    <Export headCells={headCells} visibleColumns={visibleColumns} rowsPerPage={rowsPerPage} page={page} searchTerm={searchTerm} selectedIds={selectedIds} period={period} typeId={typeId}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={3}  textAlign={'right'} mt={1} >  
                                <Box display={'flex' } justifyContent={'end'} alignItems={'center'}>                          
                                    <TextField sx={{width:'300px'}}
                                        label="Search"
                                        placeholder="Search here ..."
                                        variant="outlined"
                                        size="small"
                                        value={searchTerm}
                                        // onChange={handleSearchChange}
                                        onChange={(e:any) => {
                                            const value = e.target.value;
                                            if (!value.includes(',')) {
                                            handleSearchChange(e); 
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === ',') {
                                            e.preventDefault();
                                            }
                                        }}
                                    />
                                    
                                    <Button onClick={handleSearchReset} variant="contained" color="info" sx={{ ml: 2}}>
                                        Reset
                                    </Button>
                                </Box>   
                                <Typography sx={{textAlign:'left', ml:2, fontSize:'13px', color:'#2e7d32'}}><strong>Note:</strong>Comma not allowed, even on paste. </Typography>
                            </Grid>  
                        </Grid>    
                    </Box>
                    <TableContainer sx={{ maxHeight: 600 }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium' stickyHeader aria-label="sticky table">
                            <TableHeadList  headCells={headCells.filter((cell: any) => visibleColumns.includes(cell.id))} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                            <TableBody>
                                {manage.length > 0 ? (
                                    manage.map((row, index) => (
                                    <TableRow key={row.id} className="letterList">                                        
                                    {headCells
                                        .filter((cell: any) => visibleColumns.includes(cell.id) && cell.id !== 'is_blocked' && cell.id !== 'is_cancelled')
                                        .map((cell: any) => (
                                        <StyledTableCell key={cell.id} sx={{ maxWidth: cell.width, whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <Tooltip
                                                title={
                                                    cell.id === 'id' ? ( index + 1 + page * rowsPerPage) : cell.id === 'created_at' ? ( dayjs(row[cell.id]).format('DD-MM-YYYY')
                                                    ) : cell.id === 'remarks' ? ( row.letter_files.map((file: any) => file.remarks).join(', ')
                                                    ) : ( row[cell.id] || "-"  ) }>
                                                
                                                <span>
                                                    {cell.id === 'id' ? ( index + 1 + page * rowsPerPage) : cell.id === 'created_at' ? (
                                                        <>
                                                            {dayjs(row[cell.id]).format('DD-MM-YYYY')}
                                                            {/* {row.notification_count >= 1 && (
                                                                <Tooltip title="The letter description is available in the activity dialog under the notification tab." placement="top">
                                                                    <Badge badgeContent={row.notification_count} sx={{zIndex:0, '& .MuiBadge-badge': {color:'#fff', backgroundColor:'#f5815c', top:'0', right:'-4px !important'}}}>
                                                                        <NotificationsNoneIcon sx={{ml:3, fontSize:'25px', color:'#0a1116', cursor:'pointer'}} />                                                                   
                                                                    </Badge>
                                                                </Tooltip>
                                                             )} */}
                                                        </>
                                                    ): cell.id === 'modify_date' ? (dayjs(row[cell.id]).format('DD-MM-YYYY')) : cell.id === 'remarks' ? (
                                                        row.letter_files.map((file: any) =>
                                                        file.remarks).join(', ') || '-'
                                                    ) : (
                                                        row[cell.id] || '-'
                                                    )}
                                                </span>
                                            </Tooltip>
                                        </StyledTableCell>
                                    ))}
                                    {visibleColumns.includes('action') && (
                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                           <Button aria-describedby={`popover-${row.id}`} onClick={(e) => handleClickPopover(e, row.id)} >
                                                <Tooltip title="The letter description is available in the activity dialog under the notification tab." placement="top">
                                                    <Badge badgeContent={row.notification_count} sx={{zIndex:0, '& .MuiBadge-badge': {color:'#fff', backgroundColor:'#f5815c', top:'0', right:'-2px !important'}}}>
                                                        <MoreVertIcon/>                                                                   
                                                    </Badge>
                                                </Tooltip>
                                            </Button>
                                            <Popover
                                                id={`popover-${row.id}`}
                                                open={popoverOpen && popoverRowId === row.id}
                                                anchorEl={popoverAnchorEl}
                                                onClose={handleClosePopover}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                                                <Box sx={{display:'flex', flexDirection:'column', gap:2}}>
                                                    
                                                     {permissions.includes('edit_letter') && (
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Edit</Typography>
                                                            <Button size="small" variant="contained"  title="Edit" onClick={() => handleClickEdit(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                <ModeEditOutlinedIcon sx={{fontSize:'16px'}} />
                                                            </Button>
                                                        </Box>    
                                                    )} 
                                                    {permissions.includes('letter_view') && (
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>View</Typography>
                                                            <Button size="small" variant="contained" color="info" title="View Letter" onClick={() => handleClickView(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                <RemoveRedEyeIcon sx={{fontSize:'16px'}} />
                                                            </Button >   
                                                        </Box>    
                                                    )}   
                                                    {permissions.includes('add_notification_remark') && (
                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Add Remark</Typography>
                                                        <Button size="small" color="secondary" variant="contained"  title="Add Remark" onClick={() => handleNotificationDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                            <ChatIcon sx={{fontSize:'16px'}} />
                                                        </Button >
                                                    </Box>
                                                    )}
                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Activity Logs</Typography>  
                                                            <Badge badgeContent={row.notification_count} sx={{zIndex:0, '& .MuiBadge-badge': {color:'#fff', backgroundColor:'#f5815c', top:'0', right:'7px !important'}}}>
                                                                <Button size="small" variant="contained" color="success" title="Activity Logs" onClick={() => handleOpenActivityDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                    <AccessTimeIcon sx={{fontSize:'16px'}} />
                                                                </Button >                                                                
                                                            </Badge>
                                                    </Box>   
                                                    {permissions.includes('cancel_letter') && row.stage === 'Draft Letter Uploaded' && (
                                                        <Box display={'flex'} justifyContent={'space-between'}>
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>
                                                                {row.is_cancelled === 1 ? 'Cancelled' : 'Cancel'}
                                                            </Typography>
                                                            <Button size="small" color="error" variant="contained" title="Cancel" onClick={() =>  handleOpenCancelBackDialog(row, 'cancel')} disabled={row.is_cancelled === 1} style={{ cursor: 'pointer', marginRight: 10, width: '30px' }}>
                                                                <ClearIcon sx={{ fontSize: '16px' }} />
                                                            </Button>
                                                        </Box>
                                                    )}
                                                     {isSuperAdmin && row.is_cancelled ===1 && (   
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Revert Back</Typography>    
                                                            <Button size="small" color="success" variant="contained" title="Revert Back" onClick={() => handleOpenCancelBackDialog(row, 'revert-back')} style={{ cursor: 'pointer', marginRight: 10, width: '30px' }} >
                                                                <KeyboardBackspaceIcon sx={{ fontSize: '16px' }} />
                                                            </Button>
                                                        </Box>
                                                    )} 
                                                    {permissions.includes('block_unblock_letter') && (
                                                        <>
                                                        {row.is_blocked === 0 ? (
                                                            <Box display={'flex'} justifyContent={'space-between'} >  
                                                                <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Block</Typography>
                                                                <Button size="small" color="error" variant="contained" title="Block" onClick={() => handleOpenBlockUnblockDialog(row, 'block')} style={{ cursor: 'pointer', marginRight: 10, width: '30px' }} >
                                                                    <BlockIcon sx={{ fontSize: '16px' }} />
                                                                </Button>
                                                            </Box>
                                                            ) : (
                                                            <Box display={'flex'} justifyContent={'space-between'} >  
                                                                <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Unblock</Typography>
                                                                <Button size="small" color="warning" variant="contained" title="Unblock" onClick={() => handleOpenBlockUnblockDialog(row, 'unblock')} style={{ cursor: 'pointer', marginRight: 10, width: '30px' }} >
                                                                    <OfflinePinIcon sx={{ fontSize: '16px' }} />
                                                                </Button>
                                                            </Box>    
                                                        )}
                                                        </>
                                                    )}
                                                    {row.parent_id !==0 && (
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Parent Details</Typography>
                                                            <Button size="small" variant="contained" color="info" title="Parent Details" onClick={() => handleOpenParentDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                <InfoIcon sx={{fontSize:'16px'}} />
                                                            </Button >
                                                        </Box>
                                                    )}
                                                    {permissions.includes('add_parent_reference') && (
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Add Reference</Typography>
                                                            <Button size="small" variant="contained" title="Add Refrence" onClick={() => handleAddRefrence(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                <AddCircleOutlineIcon sx={{fontSize:'16px'}} />
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    {row.has_children === 1 && (
                                                        <Box display={'flex'} justifyContent={'space-between'} >  
                                                            <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}> References</Typography>
                                                            <Button size="small" variant="contained" color="success" title="Refrences" onClick={() => handleOpenRefrencesDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                <PersonSearchIcon sx={{fontSize:'16px'}} />
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    {permissions.includes('status_change') && (
                                                        <>
                                                        {/* && (row.stage === 'Final Letter Uploaded' || row.stage === 'Inward Uploaded') */}
                                                            {/* {row.is_status === 1 && ( */}
                                                                <Box display={'flex'} justifyContent={'space-between'}>  
                                                                    <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Change Status</Typography>
                                                                    <Button size="small" color="secondary" variant="contained" title="Change Status" onClick={() => handleChangeStatusDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                        <AutorenewIcon sx={{fontSize:'16px'}} />
                                                                    </Button>
                                                                </Box>    
                                                            {/* )} */}
                                                        </>
                                                    )}
                                                </Box>
                                            </Popover> 
                                        </StyledTableCell>
                                    )}
                                    </TableRow>
                                ))
                                ) : (
                                <TableRow>
                                    <StyledTableCell colSpan={headCells.length} align="center">
                                    {loading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                        No records found.
                                        </Typography>
                                    )}
                                    </StyledTableCell>
                                </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>       
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 75, 100]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />           
                </Paper>
             </Box>
            <BlockUnblockManageDialog dialogOpen={blockUnblockDialogOpen} manageToBlock={manageToBlock} handleClose={handleCloseBlockUnblockDialog} handleSuccess={handleSuccess} handleBlockUnblock={handleBlockUnblock}/>
            <ActivityLogDialog dialogOpen={activityDialogOpen} manageToActivity={manageToActivity} handleClose={handleCloseActivityDialog} handleSuccess={handleSuccess}/>
            <LetterDetails dialogOpen={detailsDialogOpen} manageToDetails={manageToDetails} handleClose={handleCloseDetailsDialog} handleSuccess={handleSuccess}/>
            <ParentDetailDialog dialogOpen={parentDialogOpen} manageToParent={manageToParent} handleClose={handleCloseParentDialog} handleSuccess={handleSuccess}/>
            <ChangeStatusDialog dialogOpen={statusDialogOpen} manageToStatus={manageToStatus} handleClose={handleCloseStatusDialog} handleSuccess={handleSuccess}/>
            <CancelRevertBackDialog dialogOpen={cancelBackDialogOpen} manageToCancelBack={manageToCancelBack} handleClose={handleCloseCancelBackDialog} handleSuccess={handleSuccess} handleCancelBack={handleCancelBack}/>
            <NotificationDialog dialogOpen={notificationDialogOpen} manageToNotification={manageToNotification} handleCloseDialog={handleCloseNotificationDialog} handleSuccess={handleSuccess} />
            <BulkUplaodDialog dialogOpen={bulkUploadDialogOpen} handleCloseDialog={handleCloseBulkUploadDialog} />
            <RefrencesDetailDialog dialogOpen={refrencesDialogOpen} manageToRefrences={manageToRefrences} handleClose={handleCloseRefrencesDialog} handleSuccess={handleSuccess}/>
        </>
    )
}
export default ManageMaster
