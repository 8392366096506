import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, ToastOptions, toast } from 'react-toastify'
import { RootState } from '../redux/store';
import { hideSnackbar } from '../redux/reducer/snackbarSlice';

const SnackbarMessage = () => {
    const dispatch = useDispatch()
    const { open, type, message, } = useSelector((state: any) => state.snackbar);
    type toastEmitterType = {
        position: string;
        autoClose: number;
        hideProgressBar: boolean;
        closeOnClick: boolean;
        pauseOnHover: boolean;
        draggable: boolean;
        progress: undefined;
        theme: string;

    }
    const toastEmitter: ToastOptions<toastEmitterType> = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Slide,
    }
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                dispatch(hideSnackbar())
            }, 5000);
        }
    }, [open])

    switch (type) {
        case 'success': toast.success(message, toastEmitter);
            break;
        case 'error': toast.error(message, toastEmitter);
            break;
        case 'info': toast.info(message, toastEmitter);
            break;
        case 'warn': toast.warn(message, toastEmitter);
            break;
        default:
            break;
    }
    return (
        <>{open && <ToastContainer
            position={"bottom-right"}
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />} </>
    )
}

export default SnackbarMessage