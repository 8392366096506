import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton,  CircularProgress,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RoleCreateService, RoleUpdateService } from '../../../../utils/services/role.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
interface AddRoleDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
    currentRole:{ id: string; name: string } | null;
}

const AddRoleDialog: React.FC<AddRoleDialogProps> = ({ dialogOpen, handleCloseDialog, currentRole, handleSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const isEditing = currentRole !== null;
    const AddRoleFormik = useFormik({
        initialValues: {
            name:currentRole?.name || '',
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            name: Yup.string().trim().required("Please enter role"),
        }),
        onSubmit: async (values, {resetForm}) => {
            let serviceResponse;
            if (isEditing) {
                const { data, code } = await RoleCreateService({ ...values, id: currentRole.id });
                serviceResponse = { data, code };
            } else {
                const { data, code } = await RoleCreateService(values);
                serviceResponse = { data, code };
            }
            
            if (serviceResponse.data && serviceResponse.code === 200) {
                resetForm();
                handleCloseDialog();
                handleSuccess();
                setLoading(false);
                const message = isEditing ? 'Role details updated' : 'Role created successfully';
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } else {
                const errorMessage = serviceResponse.data || 'An error occurred';
                dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
            }
        },
    });
    const handleClose = () => {
        AddRoleFormik.resetForm();
        handleCloseDialog();
    };
    return (
        <>
            <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: AddRoleFormik.handleSubmit }} >
               <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position:'sticky', top:'0', left:'0', right:'0', zIndex:'99', backgroundColor:'#fff', borderBottom:'1px solid #ccc', p:'10px 25px'  }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10} >
                            <Typography variant="h4">{isEditing ? 'Edit Role' : 'Add Role'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={() => { handleClose() }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                 autoComplete="off"
                                fullWidth
                                label="Role*"
                                variant="outlined"
                                size="small"
                                {...AddRoleFormik.getFieldProps('name')}
                                name="name"
                                error={AddRoleFormik.touched.name && Boolean(AddRoleFormik.errors.name)}
                                helperText={AddRoleFormik.touched.name && AddRoleFormik.errors.name}
                                />
                            </FormControl>
                        </Grid>
                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddRoleDialog;