import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CategoryCreateService, CategoryUpdateService } from '../../../../utils/services/category.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
interface AddCategoryDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
    currentCategory: { id: string; name: string } | null;
}

const AddCategoryDialog: React.FC<AddCategoryDialogProps> = ({ dialogOpen, handleCloseDialog, currentCategory, handleSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const isEditing = currentCategory !== null;

    const AddCategoryFormik = useFormik({
        initialValues: {
            name: currentCategory?.name || '',
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            name: Yup.string().trim().required('Please enter category'),
        }),
        onSubmit: async (values, { resetForm }) => {
            let serviceResponse;
            if (isEditing) {
                const { data, code } = await CategoryCreateService({ ...values, id: currentCategory.id });
                serviceResponse = { data, code };
            } else {
                const { data, code } = await CategoryCreateService(values);
                serviceResponse = { data, code };
            }
            
            if (serviceResponse.data && serviceResponse.code === 200) {
                resetForm();
                handleCloseDialog();
                handleSuccess();
                setLoading(false);
                const message = isEditing ? 'Category details updated' : 'Category created successfully';
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } else {
                const errorMessage = serviceResponse.data || 'An error occurred';
                dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
            }
        },
    });
    const handleClose = () => {
        AddCategoryFormik.resetForm();
        handleCloseDialog();
    };
    return (
        <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: AddCategoryFormik.handleSubmit }} >
            <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px', }} >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="h4">{isEditing ? 'Edit Category' : 'Add Category'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth required>
                            <TextField
                             autoComplete="off"
                                fullWidth
                                label="Category*"
                                variant="outlined"
                                size="small"
                                {...AddCategoryFormik.getFieldProps('name')}
                                name="name"
                                error={AddCategoryFormik.touched.name && Boolean(AddCategoryFormik.errors.name)}
                                helperText={AddCategoryFormik.touched.name && typeof AddCategoryFormik.errors.name === 'string' ? AddCategoryFormik.errors.name : ''}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCategoryDialog;
