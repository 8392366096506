import { Box } from '@mui/material';

const Error404 = () => {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <img src="/images/error-404.jpg" alt='Error 404' />
        </Box>
    )
}

export default Error404