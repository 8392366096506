import axios, { AxiosInstance } from 'axios'
import { BaseUrls } from './base-urls'
import store from '../redux/store'

let ClientsAxios: any = {}
Object.keys(BaseUrls).forEach((base: any) => {
    ClientsAxios[base] = axios.create({ baseURL: BaseUrls[base].url })
    addInterceptor(ClientsAxios[base], BaseUrls[base].config)
})

function addInterceptor(instance: AxiosInstance, requestExtetion: any): AxiosInstance {
    instance.interceptors.request.use(async (request) => {
        const authUser = store.getState().authUser;
        if (authUser && authUser.auth_token) request.headers['Authorization'] = authUser.auth_token
        request.headers['Accept'] = 'Application/json'
        request.headers['Content-Type'] = 'application/octet-stream'
        return request
    }, (error) => {
        return Promise.reject(error)
    })

    instance.interceptors.response.use((response) => {
        return response
    }, (error) => {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
            localStorage.clear();
            window.open("/auth/login", "_self")
        } else {
            // store.dispatch(showSnackbar({ open: true, type: 'error', message: error.response.data.message ? error.response.data.message : 'Something went wrong' }))
            return Promise.reject(error);
        }
    })
    return instance
}

export default ClientsAxios