import { Navigate, Route, Routes } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import AdminRoute from './AdminRoute';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPermissions } from '../redux/reducer/permissionSlice';

const AppRoute = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const savedPermissions = localStorage.getItem('permissions');
        if (savedPermissions) {
            dispatch(setPermissions(JSON.parse(savedPermissions)));
        }
    }, [dispatch]);

    return (
        <Routes>
            <Route path='/auth/*' element={<AuthRoute />} />
            <Route path='*' element={<AdminRoute />} />
        </Routes>
    );
}

export default AppRoute;
