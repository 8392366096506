import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Autocomplete, createFilterOptions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CompanyCreateService, CompanyUpdateService } from '../../../../utils/services/company.service';
import { FilterListService } from '../../../../utils/services/filter.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
import { StateListService } from '../../../../utils/services/state.service';
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
interface AddCompanyDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
    currentCompany:{ id: string; state:string, company: string, name:string, state_name:string } | null;
}
interface StateOption {
    id: number;
    name: string;
}
const filterOptions = createFilterOptions<StateOption>({
    matchFrom: 'start',
    stringify: (option) => option.name,
});

const AddCompanyDialog: React.FC<AddCompanyDialogProps> = ({ dialogOpen, handleCloseDialog, currentCompany, handleSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [stateOptions, setStateOptions] = useState<string[]>([]);
    const [states, setStates] = useState<any>([]);
    const isEditing = currentCompany !== null;    
    const userDetails = useSelector((state: RootState) => state.authUser.user);
    const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';
    const AddCompanyFormik = useFormik({
        initialValues: {
            state:currentCompany?.state_name || initialState || '',
            name:currentCompany?.name || '',
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            state: Yup.string().required('Please select a state'),               
            name: Yup.string().trim().required("Company is required"),
        }),
        onSubmit: async (values, {resetForm}) => {
            const stateId = states.find((state: { name: string; }) => state.name === values.state)?.id;
            let serviceResponse;
            if (isEditing) {
                const { data, code } = await CompanyCreateService({
                    id: currentCompany.id,
                    state_id: stateId,
                    name: values.name
                });
                serviceResponse = { data, code };
            } else {
                const { data, code } = await CompanyCreateService({
                    state_id: stateId,
                    name: values.name
                });
                serviceResponse = { data, code };
            }
           
            if (serviceResponse.data && serviceResponse.code === 200) {
                resetForm();
                handleCloseDialog();
                handleSuccess();
                setLoading(false);
                const message = isEditing ? 'Company details updated' : 'Company created successfully';
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } else {
                const errorMessage = serviceResponse.data || 'An error occurred';
                dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
            }         
        },
    });
    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
        };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };
    const handleClose = () => {
        AddCompanyFormik.resetForm();
        handleCloseDialog();
    };
  
    return (
        <>
            <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: AddCompanyFormik.handleSubmit }} >
                <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} md={10} >
                                <Typography variant="h4">{isEditing ? 'Edit Company' : 'Add Company'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={2} textAlign={"end"}>
                                <IconButton onClick={() => { handleClose() }}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size="small"
                                    value={states.find((state: { name: string; }) => state.name === AddCompanyFormik.values.state) || null}
                                    onChange={(event, newValue: StateOption | null) => {
                                    AddCompanyFormik.setFieldValue('state', newValue ? newValue.name : '');
                                    }}
                                    inputValue={AddCompanyFormik.values.state}
                                    onInputChange={(event, newInputValue) => {
                                        AddCompanyFormik.setFieldValue('state', newInputValue);
                                    }}
                                    options={states}
                                    filterOptions={filterOptions}
                                    getOptionLabel={(option: StateOption) => option.name}
                                    key={states.map((state: { id: any; }) => state.id).join(',')}
                                    disableClearable
                                    freeSolo={false} 
                                    disabled={initialState}
                                    onBlur={() => {
                                        const validState = states.find((state: { name: any; }) => state.name === AddCompanyFormik.values.state);
                                        if (!validState) {
                                          AddCompanyFormik.setFieldValue(
                                            'state',
                                            currentCompany?.state_name || ''
                                          );
                                        }
                                      }}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="States*"
                                        error={AddCompanyFormik.touched.state && Boolean(AddCompanyFormik.errors.state)}
                                        helperText={AddCompanyFormik.touched.state && typeof AddCompanyFormik.errors.state === 'string' ? AddCompanyFormik.errors.state : ''}
                                    />
                                    )}
                                />   
                                {/* <Autocomplete
                                    size="small"
                                    value={states.find((state: { name: any; }) => state.name === AddCompanyFormik.values.state) || undefined}
                                    onChange={(event, newValue: StateOption | null) => {
                                        AddCompanyFormik.setFieldValue('state', newValue ? newValue.name : '');
                                    }}
                                    inputValue={AddCompanyFormik.values.state}
                                    onInputChange={(event, newInputValue) => {
                                        AddCompanyFormik.setFieldValue('state', newInputValue);
                                    }}
                                    options={states}
                                    getOptionLabel={(option: StateOption) => option.name}
                                    filterOptions={filterOptions}
                                    key={states.map((state: { id: any; }) => state.id).join(',')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="States*"
                                            error={AddCompanyFormik.touched.state && Boolean(AddCompanyFormik.errors.state)}
                                            helperText={AddCompanyFormik.touched.state && typeof AddCompanyFormik.errors.state === 'string' ? AddCompanyFormik.errors.state : ''}
                                        />
                                    )}
                                    disableClearable
                                /> */}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                 autoComplete="off"
                                    fullWidth
                                    label="Company*"
                                    variant="outlined"
                                    size="small"
                                    {...AddCompanyFormik.getFieldProps('name')}
                                    name="name"
                                    error={AddCompanyFormik.touched.name && Boolean(AddCompanyFormik.errors.name)}
                                    helperText={AddCompanyFormik.touched.name && AddCompanyFormik.errors.name}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddCompanyDialog;
