import { Add } from "@mui/icons-material"
import { Badge, styled, Box, Button, Paper, TableContainer, Table, TableRow, TableCell, Checkbox, TableBody, TablePagination, Avatar, Tooltip,  MenuItem, Chip, IconButton, ListItemIcon, useMediaQuery, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Popover } from '@mui/material'
import PageHead from "../../../../components/admin/PageHead"
import TableHeadList from "../../../../components/table/TableHeadList";
import { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import { PermissionListService } from "../../../../utils/services/permission.service";
import AddPermissionDialog from "./addPermission";
import DeletePermissionDialog from "./deletePermissionDialog";
import { StyledTableCell } from "../../../../components/StyledComponents";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from "react";
import { GetPermissions } from "../../../../utils/utils";
const headCells: any = [
    {
        id: 'srNo',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 20
    },
    {
        id: 'permission',
        numeric: false,
        disablePadding: false,
        label: 'Permission',
        width: 800
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
        width: 50
    },
];

const PermissionMaster = () => {
    const navigate = useNavigate();
    const [popoverRowId, setPopoverRowId] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentPermission, setCurrentPermission] = useState<any>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [permissionToDelete, setPermissionToDelete] = useState<any>(null);
    const [permissions, setPermissions]= useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const location = useLocation();
    const { state } = location as { state: { menuId: number } };
    const [menuId, setMenuId] = useState<number | null>(null);
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    useEffect(() => {       
        if (state && state.menuId) {
            setMenuId(state.menuId);
            fetchPermissions(state.menuId);
        }
    }, [state]);

    const fetchPermissions = async (menuId: number) => {
        setLoading(true);
        const payload = { id: menuId };
        const { data, code } = await PermissionListService(payload);
        if (code === 200 && data) {
            setPermissions(data.data.menu_permissions);            
        }
        setLoading(false);
    };

    const handleOpenDialog = () => {
        setCurrentPermission(null);
        setDialogOpen(true);
    };

    const handleOpenEditDialog = (name: any) => {
        setCurrentPermission(name);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
  
    const handleOpenDeleteDialog = (permission: any) => {
        setPermissionToDelete(permission);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    };
    const handleSuccess = () => {
        fetchPermissions(state.menuId);
    };
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };
    
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, rowId: any) => {
        setAnchorEl(event.currentTarget);
        setPopoverRowId(rowId);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverRowId(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const user_permissions: any = GetPermissions('letter');
    return (
        <>
            <Box>
                <Box sx={{ px: 2, py: 1.5 }}>
                    <PageHead primary='Permission Master' />
                </Box>
                <Paper>
                    <Box sx={{ borderTopWidth: 1, borderRightWidth: 0, borderBottomWidth: 1, borderLeftWidth: 0, borderStyle: 'solid', borderColor: 'grey.400', px: 2, py: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button size="small" variant='contained' startIcon={<Add />} onClick={handleOpenDialog}>Add Permission</Button>
                        </Box>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium'>
                            <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                           
                            <TableBody>
                                    {permissions.length > 0 ? (
                                        permissions?.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {index + 1}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {row.permission_name}
                                                </StyledTableCell>
                                               
                                                <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    <Button aria-describedby={id} onClick={(e) => handleClickPopover(e, row.id)}>
                                                        <MoreVertIcon />
                                                    </Button>
                                                    {popoverRowId === row.id && (
                                                        <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                                                            <Box sx={{display:'flex', flexDirection:'column', gap:2}}>
                                                               
                                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Edit</Typography>                                      
                                                                        <Button size="small" variant="contained"  title="Edit" onClick={() => handleOpenEditDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                                <ModeEditOutlinedIcon sx={{fontSize:'16px'}} />
                                                                        </Button>
                                                                    </Box>
                                                               
                                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Delete</Typography> 
                                                                        <Button size="small" color="error" variant="contained"  title="Delete" onClick={() => handleOpenDeleteDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px', }}>
                                                                            <DeleteOutlineOutlinedIcon sx={{fontSize:'16px'}} />
                                                                        </Button>
                                                                    </Box>    
                                                            </Box>
                                                        </Popover>
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                        ) : (
                                            <TableRow>
                                                <StyledTableCell colSpan={3} align="center">
                                                    {loading ? (
                                                        <CircularProgress size={24} />
                                                    ) : (
                                                        <Typography variant="body2" color="textSecondary">
                                                            No records found.
                                                        </Typography>
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                            </TableBody>
                        </Table>
                    </TableContainer>   
                </Paper>
             </Box>
             <AddPermissionDialog 
                dialogOpen={dialogOpen} 
                handleCloseDialog={handleCloseDialog} 
                currentPermission={currentPermission} 
                handleSuccess={() => fetchPermissions(menuId!)}
                menuId={menuId!}
            />
             <DeletePermissionDialog dialogOpen={deleteDialogOpen} permissionToDelete={permissionToDelete} handleClose={handleCloseDeleteDialog} onDeleteSuccess={handleSuccess} />
           
             
             
            
        </>
    )
}
export default PermissionMaster