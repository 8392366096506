import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import { CancelRevertBackService } from '../../../utils/services/manage.service';
import React from 'react';

interface DeleteDialogProps {
    dialogOpen: boolean;
    manageToCancelBack: any;
    handleClose: () => void;
    handleSuccess: () => void;
    handleCancelBack: () => void;
}

const CancelRevertBackDialog: React.FC<DeleteDialogProps> = ({ dialogOpen, manageToCancelBack, handleClose, handleSuccess, handleCancelBack }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const handleStatus = async () => {
        if (!manageToCancelBack || !manageToCancelBack.id) {
            return;
        }
        setLoading(true);
        const payload = {
            letter_id: manageToCancelBack.id,
            cancel_status: manageToCancelBack.type
        };
        const { data, code } = await CancelRevertBackService(payload);
        if (code === 200 && data) {
            handleSuccess();
            handleCancelBack();
            setLoading(false);
            handleClose();
            dispatch(showSnackbar({ message: `Letter ${manageToCancelBack.type}ed Successfully`, type: 'success', open: true }));
        } else {
            setLoading(false);
            dispatch(showSnackbar({ message: `Failed to ${manageToCancelBack.type} the Letter.`, type: 'error', open: true }));
        }
    };

    return (
        <Dialog fullWidth maxWidth="xs" open={dialogOpen} onClose={handleClose} >
            <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px' }} >
                {`Confirm ${manageToCancelBack?.type.charAt(0).toUpperCase() + manageToCancelBack?.type.slice(1)}`}
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Are you sure you want to {manageToCancelBack?.type} the "{manageToCancelBack?.state_name}"?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleStatus} color="primary" variant="contained" autoFocus disabled={loading}>
                    {loading ? 'Processing...' : 'Yes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancelRevertBackDialog;
