import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState } from 'react';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import { ProjectDeleteService } from '../../../../utils/services/project.service';
interface DeleteDialogProps {
    dialogOpen: boolean;
    projectToDelete: any;
    handleClose: () => void;
    handleSuccess: () => void;
}
const DeleteProjectDialog: React.FC<DeleteDialogProps> = ({ dialogOpen, projectToDelete, handleClose, handleSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const handleDelete = async () => {
        if (!projectToDelete) return;        
        setLoading(true);
        const { data, code } = await ProjectDeleteService(projectToDelete.id);
        if (code === 200 && data) {
            handleSuccess();
            setLoading(false);
            handleClose();
            dispatch(showSnackbar({ message: 'Project Deleted Successfully', type: 'success', open: true }));
        }else{
            dispatch(showSnackbar({ message: data || 'Something went wrong. Please try again later.', type: 'error', open: true }));
        }
    };
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={dialogOpen}
            onClose={handleClose}
        >
            <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99',
                    backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px' }}>
                {"Confirm Delete"}
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Are you sure you want to delete the project "{projectToDelete?.name}"?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="primary" variant="contained" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteProjectDialog;
