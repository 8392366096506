import React, { useEffect, useState } from "react"
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography, TableContainer, Table, TableRow, TableCell, TableBody, TablePagination, Paper, CircularProgress, Grid, Box, IconButton } from '@mui/material';
import TableHeadList from "../../../components/table/TableHeadList";
import { LetterDetailsService } from '../../../utils/services/manage.service';
import CloseIcon from '@mui/icons-material/Close';
interface DetailsDialogProps {
    dialogOpen: boolean;
    manageToDetails: any;
    handleClose: () => void;
    handleSuccess: () => void;
}
const headCells: any = [
    {
        id: 'srNo',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 20
    },
    {
        id: 'auto',
        numeric: false,
        disablePadding: false,
        label: 'Ref No. Auto',
        width: 300
    },
    {
        id: 'manual',
        numeric: false,
        disablePadding: false,
        label: 'Ref No. Manual',
        width: 200
    },
    {
        id: 'prefix',
        numeric: false,
        disablePadding: false,
        label: 'Ref No. Prefix',
        width: 200
    },
];
const LetterDetails : React.FC<DetailsDialogProps> = ({ dialogOpen, manageToDetails, handleClose, handleSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [letterDetails, setLetterDetails]= useState<any[]>([]);
    useEffect(() => {
        if (dialogOpen && manageToDetails && manageToDetails.id) {
            fetchDetails();
        }
    }, [dialogOpen, manageToDetails]);

    const fetchDetails = async () => {
        if (!manageToDetails || !manageToDetails.id) {
            return;
        }
        const payload = {
            letter_id: manageToDetails.id,
        };
        setLoading(true);
        const { data, code } = await LetterDetailsService(payload);
        if (code === 200 && data) {
            setLetterDetails(data);
            console.log("DATA", data)
        }
        setLoading(false);
    };
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    return(
        
        <> 
             <Dialog
                fullWidth
                maxWidth="md"
                open={dialogOpen}
                onClose={handleClose}
            >
            <DialogTitle
                component="div"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: 'grey.800',
                    position: 'sticky',
                    top: '0',
                    left: '0',
                    right: '0',
                    zIndex: '99',
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ccc',
                    p: '10px 25px',
                    mb:2
                }}
            >
               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="h4">Letter Details</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium'>
                        <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                        <TableBody>
                                {letterDetails.length > 0 ? (
                                    letterDetails.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                        {row.reference_no_auto}
                                    </TableCell>
                                    <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                        {row.reference_no_manual}
                                    </TableCell>
                                    <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                        {row.reference_no_prefix}
                                    </TableCell>
                                </TableRow>
                            ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        {loading ? (
                                            <CircularProgress size={4} />
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No records found.
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>  
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}
export default LetterDetails