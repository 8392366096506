import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Table, TableBody, TableCell, TableRow, CircularProgress, TableContainer, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { UserDetailService } from "../../../../utils/services/user.service";
import { decryptText } from '../../../../utils/utils';
import TableHeadList from '../../../../components/table/TableHeadList';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 0,
  minWidth: '100px',
  padding: '9px 6px',
  '&:hover': { padding: 'dense' },
}));
const headCells: any = [
  {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: 250
  },
  {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
      width: 250
  },
  {
      id: 'mobile_number',
      numeric: false,
      disablePadding: false,
      label: 'Mobile Number',
      width: 220
  },
  {
      id: 'roles',
      numeric: false,
      disablePadding: false,
      label: 'Role',
      width: 200
  },
];

interface ViewUserDialogProps {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
}

const ViewUserDialog: React.FC<ViewUserDialogProps> = ({ dialogOpen, handleCloseDialog }) => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAuthToken = () => {
        const token = localStorage.getItem('user');
        if (token) {
            const decrypted = decryptText(token); 
            const { auth_token } = JSON.parse(decrypted); 
            fetchUserDetails(auth_token); 
        }
    };
  

    if (dialogOpen) {
        fetchAuthToken();
    }
}, [dialogOpen]);


const fetchUserDetails = async (authToken: string) => {
  setLoading(true);
  const payload = {
      auth_token: authToken,
  };

  const { data, code } = await UserDetailService(payload);
      setLoading(false);

      if (code === 200 && data) {
          setUserDetails({
              user_details: data.user_details,
              roles: data.roles
          });
      } else {
          console.error('Failed to fetch user details:', data);
      }
};
const handleClose = () => {
  setUserDetails(null); 
  handleCloseDialog();
};
const handleSort = (id: string, order: 'asc' | 'desc') => {
  setSortBy(id);
  setSortOrder(order);
};
const [sortBy, setSortBy] = useState('name');
const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogOpen}
      onClose={handleCloseDialog}
    >
      <DialogTitle
        component="div"
        sx={{
          fontWeight: 'bold',
          fontSize: '22px',
          color: 'grey.800',
          position: 'sticky',
          top: '0',
          left: '0',
          right: '0',
          zIndex: '99',
          backgroundColor: '#fff',
          borderBottom: '1px solid #ccc',
          p: '10px 25px',
        }}
      >
        User Details
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {loading ? (
          <CircularProgress size={24} />
        ) : userDetails ? (
          <TableContainer component={Paper}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium'>
                <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
            <TableBody>
              <TableRow>
                <TableCell>{userDetails.user_details?.name}</TableCell>
                <TableCell>{userDetails.user_details?.email}</TableCell>
                <TableCell>{userDetails.user_details?.mobile_number}</TableCell>    
                <TableCell>
                    {userDetails.roles?.map((role: any) => (
                        <Typography key={role.id} variant="body2">{role.name}</Typography>
                    ))}
                </TableCell>           
              </TableRow>
            </TableBody>
          </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No user details found.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewUserDialog;
