import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState } from 'react';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import { UserDeleteService } from '../../../../utils/services/user.service';
interface DeleteDialogProps {
    dialogOpen: boolean;
    userToDelete: any;
    handleClose: () => void;
    handleSuccess: () => void;
}
const DeleteUserDialog: React.FC<DeleteDialogProps> = ({ dialogOpen, userToDelete, handleClose, handleSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const handleDelete = async () => {
        if (!userToDelete) return;        
        setLoading(true);
        const { data, code } = await UserDeleteService(userToDelete.id);
        if (code === 200 && data) {
            handleSuccess();
            setLoading(false);
            handleClose();
            dispatch(showSnackbar({ message: 'User Deleted Successfully', type: 'success', open: true }));
        }else{
            dispatch(showSnackbar({ message: data || 'Something went wrong. Please try again later.', type: 'error', open: true }));
        }
    };
    return (
        <Dialog fullWidth maxWidth="xs" open={dialogOpen} onClose={handleClose}>
            <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff', borderBottom: '1px solid #ccc', p: '10px 25px' }} >
                {"Confirm Delete"}
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Are you sure you want to delete the user "{userToDelete?.name}"?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="primary" variant="contained" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserDialog;
