import ClientsAxios from '../client-axios';
import { LoginRequest } from '../dto/request';
import { LoginResponse, ResponseEntity } from '../dto/response';

const { API_SERVER } = ClientsAxios;


export const loginService = async (payload: LoginRequest) => {
    const responseEntity = new ResponseEntity<LoginResponse>()
    try {
        const response = await API_SERVER.post('login', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.StatusCode || 500, error.Message, response.data && response.data.body ? response.data.body : {})
    }
    return responseEntity
}


export const logoutService = async (payload:any) => {
  const responseEntity = new ResponseEntity()
  try {
      const response = await API_SERVER.post('logout', payload)
      responseEntity.success(response.data.body, 200, response.data.message)
  } catch (error: any) {
      const response = error.response || {};
      responseEntity.error(response.StatusCode || 500, error.Message, response.data && response.data.body ? response.data.body : {})
  }
  return responseEntity
}
