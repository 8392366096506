import { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography, TableContainer, Table, TableRow, TableCell, TableBody, TablePagination, Paper, CircularProgress, Grid, Box, IconButton, FormControl, TextField, Autocomplete, createFilterOptions, FormHelperText } from '@mui/material';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { RootState } from '../../../redux/store';
import { FilterListService } from '../../../utils/services/filter.service';
import { StateListService } from '../../../utils/services/state.service';
import { BulkUploadService } from '../../../utils/services/project.service';
import FileUploadIcon from '@mui/icons-material/FileUpload';
interface BulkUploadDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
}
interface StateOption {
    id: number;
    name: string;
}
const filterOptions = createFilterOptions<StateOption>({
    matchFrom: 'start',
    stringify: (option) => option.name,
});
interface BulkUploadFormValues {
    state:string,
    company:string,
    region:string,
    district:string,
    state_id:string,
    company_id:string,
    district_id:string,
    file: File | null;
}
const BulkUplaodDialog: React.FC<BulkUploadDialogProps> = ({ dialogOpen, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [states, setStates] = useState<any>([]);
    const [companies, setCompanies] = useState<any>([]);
    const [regions, setRegions] = useState<any>([]);
    const [districts, setDistricts] = useState<any>([]);
    const userMapping = useSelector((state: RootState) => state.authUser.user_mapping);
    const userDetails = useSelector((state: RootState) => state.authUser.user);
    const initialRegion = userMapping?.[0]?.region || '';
    const initialDistrict = userMapping?.[0]?.district || '';
    const initialCompany = companies.find((company: { value: number }) => company.value === userDetails?.company_id)?.label || '';   
    const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';
    const BulkUploadFormik = useFormik<BulkUploadFormValues>({
        initialValues: {
            state:initialState || '',
            company: initialCompany || '',            
            region:initialRegion || '',
            district:initialDistrict || '',
            state_id:'',
            company_id:'',
            district_id:'',
            file: null,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            state: Yup.string().required('Please select a state'),
            company: Yup.string().required('Please select a company'),
            region: Yup.string().required('Please select a region'),
            district: Yup.string().required('Please select a district'),
            file: Yup.string().trim().required("Please upload file"),
        }),
        onSubmit: async (values, {resetForm}) => {
            setLoading(true);
            const stateId = states.find((state: { name: string; }) => state.name === values.state)?.id;
            const companyId = companies.find((company: { label: string; }) => company.label === values.company)?.value;
            const regionId = regions.find((region: { label: string; }) => region.label === values.region)?.value;
            const districtId = districts.find((district: { label: string; }) => district.label === values.district)?.value;
            const formData = new FormData();  
            formData.append('state_id', stateId);
            formData.append('company_id', companyId);
            formData.append('region_id', regionId);
            formData.append('district_id', districtId);
            if (values.file) {
                formData.append('file', values.file);
            }
            const { data, code } = await BulkUploadService(formData);
            if (code === 200) {
                resetForm();
                dispatch(showSnackbar({ message: 'Documents uploaded successfully', type: 'success', open: true }));
                handleClose()
            } else {
                dispatch(showSnackbar({ message: data || 'Unknown error occurred. Please try again.', type: 'error', open: true }));
            }
            setLoading(false);
                 
        },
    });
    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };
    const getDropdownList = async (type: string, id?: number) => {
        setLoading(true);
        const payload = {
            type: type,
            id: id,
        };
        const { data, code } = await FilterListService(payload);
        if (data && code === 200) {
            if (type === 'state') {
                setCompanies(data.data);
            } else if (type === 'company') {
                setRegions(data.data);
            } else if (type === 'region') {
                setDistricts(data.data);
            } 
        }
        setLoading(false);
    };
    useEffect(() => {
        if (userDetails?.state_id) {
            getDropdownList('state', userDetails.state_id);
        }
    }, [userDetails?.state_id]);
    useEffect(() => {
        if (userDetails?.company_id) {
            getDropdownList('company', userDetails.company_id);
        }
    }, [userDetails?.company_id]);
    useEffect(() => {
        if (userMapping && userMapping.length > 0) {
            const regionId = userMapping[0].region_id;
            if (regionId) {
                getDropdownList('region', regionId);
                const region = regions.find((r: { value: number }) => r.value === regionId);
                if (region) {
                    BulkUploadFormik.setFieldValue('region', region.label);
                }
            }
        } else {
            const regionId = regions.find((region: { label: string }) => region.label === BulkUploadFormik.values.region)?.value;
            if (regionId) {
                getDropdownList('region', regionId);
            }
        }
    }, [BulkUploadFormik.values.region, userMapping]);
    useEffect(() => {
        if (userMapping && userMapping.length > 0) {
            const regionId = userMapping[0].region_id;
            if (regionId) {
                getDropdownList('region', regionId);
                const region = regions.find((r: { value: number }) => r.value === regionId);
                if (region) {
                    BulkUploadFormik.setFieldValue('region', region.label);
                }
            }
        } else {
            const regionId = regions.find((region: { label: string }) => region.label === BulkUploadFormik.values.region)?.value;
            if (regionId) {
                getDropdownList('region', regionId);
            }
        }
    }, [BulkUploadFormik.values.region, userMapping]);

    useEffect(() => {
        if (userMapping && userMapping.length > 0) {
            const districtId = userMapping[0].district_id;
            if (districtId) {
                getDropdownList('district', districtId);
                const district = districts.find((r: { value: number }) => r.value === districtId);
                if (district) {
                    BulkUploadFormik.setFieldValue('district', district.label);
                }
            }
        } else {
            const districtId = districts.find((district: { label: string }) => district.label === BulkUploadFormik.values.district)?.value;
            if (districtId) {
                getDropdownList('district', districtId);
            }
        }
    }, [BulkUploadFormik.values.district, userMapping]);

    const handleClose = () => {
        BulkUploadFormik.resetForm();
        handleCloseDialog();
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            BulkUploadFormik.setFieldValue('file', event.currentTarget.files[0]);
        }
    };
    const handleGenerateExcel = async () => {
        const data = await BulkUploadService('region_id:10');
        if (data && data.data) {
            const fileUrl = data.data.filePath;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            console.error('Generate failed: no file path received');
        }       
    };
    return (
        <>
            <Dialog fullWidth  maxWidth="sm" open={dialogOpen} onClose={handleClose}  PaperProps={{ component: 'form', onSubmit: BulkUploadFormik.handleSubmit}}>
                <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff',
                        borderBottom: '1px solid #ccc', p: '10px 25px', mb:2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} md={10}>
                                <Typography variant="h4">Bulk Upload</Typography>
                            </Grid>
                            <Grid item xs={12} md={2} textAlign={"end"}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size="small"
                                    value={states.find((state: { name: string; }) => state.name === BulkUploadFormik.values.state) || null}
                                    onChange={(event, newValue: StateOption | null) => {
                                        BulkUploadFormik.setFieldValue('state', newValue ? newValue.name : '');
                                        BulkUploadFormik.setFieldValue('company', ''); 
                                        BulkUploadFormik.setFieldValue('region', '');
                                        BulkUploadFormik.setFieldValue('district', '');
                                        setCompanies([]);
                                        setRegions([]);
                                        setDistricts([]);
                                        const stateId = states.find((state: { name: any; }) => state.name === newValue?.name)?.id;
                                        if (stateId) {
                                            getDropdownList('state', stateId);
                                        }
                                    }}
                                    inputValue={BulkUploadFormik.values.state}
                                    onInputChange={(event, newInputValue) => {
                                        BulkUploadFormik.setFieldValue('state', newInputValue);
                                    }}
                                    options={states}
                                    getOptionLabel={(option: StateOption) => option.name}
                                    filterOptions={filterOptions}
                                    key={states.map((state: { id: any; }) => state.id).join(',')}
                                    onBlur={() => {
                                        const validState = states.find((state: { name: any; }) => state.name === BulkUploadFormik.values.state);
                                        if (!validState) {
                                          BulkUploadFormik.setFieldValue(
                                            'state', ''
                                          );
                                        }
                                      }}
                                    disableClearable
                                    freeSolo={false} 
                                    disabled={initialState}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="States*"
                                        error={BulkUploadFormik.touched.state && Boolean(BulkUploadFormik.errors.state)}
                                        helperText={BulkUploadFormik.touched.state && typeof BulkUploadFormik.errors.state === 'string' ? BulkUploadFormik.errors.state : ''}
                                    />
                                    )}
                                />                                  
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size="small"
                                    value={BulkUploadFormik.values.company}
                                    onChange={(event: any, newValue: string | null) => {
                                        if (newValue && companies.some((company: { label: string; }) => company.label === newValue)) {
                                            BulkUploadFormik.setFieldValue('company', newValue);
                                            BulkUploadFormik.setFieldValue('region', '');
                                            BulkUploadFormik.setFieldValue('district', '');
                                            setRegions([]);
                                            setDistricts([])
                                            const companyId = companies.find((company: { label: string; }) => company.label === newValue)?.value;
                                            if (companyId) {
                                                getDropdownList('company', companyId);
                                            }
                                        } else {
                                            BulkUploadFormik.setFieldValue('company', '');
                                        }
                                    }}
                                    inputValue={BulkUploadFormik.values.company}
                                    onInputChange={(event, newInputValue) => {
                                        BulkUploadFormik.setFieldValue('company', newInputValue);
                                    }}
                                    onBlur={() => {
                                        const validCompany = companies.some((company: { label: any; }) => company.label === BulkUploadFormik.values.company );
                                        if (!validCompany) {
                                          BulkUploadFormik.setFieldValue(
                                            'company', ''
                                          );
                                        }
                                      }}
                                    disabled={initialCompany}
                                    options={companies.map((company: { label: any; }) => company.label)}
                                    disableClearable
                                    freeSolo={false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Company*"
                                            error={BulkUploadFormik.touched.company && Boolean(BulkUploadFormik.errors.company)}
                                            helperText={BulkUploadFormik.touched.company && typeof BulkUploadFormik.errors.company === 'string' ? BulkUploadFormik.errors.company : ''}
                                        />
                                    )}
                                /> 
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={BulkUploadFormik.values.region}
                                    onChange={(event: any, newValue: string | null) => {
                                        BulkUploadFormik.setFieldValue('region', newValue);
                                        BulkUploadFormik.setFieldValue('district', '');
                                            setDistricts([])
                                        const regionId = regions.find((region: { label: string | null; }) => region.label === newValue)?.value;
                                        if (regionId) {
                                            getDropdownList('region', regionId);
                                        }
                                    }}
                                    inputValue={BulkUploadFormik.values.region}
                                    onInputChange={(event, newInputValue) => {
                                        BulkUploadFormik.setFieldValue('region', newInputValue);
                                    }}
                                    onBlur={() => {
                                        const validRegion = regions.some((region: { label: any; }) => region.label === BulkUploadFormik.values.region );
                                        if (!validRegion) {
                                          BulkUploadFormik.setFieldValue(
                                            'region', ''
                                          );
                                        }
                                      }}
                                    disableClearable
                                    freeSolo={false}
                                    options={regions.map((region: { label: any; }) => region.label)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Region*"
                                            error={BulkUploadFormik.touched.region && Boolean(BulkUploadFormik.errors.region)}
                                            helperText={BulkUploadFormik.touched.region && typeof BulkUploadFormik.errors.region === 'string' ? BulkUploadFormik.errors.region : ''}
                                        />
                                    )}
                                />                               
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={BulkUploadFormik.values.district}
                                    onChange={(event: any, newValue: string | null) => {
                                        BulkUploadFormik.setFieldValue('district', newValue);
                                        const districtId = districts.find((district: { label: string | null; }) => district.label === newValue)?.value;
                                        if (districtId) {
                                            getDropdownList('district', districtId);
                                        }
                                    }}
                                    inputValue={BulkUploadFormik.values.district}
                                    onInputChange={(event, newInputValue) => {
                                        BulkUploadFormik.setFieldValue('district', newInputValue);
                                    }}
                                    disableClearable
                                    freeSolo={false}
                                    options={districts.map((district: { label: any; }) => district.label)}
                                    onBlur={() => {
                                        const validDistrict = districts.some((district: { label: any; }) => district.label === BulkUploadFormik.values.district );
                                        if (!validDistrict) {
                                          BulkUploadFormik.setFieldValue(
                                            'district', ''
                                          );
                                        }
                                      }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="District*"
                                            error={BulkUploadFormik.touched.district && Boolean(BulkUploadFormik.errors.district)}
                                            helperText={BulkUploadFormik.touched.district && typeof BulkUploadFormik.errors.district === 'string' ? BulkUploadFormik.errors.district : ''}
                                        />
                                    )}
                                />                               
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Box display="block" alignItems="center">
                                    <Box position="relative">
                                        <input
                                            type="file"
                                            id="file-upload"
                                            name="file"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        <label htmlFor="file-upload">
                                            <Button variant="outlined" color="inherit" component="span" fullWidth>
                                                <FileUploadIcon sx={{ mr: 2 }} /> Upload Documents
                                            </Button>
                                        </label>
                                    </Box>
                                    {BulkUploadFormik.values.file && (
                                        <Typography variant="body2" fontSize={'11px'} mt={1}>
                                            {BulkUploadFormik.values.file.name}
                                        </Typography>
                                    )}
                                    {BulkUploadFormik.touched.file && BulkUploadFormik.errors.file && (
                                        <FormHelperText sx={{color:'#d32f2f'}}>{BulkUploadFormik.errors.file}</FormHelperText>
                                    )}
                                </Box>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <Button color='info' variant='outlined' fullWidth size="medium" sx={{p:'5px'}}  onClick={handleGenerateExcel}
                                disabled={loading}><InsertDriveFileIcon sx={{ mr: 2 }} /> Generate Excel</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary"> Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>                 
                </DialogActions>
            </Dialog>
        </>
    )
}
export default BulkUplaodDialog