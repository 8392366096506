import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton,  CircularProgress,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MenuCreateService, MenuListService } from '../../../../utils/services/menu.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
interface AddMenuDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
    currentMenu:{ id: string; name: string, menu:string, url:string, icon:string, menu_name:string, parent_id:string } | null;
}

const AddMenuDialog: React.FC<AddMenuDialogProps> = ({ dialogOpen, handleCloseDialog, currentMenu, handleSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [menus, setMenus] = useState<any>([]);
    const isEditing = currentMenu !== null;
    const AddMenuFormik = useFormik({
        initialValues: {
            menu:currentMenu?.name || '',
            name:currentMenu?.name || '',
            url:currentMenu?.url || '',
            icon:currentMenu?.icon || '',
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            menu: Yup.string().required('Please select a menu'),
            name: Yup.string().trim().required("Please enter menu"),
        }),
        onSubmit: async (values, {resetForm}) => {
            const menuId = menus.find((menu: { name: string; }) => menu.name === values.menu)?.id;
            let serviceResponse;
            if (isEditing) {
                const { data, code } = await MenuCreateService({
                    id: currentMenu.id,
                    parent_id: menuId || currentMenu.parent_id,
                    name: values.name,
                    url:values.url,
                    icon:values.icon
                });
                serviceResponse = { data, code };
            } else {
                const { data, code } = await MenuCreateService({
                    parent_id: menuId,
                    name: values.name,
                    url:values.url,
                    icon:values.icon
                });
                serviceResponse = { data, code };
            }
            
            if (serviceResponse.data && serviceResponse.code === 200) {
                resetForm();
                handleCloseDialog();
                handleSuccess();
                setLoading(false);
                const message = isEditing ? 'Menu details updated' : 'Menu created successfully';
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } 
        },
    });
    useEffect(() => {        
        fetchMenus();
    }, []);
    const fetchMenus = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        const { data, code } = await MenuListService(payload);
        if (code === 200 && data) {
            setMenus(data.data);
        }
        setLoading(false);
    };
    const handleClose = () => {
        AddMenuFormik.resetForm();
        handleCloseDialog();
    };

    return (
        <>
            <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: AddMenuFormik.handleSubmit }} >
               <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position:'sticky', top:'0', left:'0', right:'0', zIndex:'99', backgroundColor:'#fff', borderBottom:'1px solid #ccc', p:'10px 25px'  }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10} >
                            <Typography variant="h4">{isEditing ? 'Edit Menu' : 'Add Menu'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={() => { handleClose() }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Autocomplete
                                    size='small'
                                    value={AddMenuFormik.values.menu}
                                    onChange={(event: any, newValue: string | null) => {
                                        AddMenuFormik.setFieldValue('menu', newValue);
                                        const menuId = menus.find((menu: { name: string; }) => menu.name === newValue)?.id;
                                       
                                    }}
                                    inputValue={AddMenuFormik.values.menu}
                                    onInputChange={(event, newInputValue) => {
                                        if (menus.some((menu: { name: string; }) => menu.name === newInputValue)) {
                                            AddMenuFormik.setFieldValue('menu', newInputValue);
                                        }
                                    }}
                                    options={menus.map((menu: any) => menu.name)}
                                    disableClearable
                                    freeSolo={false}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            label="Menus*"
                                            error={AddMenuFormik.touched.menu && Boolean(AddMenuFormik.errors.menu)}
                                            helperText={AddMenuFormik.touched.menu && AddMenuFormik.errors.menu}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                 autoComplete="off"
                                fullWidth
                                label="Name*"
                                variant="outlined"
                                size="small"
                                {...AddMenuFormik.getFieldProps('name')}
                                name="name"
                                error={AddMenuFormik.touched.name && Boolean(AddMenuFormik.errors.name)}
                                helperText={AddMenuFormik.touched.name && AddMenuFormik.errors.name}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                 autoComplete="off"
                                    fullWidth
                                    label="URL"
                                    variant="outlined"
                                    size="small"
                                    {...AddMenuFormik.getFieldProps('url')}
                                    name="url"
                                    error={AddMenuFormik.touched.url && Boolean(AddMenuFormik.errors.url)}
                                    helperText={AddMenuFormik.touched.url && AddMenuFormik.errors.url}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                 autoComplete="off"
                                    fullWidth
                                    label="Icon"
                                    variant="outlined"
                                    size="small"
                                    {...AddMenuFormik.getFieldProps('icon')}
                                    name="icon"
                                    error={AddMenuFormik.touched.icon && Boolean(AddMenuFormik.errors.icon)}
                                    helperText={AddMenuFormik.touched.icon && AddMenuFormik.errors.icon}
                                />
                            </FormControl>
                        </Grid>
                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddMenuDialog;