import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton,  CircularProgress,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PermissionCreateService } from '../../../../utils/services/permission.service';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
interface AddPermissionDialogProps {
    dialogOpen: boolean;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
    currentPermission: { id: string; permission_name: string, menu_id: string } | null;
    menuId: number;
}
const AddPermissionDialog: React.FC<AddPermissionDialogProps> = ({ dialogOpen, handleCloseDialog, currentPermission, handleSuccess, menuId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const isEditing = currentPermission !== null;
    const AddPermissionFormik = useFormik({
        initialValues: {
            menu_id: currentPermission?.menu_id || menuId,
            permission_name:currentPermission?.permission_name || '',
        },
        enableReinitialize: true,  
        validationSchema: Yup.object({
            permission_name: Yup.string().trim().required("Please enter Permission"),
        }),
        onSubmit: async (values, {resetForm}) => {
            let serviceResponse;
            if (isEditing) {
                const { data, code } = await PermissionCreateService({ ...values, id: currentPermission.id });
                serviceResponse = { data, code };
            } else {
                const { data, code } = await PermissionCreateService(values);
                serviceResponse = { data, code };
            }
            
            if (serviceResponse.data && serviceResponse.code === 200) {
                resetForm()
                handleCloseDialog();
                handleSuccess();
                setLoading(false);
                const message = isEditing ? 'Permission details updated' : 'Permission created successfully';
                dispatch(showSnackbar({ message, type: 'success', open: true }));
            } else {
                const errorMessage = serviceResponse.data.message || 'An error occurred';
                dispatch(showSnackbar({ message: errorMessage, type: 'error', open: true }));
            }  
        },
    });
    const handleClose = () => {
        AddPermissionFormik.resetForm();
        handleCloseDialog();
    };
    return (
        <>
            <Dialog fullWidth maxWidth='sm' open={dialogOpen} onClose={handleClose} scroll='paper' PaperProps={{ component: 'form', onSubmit: AddPermissionFormik.handleSubmit }} >
               <DialogTitle component='div' sx={{ fontWeight: 'bold', fontSize: '22px', color: 'grey.800', position:'sticky', top:'0', left:'0', right:'0', zIndex:'99', backgroundColor:'#fff', borderBottom:'1px solid #ccc', p:'10px 25px'  }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={10} >
                            <Typography variant="h4">{isEditing ? 'Edit Permission' : 'Add Permission'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign={"end"}>
                            <IconButton onClick={() => { handleClose() }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField
                                    autoComplete="off"
                                    fullWidth
                                    label="Permission*"
                                    variant="outlined"
                                    size="small"
                                    {...AddPermissionFormik.getFieldProps('permission_name')}
                                    name="permission_name"
                                    error={AddPermissionFormik.touched.permission_name && Boolean(AddPermissionFormik.errors.permission_name)}
                                    helperText={AddPermissionFormik.touched.permission_name && AddPermissionFormik.errors.permission_name}
                                />
                            </FormControl>
                        </Grid>                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddPermissionDialog;