import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Autocomplete, CardContent, Card, Select, ListItemText, Checkbox, MenuItem, SelectChangeEvent, Chip, InputLabel } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FilterDataCreateService, LetterDetailsService } from '../../../utils/services/manage.service';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
interface FilterDataProps {
    states: any[];
    companies: any[];
    regions: any[];
    districts: any[];
    talukas: any[];
    villages: any[];
    setSelectedVillages: (villages: string[]) => void;
    onDropdownChange: (type: string, id?: number, formikInstance?: any) => Promise<void>;
    setResponseData: React.Dispatch<React.SetStateAction<any>>;
    onFilterSubmit: () => void;
    editData?: {
        company: string;
        state: string;
        region: string;
        district: string;
        taluka: string;
        village: string;
    };    
    mode: 'create' | 'edit';
}

const FilterData: React.FC<FilterDataProps> = ({  states, companies, regions, districts, talukas, villages, onDropdownChange, setResponseData,
    onFilterSubmit, editData, mode, setSelectedVillages
}) => {
    const [selectedVillages, setSelectedVillagesState] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [submitFormLoading, setSubmitFormLoading] = useState<boolean>(false);
    const isEditMode = mode === 'edit';
    const userMapping = useSelector((state: RootState) => state.authUser.user_mapping);
    const userDetails = useSelector((state: RootState) => state.authUser.user);    
    const userRoles = useSelector((state: RootState) => state.authUser.roles);
    const initialRegion = userMapping?.[0]?.region || editData?.region || '';
    const initialDistrict = userMapping?.[0]?.district || editData?.district || '';
    const initialCompany = companies.find((company: { value: number }) => company.value === userDetails?.company_id)?.label || '';  
    const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';   
     
    const FilterDataFormik = useFormik({
        initialValues: {
            company: editData?.company || initialCompany,
            state: editData?.state || initialState,
            region: editData?.region || initialRegion,
            district: editData?.district || initialDistrict,
            taluka: editData?.taluka || '',
            village: editData?.village ? editData.village.split(',').map(v => v.trim()) : [],
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            company: Yup.string().trim().required("Please select company"),
            state: Yup.string().trim().required("Please select a state"),
            region: Yup.string().trim().required("Please select region"),
            district: Yup.string().trim().required("Please select district"),
            taluka: Yup.string().trim().required("Please select taluka"),
            village: Yup.array().min(1, "Please select at least one village").required("Please select village")
        }),
        onSubmit: async (values) => {
            setSelectedVillages(values.village);
            setFormSubmitted(true);
            onFilterSubmit();
            const districtId = districts.find((district: { label: string; }) => district.label === values.district)?.value;
            setLoading(true);            
            const { data, code } = await LetterDetailsService({
                district_id: districtId,
            });            
            setLoading(false);
            if (data && code === 200) {
                setResponseData(data); 
                setFormSubmitted(true); 
                setSubmitFormLoading(false);                
                onFilterSubmit();
            }
        },
    });
  
    useEffect(() => {
        if (editData?.village) {
            const initialVillages = editData.village.split(',');
            FilterDataFormik.setFieldValue('village', initialVillages);
            setSelectedVillagesState(initialVillages);
        }
    }, [editData]);

    const handleVillageRemove = (villageToRemove: string) => {
        const newVillages = selectedVillages.filter(v => v !== villageToRemove);
        FilterDataFormik.setFieldValue('village', newVillages); 
        setSelectedVillages(newVillages);
        setSelectedVillagesState(newVillages); 
    };

    const handleDropdownChange = (
        fieldName: string,
        value:  number | undefined,
        dependentFields: string[] = []
      ) => {
        if (value) {
          onDropdownChange(fieldName, value, FilterDataFormik).then(() => {
            dependentFields.forEach((field) => {
              FilterDataFormik.setFieldValue(field, '');
            });
          });
        }
    };
    useEffect(() => {
        const stateId = states.find((state: { name: string }) => state.name === FilterDataFormik.values.state)?.id;
        handleDropdownChange('state', stateId, ['company', 'region', 'district', 'taluka']);
      }, [FilterDataFormik.values.state]);
      
      useEffect(() => {
        const companyId = companies.find((company: { label: string }) => company.label === FilterDataFormik.values.company)?.value;
        handleDropdownChange('company', companyId, ['region', 'district', 'taluka']);
      }, [FilterDataFormik.values.company]);
      
      useEffect(() => {
        const regionId = regions.find((region: { label: string }) => region.label === FilterDataFormik.values.region)?.value;
        const userRegionId = userMapping?.[0]?.region_id;
        handleDropdownChange('region', userRegionId || regionId, ['district', 'taluka']);
      }, [FilterDataFormik.values.region, userMapping]);
      
      useEffect(() => {
        const districtId = districts.find((district: { label: string }) => district.label === FilterDataFormik.values.district)?.value;
        const userDistrictId = userMapping?.[0]?.district_id;
        handleDropdownChange('district', userDistrictId || districtId, ['taluka']);
      }, [FilterDataFormik.values.district, userMapping]);
      
    //   useEffect(() => {
    //     const talukaId = talukas.find((taluka: { label: string }) => taluka.label === FilterDataFormik.values.taluka)?.value;
    //     handleDropdownChange('taluka', talukaId,]);
    //   }, [FilterDataFormik.values.taluka]);
      
    return(
        <>
            <Box component='form' onSubmit={FilterDataFormik.handleSubmit}>
                <Grid container columnSpacing={3}>                
                    <Grid item xs={12} lg={3} sx={{ mt: 2 }}>                    
                        <FormControl fullWidth required>                        
                            <Autocomplete
                                size='small'
                                value={FilterDataFormik.values.state}
                                onChange={(event: any, newValue: string | null) => {
                                    if (states.some((state: { name: string }) => state.name === newValue)) {
                                        FilterDataFormik.setFieldValue('state', newValue);
                                        const stateId = states.find((state: { name: string }) => state.name === newValue)?.id;
                                        if (stateId) {
                                            onDropdownChange('state', stateId);
                                            FilterDataFormik.setFieldValue('company', '');
                                            FilterDataFormik.setFieldValue('region', '');
                                            FilterDataFormik.setFieldValue('district', '');
                                            FilterDataFormik.setFieldValue('taluka', '');
                                            FilterDataFormik.setFieldValue('village', []);
                                        }
                                    } else {
                                        FilterDataFormik.setFieldValue('state', '');
                                    }
                                }}
                                inputValue={FilterDataFormik.values.state}
                                onInputChange={(event, newInputValue) => {
                                    FilterDataFormik.setFieldValue('state', newInputValue);
                                }}
                                onBlur={() => {
                                    const isValidState = states.some((state: { name: string }) => state.name === FilterDataFormik.values.state);
                                    if (!isValidState) {
                                        FilterDataFormik.setFieldValue('state', '');
                                    }
                                }}
                                disabled={initialState}
                                disableClearable
                                freeSolo={false}
                                options={states.map((state: any) => state.name)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="States*"
                                        error={FilterDataFormik.touched.state && Boolean(FilterDataFormik.errors.state)}
                                        helperText={FilterDataFormik.touched.state && typeof FilterDataFormik.errors.state === 'string' ? FilterDataFormik.errors.state : ''}
                                        className={formSubmitted || isEditMode ? 'custom-disabled' : ''}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                        <Autocomplete
                            size='small'
                            value={FilterDataFormik.values.company}
                            onChange={(event: any, newValue: string | null) => {
                                if (companies.some((company) => company.label === newValue)) {
                                    FilterDataFormik.setFieldValue('company', newValue);
                                    const companyId = companies.find((company) => company.label === newValue)?.value;
                                    if (companyId) {
                                        onDropdownChange('company', companyId);
                                        FilterDataFormik.setFieldValue('region', '');
                                        FilterDataFormik.setFieldValue('district', '');
                                        FilterDataFormik.setFieldValue('taluka', '');
                                        FilterDataFormik.setFieldValue('village', []);
                                    }
                                } else {
                                    FilterDataFormik.setFieldValue('company', '');
                                }
                            }}
                            inputValue={FilterDataFormik.values.company}
                            onInputChange={(event, newInputValue) => {
                                FilterDataFormik.setFieldValue('company', newInputValue);
                            }}
                            onBlur={() => {
                                const isValidCompany = companies.some((company) => company.label === FilterDataFormik.values.company);
                                if (!isValidCompany) {
                                    FilterDataFormik.setFieldValue('company', '');
                                }
                            }}
                            disableClearable
                            freeSolo={false} 
                            disabled={initialCompany}
                            options={companies.map((company) => company.label)}
                            renderInput={(params) => (
                                <TextField {...params} label="Company*"
                                    error={FilterDataFormik.touched.company && Boolean(FilterDataFormik.errors.company)}
                                    helperText={FilterDataFormik.touched.company && typeof FilterDataFormik.errors.company === 'string' ? FilterDataFormik.errors.company : ''}
                                    className={formSubmitted || isEditMode ? 'custom-disabled' : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                        <FormControl fullWidth required>
                            <Autocomplete
                                size='small'
                                value={FilterDataFormik.values.region}
                                onChange={(event: any, newValue: string | null) => {
                                    if (regions.some((region) => region.label === newValue)) {
                                        FilterDataFormik.setFieldValue('region', newValue);
                                        const regionId = regions.find((region) => region.label === newValue)?.value;
                                        if (regionId) {
                                            onDropdownChange('region', regionId);
                                            FilterDataFormik.setFieldValue('district', '');
                                            FilterDataFormik.setFieldValue('taluka', '');
                                            FilterDataFormik.setFieldValue('village', []);
                                        }
                                    } else {
                                        FilterDataFormik.setFieldValue('region', '');
                                    }
                                }}
                                inputValue={FilterDataFormik.values.region}
                                onInputChange={(event, newInputValue) => {
                                    FilterDataFormik.setFieldValue('region', newInputValue);
                                }}
                                onBlur={() => {
                                    const isValidRegion = regions.some((region) => region.label === FilterDataFormik.values.region);
                                    if (!isValidRegion) {
                                        FilterDataFormik.setFieldValue('region', '');
                                    }
                                }}
                                disableClearable
                                freeSolo={false} 
                                options={regions.map((region) => region.label)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Region*"
                                        error={FilterDataFormik.touched.region && Boolean(FilterDataFormik.errors.region)}
                                        helperText={FilterDataFormik.touched.region && typeof FilterDataFormik.errors.region === 'string' ? FilterDataFormik.errors.region : ''}
                                        className={formSubmitted || isEditMode ? 'custom-disabled' : ''}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                        <FormControl fullWidth required>
                            <Autocomplete
                                size='small'
                                value={FilterDataFormik.values.district}
                                onChange={(event: any, newValue: string | null) => {
                                    if (districts.some((district) => district.label === newValue)) {
                                        FilterDataFormik.setFieldValue('district', newValue);
                                        const districtId = districts.find((district) => district.label === newValue)?.value;
                                        if (districtId) {
                                            onDropdownChange('district', districtId);
                                            FilterDataFormik.setFieldValue('taluka', '');
                                            FilterDataFormik.setFieldValue('village', []);
                                        }
                                    } else {
                                        FilterDataFormik.setFieldValue('district', '');
                                    }
                                }}
                                inputValue={FilterDataFormik.values.district}
                                onInputChange={(event, newInputValue) => {
                                    FilterDataFormik.setFieldValue('district', newInputValue);
                                }}
                                onBlur={() => {
                                    const isValidRegion = districts.some((district) => district.label === FilterDataFormik.values.district);
                                    if (!isValidRegion) {
                                        FilterDataFormik.setFieldValue('district', '');
                                    }
                                }}
                                disableClearable
                                freeSolo={false} 
                                options={districts.map((district) => district.label)}
                                renderInput={(params) => (
                                    <TextField {...params} label="District*"
                                        error={FilterDataFormik.touched.region && Boolean(FilterDataFormik.errors.district)}
                                        helperText={FilterDataFormik.touched.district && typeof FilterDataFormik.errors.district === 'string' ? FilterDataFormik.errors.district : ''}
                                        className={formSubmitted || isEditMode ? 'custom-disabled' : ''}
                                    />
                                )}
                            />
                           
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{ mt: 2 }}>                        
                        <FormControl fullWidth required>
                            <Autocomplete
                                size='small'
                                value={FilterDataFormik.values.taluka}
                                onChange={(event: any, newValue: string | null) => {
                                    if (talukas.some((taluka) => taluka.label === newValue)) {
                                        FilterDataFormik.setFieldValue('taluka', newValue);
                                        const talukaId = talukas.find((taluka) => taluka.label === newValue)?.value;
                                        if (talukaId) {
                                            onDropdownChange('taluka', talukaId);
                                            FilterDataFormik.setFieldValue('village', []);
                                        }
                                    } else {
                                        FilterDataFormik.setFieldValue('taluka', '');
                                    }
                                }}
                                inputValue={FilterDataFormik.values.taluka}
                                onInputChange={(event, newInputValue) => {
                                    FilterDataFormik.setFieldValue('taluka', newInputValue);
                                }}
                                onBlur={() => {
                                    const isValidRegion = talukas.some((taluka) => taluka.label === FilterDataFormik.values.taluka);
                                    if (!isValidRegion) {
                                        FilterDataFormik.setFieldValue('taluka', '');
                                    }
                                }}
                                disableClearable
                                freeSolo={false} 
                                options={talukas.map((taluka) => taluka.label)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Taluka*"
                                        error={FilterDataFormik.touched.region && Boolean(FilterDataFormik.errors.taluka)}
                                        helperText={FilterDataFormik.touched.taluka && typeof FilterDataFormik.errors.taluka === 'string' ? FilterDataFormik.errors.taluka : ''}
                                        className={formSubmitted || isEditMode ? 'custom-disabled' : ''}
                                    />
                                )}
                            />
                           
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} lg={3} sx={{ mt: 2 }}>                        
                        <FormControl fullWidth required>
                            <Select
                                multiple
                                className={formSubmitted || isEditMode ? 'custom-disabled' : ''}
                                value={FilterDataFormik.values.village}
                                onChange={(event) => {
                                    const selectedVillages = event.target.value as string[];
                                    FilterDataFormik.setFieldValue('village', selectedVillages);
                                    setSelectedVillages(selectedVillages);
                                }}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {(selected as string[]).map((village) => (
                                            <Chip
                                                key={village}
                                                label={villages.find((v) => v.value === village)?.label || village}
                                                onDelete={() => {
                                                    const newVillages = (selected as string[]).filter(v => v !== village);
                                                    FilterDataFormik.setFieldValue('village', newVillages);
                                                    setSelectedVillages(newVillages); 
                                                }}
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {villages.map((village) => (
                                    <MenuItem key={village.value} value={village.value}>
                                        {village.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {FilterDataFormik.touched.village && FilterDataFormik.errors.village && (
                                <div style={{ color: 'red' }}>{FilterDataFormik.errors.village}</div>
                            )}
                        </FormControl>

                    </Grid> */}
                    <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                        <FormControl fullWidth required >
                            <InputLabel sx={{ top:'-8px'}}>Project</InputLabel>
                            <Select 
                                multiple
                                className={formSubmitted || isEditMode ? 'custom-disabled' : ''}
                                value={FilterDataFormik.values.village} 
                                onChange={(event) => {
                                    const selectedVillages = event.target.value as string[];
                                    FilterDataFormik.setFieldValue('village', selectedVillages);
                                    setSelectedVillages(selectedVillages); 
                                    setSelectedVillagesState(selectedVillages); 
                                }} 
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {(selected as string[]).map((village) => (
                                            <Chip
                                                key={village}
                                                label={villages.find((v) => v.value === village)?.label || village}
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onDelete={() => handleVillageRemove(village)} 
                                            />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            display: 'block',  
                                            padding: 0,   
                                            maxHeight: 200,
                                            overflow: 'auto', 
                                        },
                                    },
                                    anchorOrigin: {
                                        vertical: 'bottom', 
                                        horizontal: 'left',
                                      },
                                      transformOrigin: {
                                        vertical: 'top', 
                                        horizontal: 'left',
                                      },
                                }}
                            >
                                {villages.map((village) => (
                                    <MenuItem key={village.value} value={village.value}>
                                        {village.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {FilterDataFormik.touched.village && FilterDataFormik.errors.village && (
                                <div style={{ color: 'red' }}>{FilterDataFormik.errors.village}</div>
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                        <Button sx={{float:'right', m:1}} variant='contained' type="submit" disabled={loading || formSubmitted || isEditMode} color='info'>Submit</Button> 
                    </Grid>  
                </Grid>
            </Box>
        </>
    )
}
export default FilterData