import { Navigate, Route, Routes } from 'react-router-dom';
import AdminLayout from '../layout/AdminLayout';
import DashboardPage from './dashboard';
import UserMaster from './admin/master/user';
import RoleMaster from './admin/master/role';
import CompanyMaster from './admin/master/company';
import TypeMaster from './admin/master/type';
import StatusMaster from './admin/master/status';
import CategoryMaster from './admin/master/category';
import StateMaster from './admin/master/state';
import RegionMaster from './admin/master/region';
import DistrictMaster from './admin/master/district';
import ProjectMaster from './admin/master/project';
import TalukaMaster from './admin/master/taluka';
import MenuMaster from './admin/master/menu';
import PermissionMaster from './admin/master/permission';
const MasterRoute = () => {
    return (
        <>
            <Routes>
                
                <Route path='/user' element={<AdminLayout><UserMaster /></AdminLayout>}></Route>
                <Route path='/role' element={<AdminLayout><RoleMaster /></AdminLayout>}></Route>
                <Route path='/company' element={<AdminLayout><CompanyMaster /></AdminLayout>}></Route>
                <Route path='/type' element={<AdminLayout><TypeMaster /></AdminLayout>}></Route>
                <Route path='/status' element={<AdminLayout><StatusMaster /></AdminLayout>}></Route>
                <Route path='/category' element={<AdminLayout><CategoryMaster /></AdminLayout>}></Route>
                <Route path='/state' element={<AdminLayout><StateMaster /></AdminLayout>}></Route>
                <Route path='/region' element={<AdminLayout><RegionMaster /></AdminLayout>}></Route>
                <Route path='/district' element={<AdminLayout><DistrictMaster /></AdminLayout>}></Route>
                <Route path='/taluka' element={<AdminLayout><TalukaMaster /></AdminLayout>}></Route>
                <Route path='/project' element={<AdminLayout><ProjectMaster /></AdminLayout>}></Route>
                <Route path='/menu' element={<AdminLayout><MenuMaster /></AdminLayout>}></Route>
                <Route path='/permission' element={<AdminLayout><PermissionMaster /></AdminLayout>}></Route>
            </Routes>
        </>
    )
}

export default MasterRoute;