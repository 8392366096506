import { styled, TableCell } from "@mui/material";

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const StyledTableCell = styled(TableCell)({
    // borderWidth: 0.1,
    // borderStyle: 'solid',
    // borderColor: '#ddd',
    padding:'8px'
});