import ClientsAxios from '../client-axios';
import { ResponseEntity } from '../dto/response';

const { API_SERVER } = ClientsAxios;


export const RoleCreateService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('roles/store', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}

export const RoleUpdateService = async (id: string, payload: any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('role/update', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}


export const RoleListService = async (payload: any) => {
    const responseEntity = new ResponseEntity<any>();
    try {
        const response = await API_SERVER.post('roles/list', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.body ? response.data.body : {});
    }
    return responseEntity;
}
export const RoleDeleteService = async (id: number) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('roles/delete',  { id })
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.statusCode || 500, error.message, response.data?.message ? response.data.message : {})
    }
    return responseEntity
}
export const RoleViewService = async (id: number) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('roles/view',  {id})
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}

export const RoleAssignService = async (payload:any) => {
    const responseEntity = new ResponseEntity<any>()
    try {
        const response = await API_SERVER.post('roles/assign-permissions', payload)
        responseEntity.success(response.data.body, 200, response.data.message)
    } catch (error: any) {
        const response = error.response || {};
        const message = response && response.data && response.data.message ? response.data.message : '';
        const data = response.data && response.data.body ? response.data.body : {};
        responseEntity.error(response.StatusCode || 500, message, data);
    }
    return responseEntity
}