import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import { BlockUnblockService } from '../../../utils/services/manage.service';
import React from 'react';

interface DeleteDialogProps {
    dialogOpen: boolean;
    manageToBlock: any;
    handleClose: () => void;
    handleSuccess: () => void;
    handleBlockUnblock: () => void;
}

const BlockUnblockManageDialog: React.FC<DeleteDialogProps> = ({ dialogOpen, manageToBlock, handleClose, handleSuccess, handleBlockUnblock }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const handleStatus = async () => {
        if (!manageToBlock || !manageToBlock.id) {
            return;
        }
        setLoading(true);
        const payload = {
            letter_id: manageToBlock.id,
            type: manageToBlock.type
        };
        const { data, code } = await BlockUnblockService(payload);
        if (code === 200 && data) {
            handleSuccess();
            handleBlockUnblock();
            setLoading(false);
            handleClose();
            dispatch(showSnackbar({ message: `User ${manageToBlock.type}ed Successfully`, type: 'success', open: true }));
        } else {
            setLoading(false);
            dispatch(showSnackbar({ message: `Failed to ${manageToBlock.type} the user.`, type: 'error', open: true }));
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={dialogOpen}
            onClose={handleClose}
        >
            <DialogTitle
                component="div"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: 'grey.800',
                    position: 'sticky',
                    top: '0',
                    left: '0',
                    right: '0',
                    zIndex: '99',
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ccc',
                    p: '10px 25px'
                }}
            >
                {`Confirm ${manageToBlock?.type.charAt(0).toUpperCase() + manageToBlock?.type.slice(1)}`}
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Are you sure you want to {manageToBlock?.type} the "{manageToBlock?.state_name}"?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleStatus} color="primary" variant="contained" autoFocus disabled={loading}>
                    {loading ? 'Processing...' : 'Yes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BlockUnblockManageDialog;
