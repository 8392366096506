import * as React from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, MenuItem, Menu, Tooltip, Avatar, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAuthUser } from '../../../redux/reducer/authUserSlice';
import { useSettings } from '../../../providers/SettingsProvider';
import FaceIcon from '@mui/icons-material/Face';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { RootState } from '../../../redux/store';
import { logoutService } from '../../../utils/services/auth.service';
import { useState } from 'react';
export default function AdminTopBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { setIsDashboardDrawerOpened } = useSettings();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const userDetails = useSelector((state: RootState) => state.authUser.user);  
  const token = useSelector((state: RootState) => state.authUser.auth_token);  
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const handleLogout = () => {
  //   localStorage.clear();
  //   dispatch(deleteAuthUser());
  //   setTimeout(() => {
  //     navigate('/auth/login');
  //   }, 100)
  // }
  
  const handleLogout = async () => {
    setLoading(true);
    const payload = { token };
    const { data, code } = await logoutService(payload);
    if (code === 200) {
       dispatch(deleteAuthUser());
       navigate('/auth/login');
    } 
    setLoading(false);
  }

  const menuId = 'primary-search-account-menu';
  
  const renderMenu = (
    
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'primary.light' }} />
        <Box>
          <Typography variant='body1' sx={{ lineHeight: 1 }}>{userDetails?.email}</Typography>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout}><Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: '#fff' }}><Logout sx={{ color: '#000', fontSize: 18 }} /></Avatar>Logout</MenuItem>
    </Menu>

  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    
    <Box sx={{ zIndex: 101 }}>
     
      <AppBar elevation={7}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', minHeight: '59px !important' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={setIsDashboardDrawerOpened}
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 1 }}
              >
                <MenuIcon fontSize='large' />
              </IconButton>
              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                Correspondence
               
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems:'center' }}>
                <Typography variant='h6' mr={1}>{userDetails?.name}</Typography>
                <Tooltip title="Profile">
                  <IconButton
                    onClick={handleProfileMenuOpen}
                    size="small"
                    aria-controls={anchorEl ? 'profile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl ? 'true' : undefined}
                  >
                    {/* <img src='../images/profile.jpg' width={'35px'}/> */}
                    <PersonSharpIcon sx={{ width: 35, height: 35, color:'#092635', backgroundColor:'#fff', borderRadius:'100%', padding:'2px'}} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}