import { configureStore } from '@reduxjs/toolkit';
import snackbarReducer from './reducer/snackbarSlice';
import authUserReducer from './reducer/authUserSlice';
import drawerReducer from './reducer/drawerSlice';
import dialogReducer from './reducer/dialogSlice';
import filterReducer from './reducer/filterSlice';
import modalReducer from './reducer/modalSlice'
import permissionsReducer from './reducer/permissionSlice';

const store = configureStore({
    reducer: {
        snackbar: snackbarReducer,
        authUser: authUserReducer,
        drawer: drawerReducer,
        dialog: dialogReducer,
        filter: filterReducer,
        modal: modalReducer,
        permissions: permissionsReducer,
    }
});

export type RootState = ReturnType<typeof import('./store').default.getState>

export default store;

